import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TileScroller } from '@aerq/aerq-design-system';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from 'styled-components';

import { fetchSectionFilter, getBackgroundImage, SectionMeta } from 'globals/cms-helper/layoutApi';
import { assetsPath } from 'globals/api';
import { AerenaContent } from 'globals/cms-helper/types';
import { useGlobalStore } from 'globals/store';
import { useGetSwimlaneContents, useTileClickHandler } from 'globals/hooks';
import { TNavigationEventOriginPage } from 'globals/types/logevent';
import { useNavigationEventStore } from 'globals/store/navigationevent.store';
import { getCurrentLangBundle } from 'globals/cms-helper/movieHelper';
import { pageXMargin } from 'globals/styles';

import { WatchOption } from 'views/watch/components/watchOptionsContainer';
import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

export const TileScrollerWrapper: React.FC<{ section: AerenaContent }> = ({ section }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  const backgroundImage = section?.children?.['backgroundImage']
    ? assetsPath + getBackgroundImage(section)
    : null;

  const {
    setWatchMainOptions,
    setWatchSecondaryOptions,
    watchMainOptions,
    watchSecondaryOptions,
    selectedLanguage,
    setResetWatchOptions
  } = useGlobalStore(
    useShallow((state) => ({
      setWatchMainOptions: state.setWatchMainOptions,
      setWatchSecondaryOptions: state.setWatchSecondaryOptions,
      watchMainOptions: state.watchMainOptions,
      watchSecondaryOptions: state.watchSecondaryOptions,
      selectedLanguage: state.selectedLanguage,
      setResetWatchOptions: state.setResetWatchOptions
    }))
  );

  const setNavigationEventOriginPage = useNavigationEventStore(
    (state) => state.setNavigationEventOriginPage
  );

  const { tileItems } = useGetSwimlaneContents(section);

  const sectionMeta = section?.metadata as SectionMeta;
  const enableText = sectionMeta?.imageType !== 'poster';

  const tileClickHandler = useTileClickHandler(section);

  const seeAllClickHandler = () => {
    const sectionFilterData = fetchSectionFilter(section);
    // scroll to top of the page
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    if (sectionMeta.url?.length > 0) {
      const url = sectionMeta.url?.join('/');
      const state: TNavigationEventOriginPage = {
        type: sectionMeta?.categories?.[0],
        languageSelected: selectedLanguage.code,
        clickedElement: {
          id: section?.ID,
          name: sectionMeta?.title,
          type: sectionMeta?.type,
          rank: sectionMeta?.rank + 1,
          clickedElement: {
            id: sectionMeta?.seeAllText,
            name: sectionMeta?.seeAllText,
            type: 'See All Tile',
            rank: 0
          }
        }
      };

      setNavigationEventOriginPage(state);

      // Function to update options based on a given list of values
      const updateOptions = (options: WatchOption[], values: string[]) =>
        options.map((option) => ({ ...option, selected: values.includes(option.value) }));

      const isBrowseAllSelected =
        sectionFilterData.contentTypes.includes('aerena_movie') &&
        sectionFilterData.contentTypes.includes('aerena_tv_show');

      if (watchMainOptions?.length > 0 && watchSecondaryOptions?.length > 0) {
        if (isBrowseAllSelected) {
          const mainOptions = watchMainOptions.map((option) => {
            if (option.value.includes('browse_all')) option.selected = true;
            else option.selected = false;
            return option;
          });
          setWatchMainOptions(mainOptions);
        } else {
          // if browse_all not selected, find out the selected main option
          if (sectionFilterData.contentTypes?.length > 0) {
            setWatchMainOptions(updateOptions(watchMainOptions, sectionFilterData.contentTypes));
          }
        }
        if (sectionFilterData.contentTypes?.length > 0) {
          // if a main option is found, find out secondary options
          setWatchSecondaryOptions(updateOptions(watchSecondaryOptions, sectionFilterData.genres));
        }
      } else {
        const mainOptionsBundle = getCurrentLangBundle(selectedLanguage.code, 'watchMainOptions');
        const mainOptions = Object.keys(mainOptionsBundle || {})?.map((o: any) => {
          return {
            title: mainOptionsBundle?.[o],
            value: o,
            selected: isBrowseAllSelected
              ? o === 'browse_all'
              : o?.includes(sectionFilterData?.contentTypes)
          };
        });

        const secondaryOptionsBundle = getCurrentLangBundle(
          selectedLanguage.code,
          'watchSecondaryOptions'
        );
        const secondaryOptions = Object.keys(secondaryOptionsBundle || {})?.map((o: any) => {
          return {
            title: secondaryOptionsBundle?.[o],
            value: o,
            selected:
              sectionFilterData?.genres?.length > 0 ? sectionFilterData.genres?.includes(o) : false
          };
        });
        setWatchMainOptions(mainOptions);
        setWatchSecondaryOptions(secondaryOptions);
      }
      navigate(url);
      setResetWatchOptions(false);
    } else {
      // if no path specified, default to see-all page
      navigate(`/see-all/${section?.ID}`, {
        state: {
          section
        }
      });
    }
  };

  return (
    <>
      {!tileItems ? (
        <PlaceHolderWrapper
          type="TileScroller"
          width={sectionMeta?.tileWidth}
          ratio={sectionMeta?.tileRatio}
          showPlaceholderTitle={true}
          noOfTile={9}
        />
      ) : (
        tileItems?.length > 0 && (
          <TileScroller
            theme={theme}
            title={sectionMeta?.title}
            textPosition={sectionMeta?.textPosition}
            showTitle={true}
            showCaption={sectionMeta?.showCaption}
            showDescription={sectionMeta?.showDescription}
            showSub={sectionMeta?.showSub}
            showTag={enableText && sectionMeta?.showTag}
            ratio={sectionMeta?.tileRatio}
            xPadding={pageXMargin}
            width={sectionMeta?.tileWidth}
            tiles={tileItems}
            onTileClick={tileClickHandler}
            onSeeAllClick={seeAllClickHandler}
            hasSeeAll={sectionMeta?.hasSeeAll}
            seeAllMaxLimit={sectionMeta?.seeAllMaxLimit}
            description={sectionMeta?.description}
            seeAllText={sectionMeta?.seeAllText}
            backgroundImage={backgroundImage}
            noImageText={t('common:error_page.no_image.text')}
          />
        )
      )}
    </>
  );
};
