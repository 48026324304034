import styled, { css } from 'styled-components';

import { tabletSize } from 'globals/styles';

export const Container = styled.div<{ bgImage: string }>`
  width: 100%;
  height: 100vh;
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  // styles below may be moved to a common file
  .display1 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.display1}
      `}
  }
  .display2 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.display2}
      `}
  }
  .header3 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.header3}/* font-weight: 500; */
      `}
  }
  .bodyS {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyS}
      `}
  }
  .bodyL {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyL}
      `}
  }
  .bodyXL {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyXL}
      `}
  }
  .text-center {
    text-align: center;
  }

  @media screen and (max-width: ${tabletSize}) {
    .display1 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.display1}
        `}
    }
    .display2 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.display2}
        `}
    }
    .header3 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.header3}
        `}
    }
    .bodyS {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyS}
        `}
    }
    .bodyL {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyL}
        `}
    }
    .bodyXL {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyXL}
        `}
    }
  }

  .primaryBlack {
    ${({ theme }) =>
      css`
        color: ${theme?.colors?.primaryBlack};
      `}
  }
  .body {
    ${({ theme }) =>
      css`
        color: ${theme?.colors?.body};
      `}
  }
`;

export const Overlay = styled.div`
  background: ${({ theme }) => theme?.colors?.lightOverlay1};
  ${({ theme }) => theme?.shadow?.flatLight};
  width: 100%;
  height: 100vh;
  position: absolute;
`;

export const LogoContainer = styled.div`
  display: flex;
  padding: 4.35rem 3.2rem 0 8.35rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  height: 8.8rem;
`;

export const LogoRight = styled.div`
  ${({ theme }) => theme?.font?.large?.button1};
  color: ${({ theme }) => theme?.colors?.primaryWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24rem;
  & > :first-child {
    width: 15.2rem;
    text-align: center;
  }
`;

export const ActionContainer = styled.div`
  margin-bottom: 3.2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 6.4rem;
  z-index: 1;

  .welcome-btn {
    margin-left: auto;
  }
`;

export const WelcomeContent = styled.div`
  display: flex;
  padding: 0 6.4rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  justify-content: space-between;
  width: 100%;
  z-index: 1;
  gap: 10rem;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  > div:nth-child(1),
  > div:nth-child(3) {
    margin-bottom: 1.6rem;
  }
  > div:nth-child(2) {
    margin-bottom: 4.8rem;
  }

  .bodyL {
    max-width: 78.2rem;
  }
`;

export const LanguageContainer = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(auto-fit, 32rem);
  flex: 1 0;
  > div {
    height: 5.6rem;
  }
`;
