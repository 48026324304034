import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, TIconName, Tile } from '@aerq/aerq-design-system';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import styles from './eJournalPage.module.css';
import {
  ButtonContainer,
  EJournalContainer,
  EJournalPageBox,
  MainMessage,
  SecondMessage
} from './eJournalPage.styled';

import { getEnvValue } from 'utils/useConfig';
import { useSeatNumber } from 'globals/hooks/useSeatNumber';
import { sdkInstance } from 'globals/api';

const EJournalPage: React.FC = () => {
  const [isPedPaired, setPedPaired] = useState(false);

  const seatNumber = useSeatNumber();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: pedStatus } = useQuery({
    queryKey: ['ped_status'],
    queryFn: () => axios.get(`/api/isd/connections?seatId=${seatNumber}`),
    select: (sessionData: any) => {
      return sessionData?.data?.isdSessions?.[0]?.status as string;
    },
    enabled: !!seatNumber,
    staleTime: 0
  });

  useEffect(() => {
    if (pedStatus === 'CONNECTED') {
      setPedPaired(true);
    }
  }, [pedStatus]);

  const PEDConnectIcon: TIconName = isPedPaired ? 'Ped' : 'QRCode';

  const PEDConnectButton = () => (
    <Button
      className={styles.homeButton}
      icon={PEDConnectIcon}
      textColor="white"
      handleClick={pedConnectClickHandler}
    >
      {isPedPaired ? t('ejournal:button_text_open_on_ped') : t('ejournal:button_text_connect_ped')}
    </Button>
  );

  const pedConnectClickHandler = () => {
    // connect with ped
    const pedRedirectUrl = getEnvValue('REACT_APP_PED_EJOURNAL_URL');
    sdkInstance?.startPedHandoff(pedRedirectUrl);
  };

  const homeClickHandler = () => {
    navigate('/', { replace: true });
  };

  const HomeButton = () => (
    <Button
      className={styles.homeButton}
      buttonType="outlined"
      textColor="black"
      handleClick={homeClickHandler}
    >
      {t('ejournal:button_text_homePage')}
    </Button>
  );

  return (
    <EJournalContainer>
      <EJournalPageBox>
        <Tile
          aspectRatio="4:3"
          imageSrc="/assets/images/ped_image.jpeg"
          textPosition="none"
          width={['26rem', '36rem', '56rem']}
        />
        <MainMessage>{t('ejournal:only_ped_message')}</MainMessage>
        <SecondMessage>{t('ejournal:connect_ped_message')}</SecondMessage>
        <ButtonContainer>
          <HomeButton />
          <PEDConnectButton />
        </ButtonContainer>
      </EJournalPageBox>
    </EJournalContainer>
  );
};

export default EJournalPage;
