import React, { FC } from 'react';
import { Progress, Bar } from './progressBar.styled';

interface ProgressBarProps {
  width: number;
  isTextDark: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({ width, isTextDark }) => {
  return (
    <Progress isTextDark={isTextDark}>
      <Bar width={width} isTextDark={isTextDark} />
    </Progress>
  );
};

export default ProgressBar;
