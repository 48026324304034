import React, { PropsWithChildren, useEffect, useState } from 'react';
import { MapContainerStyled } from './WorldMap.styled';

const MapContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [height, setHeight] = useState<string>();
  useEffect(() => {
    const firstSection = document.getElementById('homepage-section-0');
    const config = { childList: true };

    const callback: MutationCallback = () => {
      // observe changes in homepage-section-0, then update map height for first sectio
      if (firstSection) {
        setHeight(firstSection.getBoundingClientRect().height + 'px');
        observer.disconnect();
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(firstSection, config);
    return () => {
      observer.disconnect();
    };
  }, []);
  return <MapContainerStyled height={height}>{children}</MapContainerStyled>;
};

export default MapContainer;
