import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';
import { Button, Checkbox } from '@aerq/aerq-design-system';
import { useShallow } from 'zustand/react/shallow';

import {
  ActionButton,
  AudioSubtitleContainer,
  AudioSubtitleContent,
  AudioSubtitleWrapper,
  AudioSubtitleList,
  AudioSubtitleBody,
  MaxHeight,
  AudioSubtitleOverlay
} from './AudioSubtitles.styled';

import { useGlobalStore } from 'globals/store';
import { TAudioSubtitle } from 'globals/types/audiosubtitles';
import { setFullscreen } from 'utils/switchAppContext';
import { useDisableBodyScroll } from 'globals/hooks';
import { getCurrentLangBundle, setGenresAudioSubs } from 'globals/cms-helper/movieHelper';
import { ACTIVE_LIST } from 'utils/constants';
import { sdkInstance } from 'globals/api';

export const AudioSubtitles: React.FC<{ setOpenModal: (openModal: boolean) => void }> = ({
  setOpenModal
}) => {
  const [showClearAllBtn, setShowClearAllBtn] = useState<boolean>(false);
  const [audioSubtitleList, setAudioSubtitleList] = useState<{
    audios: TAudioSubtitle[];
    subtitles: TAudioSubtitle[];
  }>({ audios: [], subtitles: [] });

  const {
    selectedAudios,
    selectedSubtitles,
    setSelectedAudio,
    setSelectedSubtitles,
    selectedLanguage
  } = useGlobalStore(
    useShallow((state) => ({
      selectedAudios: state.selectedAudios,
      selectedSubtitles: state.selectedSubtitles,
      setSelectedAudio: state.setSelectedAudio,
      setSelectedSubtitles: state.setSelectedSubtitles,
      selectedLanguage: state.selectedLanguage
    }))
  );

  const { t } = useTranslation();

  useDisableBodyScroll(true);

  const { data: activeList } = useQuery({
    queryKey: [ACTIVE_LIST],
    queryFn: () => setGenresAudioSubs(selectedLanguage.code)
  });

  const handleAudioClick = useCallback(
    (item: TAudioSubtitle) => {
      const updatedAudios = [...audioSubtitleList.audios];
      const selectedAudio = updatedAudios?.find((s) => s.value === item.value);
      selectedAudio && (selectedAudio.selected = !item.selected);
      setAudioSubtitleList({ subtitles: audioSubtitleList.subtitles, audios: updatedAudios });
      const someItemSelected =
        updatedAudios?.some((item) => item.selected) ||
        audioSubtitleList.subtitles?.some((item) => item.selected);
      setShowClearAllBtn(someItemSelected);
    },
    [audioSubtitleList]
  );

  const handleSubtitleClick = useCallback(
    (item: TAudioSubtitle) => {
      const updatedSubtitles = [...audioSubtitleList.subtitles];
      const selectedSubtitle = updatedSubtitles?.find((s) => s.value === item.value);
      selectedSubtitle && (selectedSubtitle.selected = !item.selected);
      setAudioSubtitleList({ audios: audioSubtitleList.audios, subtitles: updatedSubtitles });
      const someItemSelected =
        updatedSubtitles?.some((item) => item.selected) ||
        audioSubtitleList.audios?.some((item) => item.selected);
      setShowClearAllBtn(someItemSelected);
    },
    [audioSubtitleList]
  );

  const applyFilters = useCallback(() => {
    const updatedAudios = audioSubtitleList.audios
      ?.filter((i) => i.selected)
      ?.map((audio) => audio?.value);
    setSelectedAudio(updatedAudios);
    const updatedSubtitles = audioSubtitleList.subtitles
      ?.filter((i) => i.selected)
      ?.map((subtitle) => subtitle?.value);
    setSelectedSubtitles(updatedSubtitles);
    setFullscreen(false);
    setOpenModal(false);
  }, [audioSubtitleList, setOpenModal, setSelectedSubtitles, setSelectedAudio]);

  const clearFilter = () => {
    const resetAudio = audioSubtitleList?.audios?.map((item) => ({ ...item, selected: false }));
    const resetSubtitle = audioSubtitleList?.subtitles?.map((item) => ({
      ...item,
      selected: false
    }));
    setAudioSubtitleList({ audios: resetAudio, subtitles: resetSubtitle });
    setShowClearAllBtn(false);
  };

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const FilterButtonDisabled = useCallback(() => {
    const selectedItemsMatched = (items: TAudioSubtitle[], selectedItems: string[]) => {
      return items?.every((item) => {
        const isItemInStore = selectedItems.includes(item.value);
        return item.selected === isItemInStore;
      });
    };

    const audioSelectionMatched = selectedItemsMatched(audioSubtitleList.audios, selectedAudios);
    const subtitleSelectionMatched = selectedItemsMatched(
      audioSubtitleList.subtitles,
      selectedSubtitles
    );

    return audioSelectionMatched && subtitleSelectionMatched;
  }, [audioSubtitleList, selectedAudios, selectedSubtitles]);

  useEffect(() => {
    const getAudioSubtitles = async () => {
      const filteredActiveAudios = Object.keys(
        getCurrentLangBundle(selectedLanguage.code, 'languages') || {}
      )?.filter((lang: string) =>
        activeList?.activeAudios?.some((audio: string) => audio === lang)
      );

      const audioList = filteredActiveAudios?.map((audio: string, idx: number) => {
        return {
          id: idx,
          title: getCurrentLangBundle(selectedLanguage.code, 'languages')?.[audio],
          value: audio,
          selected: selectedAudios.includes(audio)
        };
      });

      const filteredActiveSubs = Object.keys(
        getCurrentLangBundle(selectedLanguage.code, 'languages') || {}
      )?.filter((lang) => activeList?.activeSubs?.some((subs: string) => subs === lang));

      const subtitleList = filteredActiveSubs?.map((sub: string, idx: number) => {
        return {
          id: idx,
          title: getCurrentLangBundle(selectedLanguage.code, 'languages')?.[sub],
          value: sub,
          selected: selectedSubtitles.includes(sub)
        };
      });
      setAudioSubtitleList({ audios: audioList, subtitles: subtitleList });
    };

    sdkInstance?.fullscreenOverlayClosed?.connect(handleClose);

    setShowClearAllBtn(selectedAudios.length > 0 || selectedSubtitles.length > 0);

    getAudioSubtitles();
  }, [
    selectedAudios,
    selectedSubtitles,
    handleClose,
    selectedLanguage.code,
    activeList?.activeAudios,
    activeList?.activeSubs
  ]);

  return (
    <AudioSubtitleOverlay>
      <AudioSubtitleWrapper>
        <AudioSubtitleBody>
          <AudioSubtitleContainer>
            {audioSubtitleList?.subtitles?.length > 0 && (
              <AudioSubtitleContent>
                <h1>{t('listen:subtitles.subtitles')}</h1>
                <MaxHeight>
                  {audioSubtitleList?.subtitles?.map((subtitle) => (
                    <AudioSubtitleList key={subtitle?.id}>
                      <Checkbox
                        checkboxStyle="dark"
                        checkboxType={subtitle?.selected ? 'transparent' : 'none'}
                        onClick={() => handleSubtitleClick(subtitle)}
                        flexDirection="row-reverse"
                        justifyContent="start"
                        width="100%"
                        checked={subtitle?.selected}
                      >
                        {subtitle?.title}
                      </Checkbox>
                    </AudioSubtitleList>
                  ))}
                </MaxHeight>
              </AudioSubtitleContent>
            )}
            {audioSubtitleList?.audios?.length > 0 && (
              <AudioSubtitleContent>
                <h1>{t('listen:subtitles.audio')}</h1>
                <MaxHeight>
                  {audioSubtitleList?.audios?.map((audio) => (
                    <AudioSubtitleList key={audio?.id}>
                      <Checkbox
                        checkboxStyle="dark"
                        checkboxType={audio?.selected ? 'transparent' : 'none'}
                        onClick={() => handleAudioClick(audio)}
                        flexDirection="row-reverse"
                        justifyContent="start"
                        width="100%"
                        checked={audio?.selected}
                      >
                        {audio?.title}
                      </Checkbox>
                    </AudioSubtitleList>
                  ))}
                </MaxHeight>
              </AudioSubtitleContent>
            )}
          </AudioSubtitleContainer>

          <ActionButton>
            <div className="action_btns">
              {showClearAllBtn && (
                <Button buttonType="outlined" handleClick={clearFilter}>
                  {t('listen:subtitles.button.clearAll')}
                </Button>
              )}
              <Button disabled={FilterButtonDisabled()} handleClick={applyFilters}>
                {t('listen:subtitles.button.applyFilters')}
              </Button>
            </div>
          </ActionButton>
        </AudioSubtitleBody>
      </AudioSubtitleWrapper>
    </AudioSubtitleOverlay>
  );
};
