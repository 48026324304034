import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFlightDataStore } from 'globals/store';
import { IFlightDataStore } from 'globals/types/flightdata';
import { localTime } from 'utils/welcomePageUtils';

import {
  FlightInformationContainer,
  Departure,
  Destination,
  FlightDataUnavailableText,
  FlightNumber,
  FlightTime,
  Separator
} from './FlightInformation.styled';

import { useSeatNumber } from 'globals/hooks/useSeatNumber';

export const FlightInformation: React.FC = () => {
  const seatNumber = useSeatNumber();
  const { flightData } = useFlightDataStore((state: IFlightDataStore) => ({
    flightData: state.flightData
  }));

  const { t } = useTranslation(['errors, welcome']);

  if (Object.keys(flightData || {})?.length === 0 && !seatNumber) return null;

  return (
    <FlightInformationContainer className="flightInformation">
      <FlightNumber className="flightNumber">
        {!flightData?.flightNumber && !seatNumber ? (
          <FlightDataUnavailableText className="header3 body">
            {t('errors:flightData_unavailable')}
          </FlightDataUnavailableText>
        ) : (
          <>
            <div className="display1 primaryBlack">{seatNumber || ''}</div>
            {flightData?.flightNumber && (
              <>
                <div className="bodyS body">{t('welcome:flightNumber')}</div>
                <div className="header3 body">{flightData?.flightNumber || ''}</div>
              </>
            )}
          </>
        )}
      </FlightNumber>

      <Departure>
        {!flightData?.orig?.code ? (
          <FlightDataUnavailableText className="header3 body">
            {t('errors:flightData_unavailable')}
          </FlightDataUnavailableText>
        ) : (
          <>
            <div className="bodyS body text-center">{t('welcome:departure')}</div>
            <div className="header3 primaryBlack">{flightData?.orig?.code || ''}</div>
          </>
        )}
      </Departure>
      <Destination>
        {!flightData?.dest?.code ? (
          <FlightDataUnavailableText className="header3 body">
            {t('errors:flightData_unavailable')}
          </FlightDataUnavailableText>
        ) : (
          <>
            <div className="bodyS body text-center">{t('welcome:destination')}</div>
            <div className="header3 primaryBlack">{flightData?.dest?.code || ''}</div>
          </>
        )}
      </Destination>
      <FlightTime>
        {!flightData?.timeDest ||
        !flightData?.dest?.localTimeAtArrival ||
        !flightData?.dest?.tzTimeZone ? (
          <FlightDataUnavailableText className="header3 body">
            {t('errors:flightData_unavailable')}
          </FlightDataUnavailableText>
        ) : (
          <>
            <div>
              <span className="bodyS body">{t('welcome:duration')}</span>
              <span className="header3 primaryBlack">{flightData?.timeDest || ''}</span>
            </div>
            <Separator className="separator" />
            <div>
              <span className="bodyS body">{t('welcome:arrival')}</span>
              <span className="header3 primaryBlack textRight">
                {localTime(flightData?.dest?.localTimeAtArrival, flightData?.dest?.tzTimeZone)}
              </span>
            </div>
          </>
        )}
      </FlightTime>
    </FlightInformationContainer>
  );
};
