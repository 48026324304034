import styled from 'styled-components';
import { tabletSize } from 'globals/styles';
import { Color } from 'utils/textColor';

export const ListenDetailsHeader = styled.div`
  min-height: 65.6rem;
  height: 100%;
  z-index: 0;
  position: relative;
`;

export const ListenDetailImageContainer = styled.div`
  width: 54.4rem;
  z-index: 1;
  .listen-header_image {
    border-radius: 0;
  }
`;

export const ListenDetailsHeaderContent = styled.div<{
  dominantCol: Color | string;
  defaultDominantCol: Color | string;
}>`
  display: flex;
  background: ${({ dominantCol, defaultDominantCol }) =>
    dominantCol ? `rgb(${dominantCol})` : `rgb(${defaultDominantCol},0.6)`};
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 4.3%;
  width: 100%;
  position: relative;
  padding: 4rem 6.4rem;
  align-items: center;
`;

export const ListenDetailsHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 68rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 112.8rem;
`;

export const ListenDetailsHeaderType = styled.div<{ isTextDark: boolean }>`
  ${({ theme }) => theme?.font?.large?.caption};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.darkOverlay1 : theme?.colors?.lightOverlay1};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.caption}
  }

  letter-spacing: 0.06rem;
  text-align: left;
  text-transform: uppercase;
`;

export const ListenDetailsHeaderTitle = styled.div<{ isTextDark: boolean }>`
  ${({ theme }) => theme?.font?.large?.header1};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.primaryBlack : theme?.colors?.primaryWhite};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header1};
  }

  text-align: left;
  padding-top: 1.2rem;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

export const ListenDetailsHeaderDetails = styled.div<{ isTextDark: boolean }>`
  ${({ theme }) => theme?.font?.large?.bodyXL};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.body : theme?.colors?.bodyInverse};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyXL}
  }
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
