import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';

import App from 'App';
import { ErrorPage } from 'views';
import { GlobalStyle, useGetTheme } from 'globals/styles';

import { LoadingWrapper } from 'components/LoadingWrapper';
import FlightBar from 'components/FlightBar/FlightBar';

function AppWrapper() {
  const theme = useGetTheme();

  return (
    <ThemeProvider theme={theme?.mainTheme}>
      <FlightBar />
      <GlobalStyle />
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary FallbackComponent={ErrorPage} onReset={reset}>
            <LoadingWrapper />
            <App />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </ThemeProvider>
  );
}

export default AppWrapper;
