import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createIDBPersister, queryClient } from 'utils/reactQueryConfig';
import './globals/translations/i18n';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { PAGE_QUERY_KEYS_NOT_CACHED } from 'utils/constants';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryKey } from '@tanstack/react-query';
import AppWrapper from 'AppWrapper';
import { getPreviewData } from 'utils/previewConfig';
import { getEnvValue } from 'utils/useConfig';
import { keyCloakInit } from 'utils/api/keyCloakInit';

const Root = () => {
  const [initKeyCloak, setInitKeyCloak] = useState<boolean>(false);

  useEffect(() => {
    if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED') && !initKeyCloak) {
      keyCloakInit(setInitKeyCloak);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initKeyCloak && getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) return;

  const excludedFromCache = (queryKey: QueryKey) => {
    if (PAGE_QUERY_KEYS_NOT_CACHED.some((id) => queryKey.includes(id))) {
      return true;
    }
    return false;
  };

  const { previewID } = getPreviewData();
  return (
    <BrowserRouter>
      {!previewID && (
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: createIDBPersister(),
            maxAge: Infinity,
            dehydrateOptions: {
              shouldDehydrateQuery: (query) => {
                const queryIsReadyForPersistance = query.state.status === 'success';
                if (queryIsReadyForPersistance) {
                  const { queryKey } = query;
                  const excludeFromPersisting = excludedFromCache(queryKey);
                  if (excludeFromPersisting) query.cacheTime = 0;
                  return !excludeFromPersisting;
                } else if (query.state.status === 'error' && query.state.data) {
                  return true; // Retain the cache and do not overwrite
                } else if (query.state.status === 'error' && !query.state.data) {
                  return false; // Do not store if it fails in the startup
                }
                return queryIsReadyForPersistance;
              }
            }
          }}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <AppWrapper />
        </PersistQueryClientProvider>
      )}
      {!!previewID && (
        <QueryClientProvider client={queryClient}>
          <AppWrapper />
        </QueryClientProvider>
      )}
    </BrowserRouter>
  );
};

const container = document.getElementById('root');

const root = createRoot(container);

root.render(<Root />);
