import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultTheme } from 'styled-components';

import { AerenaContent } from 'globals/cms-helper/types';
import { EpisodeDescriptionContainer } from './EpisodeDescription.styled';

export const EpisodeDescription: React.FC<{ episode: AerenaContent; theme: DefaultTheme }> = ({
  episode,
  theme
}) => {
  const { t } = useTranslation();

  const episodeMeta = episode?.metadata as any;

  const metaDict = {
    value: [
      { key: t('watch:details.starring.title'), value: episodeMeta?.cast },
      { key: t('watch:details.directors.title'), value: episodeMeta?.directors }
    ].filter((item) => item?.value)
  };

  const languageDict = {
    key: t('watch:details.languages.title'),
    value: [
      {
        key: t('watch:details.audio.title'),
        value:
          episodeMeta?.audios?.length > 0
            ? episodeMeta?.audios?.map((a: string) => t('languages:' + a))?.join(', ')
            : ''
      },
      {
        key: t('watch:details.subtitles.title'),
        value:
          episodeMeta?.subtitles?.length > 0
            ? episodeMeta?.subtitles?.map((a: string) => t('languages:' + a))?.join(', ')
            : ''
      }
    ].filter((item) => item?.value)
  };

  return (
    <EpisodeDescriptionContainer theme={theme}>
      <div className="episode-synopsis">{episodeMeta?.synopsis}</div>
      {metaDict?.value?.length > 0 &&
        metaDict.value.map((item) => (
          <div key={item?.key}>
            <div className="episode-meta-key">{item?.key}</div>
            <div className="episode-meta-value">{item?.value}</div>
          </div>
        ))}
      {languageDict?.value?.length > 0 &&
        languageDict?.value?.map((item) => (
          <div key={item?.key}>
            <div className="episode-meta-key">{item?.key}</div>
            <div className="episode-meta-value">{item?.value}</div>
          </div>
        ))}
    </EpisodeDescriptionContainer>
  );
};
