import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { translations } from '@aerq2/aerq-translations';
import { useGlobalStore } from 'globals/store';

const selectedLanguage = useGlobalStore.getState().selectedLanguage;

const config = {
  resources: translations,
  lng: selectedLanguage.code,
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false
  }
};
i18n.use(initReactI18next).init(config);
export default i18n;
