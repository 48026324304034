import { tabletSize } from 'globals/styles';
import styled from 'styled-components';

export const WatchOptionsContainer = styled.div`
  padding-top: 6.4rem;
  padding-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 3.2rem;
  @media screen and (max-width: ${tabletSize}) {
    gap: 1.6rem;
    padding-top: 3.2rem;
    padding-bottom: 0.8rem;
  }
  overflow-x: auto;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 3.2rem;
  @media screen and (max-width: ${tabletSize}) {
    gap: 1.6rem;
  }
`;

export const OptionsSeperator = styled.div`
  width: 0.2rem;
  height: 6rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme?.colors?.gray80};
`;

export const OptionsSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 3.2rem;
  @media screen and (max-width: ${tabletSize}) {
    gap: 1.6rem;
  }
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`;
