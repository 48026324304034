import React from 'react';
import { Placeholder, TSize } from '@aerq/aerq-design-system';

const PlaceholderTextBlock: React.FC<{
  showTitle?: boolean;
  showCaption?: boolean;
  showDescription?: boolean;
  titleSize?: TSize;
  captionSize?: TSize;
  className?: string;
}> = ({
  showCaption = true,
  showTitle = true,
  showDescription = true,
  titleSize = 'xl',
  captionSize = 'l',
  className
}) => (
  <div className={className}>
    {showCaption && <Placeholder type="tab" size={captionSize} className="placeholder-caption" />}
    {showTitle && <Placeholder type="tab" size={titleSize} className="placeholder-title" />}
    {showDescription && (
      <>
        <Placeholder type="tab" size={titleSize} className="placeholder-description" />
        <Placeholder type="tab" size={titleSize} className="placeholder-description" />
        <Placeholder type="tab" size={titleSize} className="placeholder-description" />
      </>
    )}
  </div>
);

export default PlaceholderTextBlock;
