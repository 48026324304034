import { useGlobalStore } from 'globals/store';
import moment from 'moment-timezone';

export const localTime = (localTimeAtArrival: Date, tzTimezone: string) => {
  if (!localTimeAtArrival || !tzTimezone) {
    return '';
  }
  const selectedLangugage = useGlobalStore.getState().selectedLanguage;
  const isValid = !isNaN(new Date(localTimeAtArrival).getTime());

  if (!isValid) {
    return '';
  }

  const eta: any = new Date(localTimeAtArrival).toLocaleString(selectedLangugage.code, {
    timeZone: tzTimezone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  });

  const timezone = moment.tz(localTimeAtArrival, tzTimezone).format('z');
  return `${eta} ${timezone || ''}`;
};
