import {
  IDsTRACKMOVIEParams,
  NavigationEventParams,
  PlayEventParams,
  TEventType
} from './types/logevent';

export const handleLogEvent = <
  T extends NavigationEventParams | PlayEventParams | IDsTRACKMOVIEParams
>(
  eventType: TEventType,
  payload: T
) => {
  const origin = 'frontend';

  if (typeof window?.AnalyticsClient !== 'undefined') {
    window?.AnalyticsClient?.logEvent(eventType, origin, { ...payload, origin: 'frontend' });
  }
};
