import React from 'react';
import { Placeholder } from '@aerq/aerq-design-system';

import {
  PlaceHolderAboutUsContainer,
  PlaceHolderAboutUsOverview,
  PlaceHolderAboutUsValuesContainer
} from './placeholderWrapper.styled';
import PlaceholderTextBlock from './PlaceholderTextBlock';

const PlaceHolderAboutUs: React.FC = () => {
  return (
    <PlaceHolderAboutUsContainer>
      <PlaceHolderAboutUsOverview>
        <PlaceholderTextBlock />
        <Placeholder type="tab" size="xxl" className="placeholder-button" />
      </PlaceHolderAboutUsOverview>
      <PlaceHolderAboutUsValuesContainer>
        {Array.from({ length: 3 }, (_, i) => i)
          .toString()
          .split(',')
          .map((value) => (
            <Placeholder key={value} type="area" />
          ))}
      </PlaceHolderAboutUsValuesContainer>
    </PlaceHolderAboutUsContainer>
  );
};

export default PlaceHolderAboutUs;
