import styled, { css } from 'styled-components';

import { tabletSize } from 'globals/styles';

export const FlightInformationContainer = styled.div`
  display: grid;
  gap: 2.4rem 3.2rem;
  flex: 1.2;
  align-self: center;
  height: 39.2rem;
  max-width: 88rem;
  z-index: 1;
  grid-template-areas: 'flightNumber flightNumber departure destination' 'flightNumber flightNumber flightTime flightTime';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: ${({ theme }) => theme?.colors?.bodyInverse};
  > div {
    justify-self: stretch;
    align-self: stretch;
    background: ${({ theme }) => theme?.colors?.darkOverlay2};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.6rem;
  }

  .textRight {
    text-align: right;
  }

  .display1 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.display1}
      `}
  }
  .display2 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.display2}
      `}
  }
  .header3 {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.header3}/* font-weight: 500; */
      `}
  }
  .bodyS {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyS}
      `}
  }
  .bodyL {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyL}
      `}
  }
  .bodyXL {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.bodyXL}
      `}
  }
  .text-center {
    text-align: center;
  }

  @media screen and (max-width: ${tabletSize}) {
    .display1 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.display1}
        `}
    }
    .display2 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.display2}
        `}
    }
    .header3 {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.header3}
        `}
    }
    .bodyS {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyS}
        `}
    }
    .bodyL {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyL}
        `}
    }
    .bodyXL {
      ${({ theme }) =>
        css`
          ${theme?.font?.small?.bodyXL}
        `}
    }
  }

  .primaryBlack {
    ${({ theme }) =>
      css`
        color: ${theme?.colors?.primaryBlack};
      `}
  }
  .body {
    ${({ theme }) =>
      css`
        color: ${theme?.colors?.body};
      `}
  }
`;

export const FlightNumber = styled.div`
  grid-area: flightNumber;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 42.4rem;
  padding: 0rem 3.2rem;
  flex-direction: column;
  align-self: stretch;
`;

export const Departure = styled.div`
  grid-area: departure;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Destination = styled.div`
  grid-area: destination;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const FlightTime = styled.div`
  grid-area: flightTime;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 2.4rem;
  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme?.colors?.gray20};
  margin-bottom: 0.5rem;
`;

export const FlightDataUnavailableText = styled.p`
  font-size: 2.8rem;
  text-align: center;
`;
