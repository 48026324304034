import { pageYMargin, tabletSize } from 'globals/styles';
import styled, { css } from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  gap: 3.2rem;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - ${pageYMargin});
`;

export const ErrorPageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .flightInformation {
    ${({ theme }) =>
      css`
        ${theme?.font?.large?.display2}
        margin-bottom: 3.2rem;
      `}
  }

  .flightNumber {
    height: 39.2rem;
  }

  .separator {
    border-top: ${({ theme }) => `1px solid ${theme?.colors?.gray80}`};
  }
`;

export const MainMessage = styled.p`
  ${({ theme }) => theme?.font?.large?.header1}
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header1}
  }
`;

export const SecondMessage = styled.p`
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
  ${({ theme }) => theme?.font?.large?.bodyL}
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyL}
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
`;
