import styled from 'styled-components';

export const BillboardContainer = styled.div`
  padding: 4.8rem 6.4rem;
`;

export const BillboardImageContainer = styled.img`
  width: -webkit-fill-available;
`;

export const BillboardVideoContainer = styled.video`
  width: -webkit-fill-available;
`;
