import styled from 'styled-components';

export const MapContainerStyled = styled.div<{ height: string }>`
  border-radius: 0px 0px 16px 16px;
  height: ${({ height }) => height};
  width: 100%;
  position: absolute;
  top: 0;
  box-shadow: 0px 10px 24px 0px grey;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path:nth-child(1) {
      //TODO: fill color changes based on theme. Yet to get values from UX.
      /* fill: lightgray; */
    }
  }
`;
