import styled from 'styled-components';
import { tabletSize } from 'globals/styles';

export const ExpandableText = styled.div<{ isTextDark: boolean; isExpanded?: boolean }>`
  ${({ theme }) => theme?.font?.large?.bodyL};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.body : theme?.colors?.bodyInverse};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyL}
  }
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? 'none' : 3)};
`;

export const SeeMore = styled.div<{ isTextDark?: boolean }>`
  ${({ theme }) => theme?.font?.large?.bodyL};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.body : theme?.colors?.bodyInverse};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyL}
    font-weight: bold;
  }

  font-weight: bold;
  cursor: pointer;
  display: block;
  margin-bottom: 3rem;
  -webkit-tap-highlight-color: transparent;
`;
