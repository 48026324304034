import { useQuery } from '@tanstack/react-query';

import { getSeatNoConfig } from 'utils/api/scsClient';
import { getPreviewData } from 'utils/previewConfig';
import { getEnvValue } from 'utils/useConfig';

const { previewID } = getPreviewData();

export const useSeatNumber = () => {
  const { data: seatNumber } = useQuery({
    queryKey: ['seatId'],
    queryFn: () => getSeatNoConfig(),
    retry: true,
    retryDelay: 5000,
    select: (data) => {
      return data?.seatId;
    },
    // If previewID OR  REACT_APP_SEAT_CONFIG_DISABLED value is not provided, it is enabled
    enabled: !(previewID || getEnvValue('REACT_APP_SEAT_CONFIG_DISABLED'))
  });
  return seatNumber;
};
