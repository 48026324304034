import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useShallow } from 'zustand/react/shallow';
import { useQuery } from '@tanstack/react-query';
import { Badge, Button, Chip, Icon, ListContainer, TIconName } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import {
  FavCounterPlaceholder,
  FavoritesOuterContainer,
  FavoritesDrawerStyled,
  FavoritesEmpty,
  FavoritesContainer,
  Overlay,
  SlideTransition
} from './favoritesModal.styled';

import { TFavorite, useFavoritesStore } from 'globals/store/favorites.store';
import { useDisableBodyScroll } from 'globals/hooks';
import { useGlobalStore } from 'globals/store';
import { AerenaContentType } from 'globals/cms-helper/types';
import { isAllowedRatingsMatchWithItem } from 'globals/cms-helper/contentHelper';
import { ALL_CONTENT } from 'utils/constants';
import { fetchContentById, getImageWithFallback } from 'globals/cms-helper/layoutApi';
import { assetsPath } from 'globals/api';

export const Favorites: React.FC<{ favoriteType: string }> = ({ favoriteType }) => {
  const { favorites, setFavorites } = useFavoritesStore(
    useShallow((state) => ({
      favorites: favoriteType === 'watch' ? state.watchFavorites : state.listenFavorites,
      setFavorites: favoriteType === 'watch' ? state.setWatchFavorites : state.setListenFavorites
    }))
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [displayedFavorites, setDisplayedFavorites] = useState<TFavorite[]>([]);
  const [selectedFavoriteTypes, setSelectedFavoriteTypes] = useState<AerenaContentType[]>([]);

  const location = useLocation();
  const { id: contentId } = useParams();
  const navigate = useNavigate();

  useDisableBodyScroll(showModal);

  const theme = useTheme();

  const favoritesModalToggle = useCallback((val: boolean) => {
    setShowModal(val);
  }, []);

  const isFavoriteTypeSelected = (item: TFavorite, selectedTypes: AerenaContentType[]) => {
    return selectedTypes.length === 0 || selectedTypes.includes(item.type);
  };

  const { allowedRatings, selectedLanguage } = useGlobalStore(
    useShallow((state) => ({
      allowedRatings: state.allowedRatings,
      selectedLanguage: state.selectedLanguage
    }))
  );

  const { t } = useTranslation(['favorites']);

  const isItemRatingAllowed = useCallback(
    (item: any) => {
      return isAllowedRatingsMatchWithItem(item, allowedRatings);
    },
    [allowedRatings]
  );

  const { data: allContent } = useQuery({
    queryKey: [ALL_CONTENT, selectedLanguage.code],
    queryFn: () => fetchContentById(ALL_CONTENT, { language: selectedLanguage, depth: 1 })
  });

  useEffect(() => {
    const filtered = favorites
      .filter((item) => isFavoriteTypeSelected(item, selectedFavoriteTypes))
      .filter(isItemRatingAllowed);
    const getTitleByCurrentLang = filtered?.map((fav) => {
      const content = allContent?.children?.contents?.find((content) => content?.ID === fav?.id);
      const tileRatio =
        fav?.type === 'aerena_movie' || fav?.type === 'aerena_tv_show' ? '3:4' : '1:1';
      const tileWidth =
        fav?.type === 'aerena_movie' || fav?.type === 'aerena_tv_show' ? '6.8rem' : '9rem';
      return {
        ...fav,
        title: (content?.metadata as any)?.title,
        ratings: (content?.metadata as any)?.ratings,
        imageUrl:
          assetsPath + getImageWithFallback(content, tileRatio, 'poster', tileWidth)?.imageSrc
      };
    });
    setDisplayedFavorites(getTitleByCurrentLang);
  }, [
    favorites,
    selectedFavoriteTypes,
    allowedRatings,
    isItemRatingAllowed,
    allContent?.children?.contents
  ]);

  const favoriteTypeSelectHandler = useCallback(
    (type: AerenaContentType) => {
      const index = selectedFavoriteTypes.indexOf(type);
      if (index > -1) {
        const updatedTypes = selectedFavoriteTypes.filter((t) => t !== type);
        setSelectedFavoriteTypes(updatedTypes);
      } else {
        setSelectedFavoriteTypes([...selectedFavoriteTypes, type]);
      }
    },
    [selectedFavoriteTypes]
  );

  const closeIcon = (type: AerenaContentType): TIconName =>
    selectedFavoriteTypes.includes(type) ? 'Close' : null;

  const selectOptionChipType = (type: AerenaContentType) => {
    return selectedFavoriteTypes.includes(type) ? 'filled' : 'transparent';
  };

  const allowedFavorites = favorites.filter(isItemRatingAllowed);

  const favCounter = allowedFavorites.length > 0 && (
    <Badge className="count" type="red" text={allowedFavorites.length.toString()} />
  );

  const buildNavigateOptions = useCallback(
    (item: TFavorite) => {
      let navigateOptions: any = { state: { type: item.type } };
      if (contentId) {
        navigateOptions = { ...navigateOptions, replace: true };
      }
      return navigateOptions;
    },
    [contentId]
  );

  const itemClickHandler = useCallback(
    (item: TFavorite) => {
      if (item?.url && item?.url !== location.pathname) {
        // if currently in a movie page, then replace, otherwise push to history
        const navigateOptions = buildNavigateOptions(item);
        navigate(item.url, navigateOptions);
        favoritesModalToggle(false);
      } else {
        favoritesModalToggle(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildNavigateOptions, favoritesModalToggle, location.pathname]
  );

  const itemRemoveClickHandler = useCallback(
    (removedItem: TFavorite) => {
      setFavorites((prev) => {
        const updatedList = prev.filter((item) => item?.id !== removedItem?.id);
        return updatedList;
      });

      const updatedList = favorites.filter((item) => item?.id !== removedItem?.id);
      const listHasNoItemOfRemovedType = !updatedList.some(
        (item) => item?.type === removedItem?.type
      );

      // if this was the last item of the type, then remove the type from selected types
      if (listHasNoItemOfRemovedType) {
        const index = selectedFavoriteTypes.indexOf(removedItem?.type);
        if (index > -1) {
          const updatedTypes = selectedFavoriteTypes.filter((t) => t !== removedItem?.type);
          setSelectedFavoriteTypes(updatedTypes);
        }
      }
    },
    [favorites, selectedFavoriteTypes, setFavorites]
  );

  const selectedTypeHasNoItem = useCallback(
    (favoriteType: AerenaContentType) => {
      return !favorites.some((item) => item?.type === favoriteType);
    },
    [favorites]
  );

  const favoritesEmpty =
    favoriteType === 'watch' ? (
      <FavoritesEmpty data-testid="favoritesEmpty">
        <div>{t('favorites:noFavoritesMessage')}</div>
        <div>{t('favorites:watchNoFavoritesMessage')}</div>
      </FavoritesEmpty>
    ) : (
      <FavoritesEmpty data-testid="favoritesEmpty">
        <div>{t('favorites:noFavoritesMessage')}</div>
        <div>{t('favorites:listenNoFavoritesMessage')}</div>
      </FavoritesEmpty>
    );

  const renderFavoritesDrawer = () => (
    <FavoritesDrawerStyled data-testid="favoritesDrawer" onClick={() => favoritesModalToggle(true)}>
      <Icon name="Star"></Icon>
      <div>{t('favorites:favorites')}</div>
      {allowedFavorites.length > 0 ? (
        <Badge type="red" text={allowedFavorites.length.toString()} />
      ) : (
        <FavCounterPlaceholder />
      )}
    </FavoritesDrawerStyled>
  );

  const renderFavoriteList = () =>
    allowedFavorites.length === 0 ? (
      favoritesEmpty
    ) : (
      <ListContainer
        theme={theme}
        aspectRatio="3:4"
        maxWidth="100%"
        height="58.5rem"
        undoText={t('favorites:undo')}
        listItems={displayedFavorites}
        iconName="Trash"
        onClick={itemClickHandler}
        onRemoveClick={itemRemoveClickHandler}
      ></ListContainer>
    );

  return (
    <>
      {!showModal && renderFavoritesDrawer()}
      {showModal && (
        <Overlay data-testid="overlay" onClick={() => favoritesModalToggle(false)}></Overlay>
      )}

      <SlideTransition in={showModal} timeout={200} classNames="slide" unmountOnExit>
        <FavoritesOuterContainer>
          <FavoritesContainer data-testid="favoritesModal" onClick={(e) => e.stopPropagation()}>
            <div className="favorites-header">
              <div className="title"> {t('favorites:yourFavorites')}</div>
              {favCounter}
              <Button
                className="close-button"
                icon="Close"
                buttonType="outlined"
                handleClick={() => favoritesModalToggle(false)}
              >
                {t('favorites:close-button')}
              </Button>
            </div>
            {favoriteType === 'watch' && (
              <div className="favorite-type-select">
                <Chip
                  chipStyle="dark"
                  chipType={selectOptionChipType('aerena_movie')}
                  onClick={() => favoriteTypeSelectHandler('aerena_movie')}
                  icon={closeIcon('aerena_movie')}
                  disabled={selectedTypeHasNoItem('aerena_movie')}
                >
                  {t('favorites:movie')}
                </Chip>
                <Chip
                  chipStyle="dark"
                  chipType={selectOptionChipType('aerena_tv_show')}
                  onClick={() => favoriteTypeSelectHandler('aerena_tv_show')}
                  icon={closeIcon('aerena_tv_show')}
                  disabled={selectedTypeHasNoItem('aerena_tv_show')}
                >
                  {t('favorites:tvshow')}
                </Chip>
              </div>
            )}
            {renderFavoriteList()}
          </FavoritesContainer>
        </FavoritesOuterContainer>
      </SlideTransition>
    </>
  );
};
