import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TileGrid } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';
import { useShallow } from 'zustand/react/shallow';

import { pageXMargin } from 'globals/styles';
import { useGlobalStore } from 'globals/store';
import { AerenaContent } from 'globals/cms-helper/types';
import { useGetFilteredGridContents, useTileClickHandler } from 'globals/hooks';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';

import {
  EndOfListText,
  GridContainer,
  ViewMoreBackToTopWrapper
} from './WatchTileGridWrapper.styled';

import BackToTop from 'components/BackToTop/BackToTop';
import NoContentFound from 'views/watch/components/NoContentFound';
import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

export const WatchTileGridWrapper: React.FC<{
  selectedMainOption: string;
  selectedSecondaryOption: string;
  pageSections?: AerenaContent[];
  allContent: AerenaContent;
  onClearSelection: () => void;
}> = ({
  selectedMainOption,
  selectedSecondaryOption,
  pageSections,
  onClearSelection,
  allContent
}) => {
  const [contents, setContents] = useState<AerenaContent[]>(undefined);

  const { watchOptionsSkipMap, setWatchOptionsSkipMap } = useGlobalStore(
    useShallow((state) => ({
      watchOptionsSkipMap: state.watchOptionsSkipMap,
      setWatchOptionsSkipMap: state.setWatchOptionsSkipMap
    }))
  );

  const noSecondaryOption = 'noSecondaryOption';

  useEffect(() => {
    const updatedMap = new Map(watchOptionsSkipMap);

    let mainMap = updatedMap?.get(selectedMainOption);
    if (!mainMap) {
      mainMap = new Map();
      updatedMap.set(selectedMainOption, undefined);
    }

    const skipVal = updatedMap
      ?.get(selectedMainOption)
      ?.get(selectedSecondaryOption || noSecondaryOption);

    if (!skipVal) {
      mainMap.set(selectedSecondaryOption || noSecondaryOption, 1);
      updatedMap.set(selectedMainOption, mainMap);
      setWatchOptionsSkipMap(updatedMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMainOption, selectedSecondaryOption]);

  const theme = useTheme();

  const limit = 16;

  const { t } = useTranslation();

  useEffect(() => {
    const contents = allContent?.children?.contents?.filter((content: AerenaContent) => {
      const contentTypeMatch =
        selectedMainOption === 'browse_all' || selectedMainOption === content?.contentTypeID;
      const genreMatch =
        !selectedSecondaryOption ||
        (content?.metadata as any)?.genres?.some(
          (genre: string) => selectedSecondaryOption === genre
        );
      return contentTypeMatch && genreMatch;
    });

    setContents(contents?.filter(Boolean));
  }, [allContent, selectedMainOption, selectedSecondaryOption]);

  const skipVal =
    watchOptionsSkipMap
      ?.get(selectedMainOption)
      ?.get(selectedSecondaryOption || noSecondaryOption) || 1;

  const { tileItems, totalItemsLen } = useGetFilteredGridContents(contents, skipVal, limit);

  const tileClickHandler = useTileClickHandler();

  useNavigationLogEvent('watch_grid_page', pageSections, contents, {
    mainOption: selectedMainOption as 'aerena_movie' | 'aerena_tv_show',
    secondaryOption: selectedSecondaryOption
  });
  const seeMoreHandler = () => {
    const updatedMap = new Map(watchOptionsSkipMap);
    const skipVal = updatedMap
      .get(selectedMainOption)
      .get(selectedSecondaryOption || noSecondaryOption);
    if (skipVal) {
      const innerMap = updatedMap.get(selectedMainOption);
      const innerValue = innerMap.get(selectedSecondaryOption || noSecondaryOption);
      innerMap.set(selectedSecondaryOption || noSecondaryOption, innerValue + 1);
      setWatchOptionsSkipMap(updatedMap);
    }
  };

  const isEndOfList = tileItems?.length < skipVal * limit || tileItems?.length === totalItemsLen;

  // To avoid scrolling to top as it's undefined initially
  if (!tileItems) {
    return (
      <PlaceHolderWrapper
        type="TileContainerAndGrid"
        noOfTile={limit * skipVal}
        columns={4}
        ratio="3:4"
      />
    );
  }

  if (tileItems?.length === 0)
    return (
      <NoContentFound
        description={t('watch:details.no_content_found.description')}
        onClearSelection={onClearSelection}
      />
    );

  return (
    <>
      {tileItems?.length > 0 && (
        <GridContainer xMargin={pageXMargin}>
          <TileGrid
            theme={theme}
            tagTextMode="light"
            textPosition={'none'}
            showTitle={true}
            showCaption={false}
            showDescription={false}
            showSub={false}
            showTag={false}
            ratio={'3:4'}
            textPadding={'0'}
            width={'100%'}
            items={tileItems}
            columns={[2, 3, 4]}
            onTileClick={tileClickHandler}
          ></TileGrid>

          <ViewMoreBackToTopWrapper>
            {!isEndOfList && (
              <div style={{ minWidth: '25rem' }}>
                <Button
                  bgColor={theme.colors.primaryBlack}
                  icon="Add"
                  width="100%"
                  handleClick={seeMoreHandler}
                >
                  {t('viewAll:viewMore')}
                </Button>
              </div>
            )}
            {isEndOfList && <EndOfListText>{t('viewAll:endOfTheListText')}</EndOfListText>}
            <BackToTop />
          </ViewMoreBackToTopWrapper>
        </GridContainer>
      )}
    </>
  );
};
