import React from 'react';

import { Placeholder } from '@aerq/aerq-design-system';

import { PlaceholderContainer, PlaceholderGridTextContent } from './placeholderWrapper.styled';
import {
  Divider,
  WatchDetailsAbout,
  WatchDetailsAboutHeader,
  WatchDetailsAboutItem,
  WatchDetailsContainer
} from 'views/watch/watchDetails/components/watchDetails.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderWatchDetailInfoProps = {
  showPlaceholderTitle?: boolean;
  rowSet?: number;
  columns?: number;
  noOfTile?: number;
};

const PlaceHolderWatchDetailInfo: React.FC<PlaceHolderWatchDetailInfoProps> = ({
  showPlaceholderTitle = true,
  rowSet,
  columns,
  noOfTile
}) => {
  return (
    <PlaceholderContainer>
      {rangeSequence(rowSet)?.map((row) => (
        <WatchDetailsContainer key={row}>
          <Divider />
          <WatchDetailsAbout>
            <WatchDetailsAboutHeader>
              {showPlaceholderTitle && <Placeholder type="tab" size="xl" />}
            </WatchDetailsAboutHeader>
            <PlaceholderGridTextContent columns={columns}>
              {rangeSequence(noOfTile)?.map((tileNo) => (
                <WatchDetailsAboutItem key={tileNo}>
                  <Placeholder type="tab" size="l" />
                  <Placeholder type="tab" size="m" />
                </WatchDetailsAboutItem>
              ))}
            </PlaceholderGridTextContent>
          </WatchDetailsAbout>
        </WatchDetailsContainer>
      ))}
    </PlaceholderContainer>
  );
};

export default PlaceHolderWatchDetailInfo;
