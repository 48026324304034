import { tabletSize } from 'globals/styles';
import styled from 'styled-components';

export const EJournalContainer = styled.div`
  display: flex;
  gap: 3.2rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const EJournalPageBox = styled.div`
  margin-top: 12.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .separator {
    border-top: ${({ theme }) => `1px solid ${theme?.colors?.gray80}`};
  }
`;

export const MainMessage = styled.p`
  margin-top: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  ${({ theme }) => theme?.font?.large?.header1}
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header1}
  }
`;

export const SecondMessage = styled.p`
  margin-top: 1.6rem;
  margin-bottom: 4.8rem;
  ${({ theme }) => theme?.font?.large?.bodyL}
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyL}
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  max-width: 100%;
`;
