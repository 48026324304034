import React from 'react';

import { Placeholder, TTileRatio } from '@aerq/aerq-design-system';

import { CustomPlaceholder, PlaceholderTitle } from './placeholderWrapper.styled';

type PlaceHolderContainerProps = {
  showPlaceholderTitle?: boolean;
  width: string;
  ratio: TTileRatio;
  height?: string;
  marginTop: string;
};

const PlaceHolderContainerFullWidth: React.FC<PlaceHolderContainerProps> = ({
  showPlaceholderTitle,
  width,
  ratio,
  height,
  marginTop
}) => {
  return (
    <>
      <PlaceholderTitle>
        {showPlaceholderTitle && <Placeholder type="pill" size="xl" />}
      </PlaceholderTitle>
      <CustomPlaceholder marginTop={marginTop}>
        <Placeholder type="image" width={width} aspectRatio={ratio} height={height} />
      </CustomPlaceholder>
    </>
  );
};

export default PlaceHolderContainerFullWidth;
