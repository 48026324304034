import { SectionMeta } from './layoutApi';

export const isAllowedRatingsMatchWithItem = (itemMeta: SectionMeta, allowedRatings: string[]) => {
  // if item has no rating or
  // if allowed ratings has no item or
  // if it has then, it should be in allowed ratings
  return (
    allowedRatings.length === 0 ||
    itemMeta?.ratings?.length === 0 ||
    !('ratings' in (itemMeta as any)) ||
    itemMeta?.ratings === undefined ||
    itemMeta?.ratings?.some((rating) => allowedRatings.includes(rating))
  );
};
