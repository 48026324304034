/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The API for consuming CMS content
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AvailabilityEntry
 */
export interface AvailabilityEntry {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityEntry
     */
    'ID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityEntry
     */
    'path'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvailabilityEntry
     */
    'available'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvailabilityEntry
     */
    'protected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityEntry
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilityEntry
     */
    'ISDs'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChildrenType
 */
export interface ChildrenType {
    /**
     * Indicates if this field is mandatory
     * @type {boolean}
     * @memberof ChildrenType
     */
    'required'?: boolean;
    /**
     * The cardinality of the field. 0 = unbounded
     * @type {number}
     * @memberof ChildrenType
     */
    'cardinality'?: number;
    /**
     * The content type ID of the refenced contents.
     * @type {Array<string>}
     * @memberof ChildrenType
     */
    'referencedTypes'?: Array<string>;
    /**
     * Indicates if this field is translatable
     * @type {boolean}
     * @memberof ChildrenType
     */
    'translatable'?: boolean;
}
/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * The ID of the content
     * @type {string}
     * @memberof Content
     */
    'ID'?: string;
    /**
     * The version of the content
     * @type {number}
     * @memberof Content
     */
    'version'?: number;
    /**
     * The ID of the type of the content
     * @type {string}
     * @memberof Content
     */
    'contentTypeID'?: string;
    /**
     * The version of the type of the content
     * @type {number}
     * @memberof Content
     */
    'contentTypeVersion'?: number;
    /**
     * The language code of the content
     * @type {string}
     * @memberof Content
     */
    'langcode'?: string;
    /**
     * 
     * @type {object}
     * @memberof Content
     */
    'metadata'?: object;
    /**
     * 
     * @type {{ [key: string]: Array<Content>; }}
     * @memberof Content
     */
    'children'?: { [key: string]: Array<Content>; };
    /**
     * The returned Content is deleted or not
     * @type {boolean}
     * @memberof Content
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface ContentParams
 */
export interface ContentParams {
    /**
     * ID of the content type to fetch
     * @type {string}
     * @memberof ContentParams
     */
    'contentTypeID'?: string;
    /**
     * Version of the content type to fetch
     * @type {number}
     * @memberof ContentParams
     */
    'contentTypeVersion'?: number;
    /**
     * Recursion depth up to which filters are expanded into filter results. Default 0 = no filter expansion. Only relevant for Facade.
     * @type {number}
     * @memberof ContentParams
     */
    'depth'?: number;
    /**
     * What metadata fields to include in the response. Default value [] will show all fields. The parameter is applied recursively when rendering children.
     * @type {Array<string>}
     * @memberof ContentParams
     */
    'fields'?: Array<string>;
    /**
     * Select children. Default value [] will show all children. [\"-\"] will show none. It only affects the top level content being returned, not its children.
     * @type {Array<string>}
     * @memberof ContentParams
     */
    'selectChildren'?: Array<string>;
    /**
     * Select metadata fields. Default value [] will show all fields. [\"-\"] will show none. It only affects the top level content being returned, not its children.
     * @type {Array<string>}
     * @memberof ContentParams
     */
    'selectFields'?: Array<string>;
    /**
     * What locale to apply to the response
     * @type {string}
     * @memberof ContentParams
     */
    'langcode'?: string;
    /**
     * Whether to filter out expired content based on exhibitionPeriod fields or not
     * @type {boolean}
     * @memberof ContentParams
     */
    'ignoreExhibitionPeriod'?: boolean;
    /**
     * UNIX timestamp (seconds) to determine the current time used in exhibition period filtering. Default is current time.
     * @type {number}
     * @memberof ContentParams
     */
    'now'?: number;
}
/**
 * 
 * @export
 * @interface ContentType
 */
export interface ContentType {
    /**
     * The system id of the content type
     * @type {string}
     * @memberof ContentType
     */
    'contentTypeID'?: string;
    /**
     * The version of the content type
     * @type {number}
     * @memberof ContentType
     */
    'contentTypeVersion'?: number;
    /**
     * Indicates if fields are fixed or can dynamically be added at runtime
     * @type {boolean}
     * @memberof ContentType
     */
    'dynamic'?: boolean;
    /**
     * 
     * @type {{ [key: string]: MetadataType; }}
     * @memberof ContentType
     */
    'metadata'?: { [key: string]: MetadataType; };
    /**
     * 
     * @type {{ [key: string]: ChildrenType; }}
     * @memberof ContentType
     */
    'children'?: { [key: string]: ChildrenType; };
}
/**
 * 
 * @export
 * @interface ContentTypeParams
 */
export interface ContentTypeParams {
    /**
     * The version of the content type
     * @type {number}
     * @memberof ContentTypeParams
     */
    'contentTypeVersion'?: number;
}
/**
 * 
 * @export
 * @interface ContentsParams
 */
export interface ContentsParams {
    /**
     * ID of the content type to fetch. DEPRECATED.
     * @type {string}
     * @memberof ContentsParams
     * @deprecated
     */
    'contentTypeID'?: string;
    /**
     * The version of the content type. DEPRECATED.
     * @type {number}
     * @memberof ContentsParams
     * @deprecated
     */
    'contentTypeVersion'?: number;
    /**
     * What content type and version to filter for. The object key is the ID of the content type and the value is are the version.
     * @type {{ [key: string]: number; }}
     * @memberof ContentsParams
     */
    'contentTypes'?: { [key: string]: number; };
    /**
     * Recursion depth up to which filters are expanded into filter results. Default 0 = no filter expansion. Only relevant for Facade.
     * @type {number}
     * @memberof ContentsParams
     */
    'depth'?: number;
    /**
     * What metadata fields to include in the response. Default value [] will show all fields. The parameter is applied recursively when rendering children.
     * @type {Array<string>}
     * @memberof ContentsParams
     */
    'fields'?: Array<string>;
    /**
     * Select children. Default value [] will show all children. [\"-\"] will show none. It only affects the top level content being returned, not its children.
     * @type {Array<string>}
     * @memberof ContentsParams
     */
    'selectChildren'?: Array<string>;
    /**
     * Select metadata fields. Default value [] will show all fields. [\"-\"] will show none. It only affects the top level content being returned, not its children.
     * @type {Array<string>}
     * @memberof ContentsParams
     */
    'selectFields'?: Array<string>;
    /**
     * What locale to apply to the response
     * @type {string}
     * @memberof ContentsParams
     */
    'langcode'?: string;
    /**
     * What field values to filter for. Filter values must be passed as array of strings
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContentsParams
     */
    'filters'?: { [key: string]: Array<string>; };
    /**
     * Filter specific settings
     * @type {{ [key: string]: any; }}
     * @memberof ContentsParams
     */
    'settings'?: { [key: string]: any; };
    /**
     * What  fields to sort the result by
     * @type {Array<string>}
     * @memberof ContentsParams
     */
    'sortFields'?: Array<string>;
    /**
     * How many items to return at one time
     * @type {number}
     * @memberof ContentsParams
     */
    'limit'?: number;
    /**
     * Where in the result list to start fetching results
     * @type {number}
     * @memberof ContentsParams
     */
    'offset'?: number;
    /**
     * Show the deleted content or not
     * @type {boolean}
     * @memberof ContentsParams
     */
    'showDeleted'?: boolean;
    /**
     * Whether to filter out expired content based on exhibitionPeriod fields or not
     * @type {boolean}
     * @memberof ContentsParams
     */
    'ignoreExhibitionPeriod'?: boolean;
    /**
     * UNIX timestamp (seconds) to determine the current time used in exhibition period filtering. Default is current time.
     * @type {number}
     * @memberof ContentsParams
     */
    'now'?: number;
}
/**
 * 
 * @export
 * @interface MetadataType
 */
export interface MetadataType {
    /**
     * Indicates if this field is mandatory
     * @type {boolean}
     * @memberof MetadataType
     */
    'required'?: boolean;
    /**
     * The cardinality of the field. 0 = unbounded
     * @type {number}
     * @memberof MetadataType
     */
    'cardinality'?: number;
    /**
     * The type of the field - I.e. \"string\", \"integer\"
     * @type {string}
     * @memberof MetadataType
     */
    'valueType'?: MetadataTypeValueTypeEnum;
    /**
     * Indicates if this field is translatable
     * @type {boolean}
     * @memberof MetadataType
     */
    'translatable'?: boolean;
}

export const MetadataTypeValueTypeEnum = {
    Datetime: 'datetime',
    String: 'string',
    Integer: 'integer',
    Map: 'map',
    Url: 'url',
    Boolean: 'boolean',
    IntMap: 'int_map',
    Object: 'object'
} as const;

export type MetadataTypeValueTypeEnum = typeof MetadataTypeValueTypeEnum[keyof typeof MetadataTypeValueTypeEnum];

/**
 * 
 * @export
 * @interface ReadContentType400Response
 */
export interface ReadContentType400Response {
    /**
     * 
     * @type {ReadContentType400ResponseErrors}
     * @memberof ReadContentType400Response
     */
    'errors'?: ReadContentType400ResponseErrors;
    /**
     * 
     * @type {string}
     * @memberof ReadContentType400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ReadContentType400ResponseErrors
 */
export interface ReadContentType400ResponseErrors {
    /**
     * 
     * @type {string}
     * @memberof ReadContentType400ResponseErrors
     */
    'contentTypeVersion'?: string;
}
/**
 * 
 * @export
 * @interface ReadContentType500Response
 */
export interface ReadContentType500Response {
    /**
     * 
     * @type {string}
     * @memberof ReadContentType500Response
     */
    'error'?: string;
}

/**
 * CMSRuntimeApi - axios parameter creator
 * @export
 */
export const CMSRuntimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch an available asset file
         * @summary Fetch asset
         * @param {string} assetId The assetId of the asset to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('fetchAsset', 'assetId', assetId)
            const localVarPath = `/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all availability entries
         * @summary Fetch all availability entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAvailabilityEntries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch availability entry by id
         * @summary Fetch availability entry by id
         * @param {string} id The id of the availability entry to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAvailabilityEntry: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAvailabilityEntry', 'id', id)
            const localVarPath = `/availability/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a single content by id
         * @summary Fetch content
         * @param {string} id The id of the content to fetch
         * @param {ContentParams} contentParams 
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContent: async (id: string, contentParams: ContentParams, xSEATID?: string, xFLIGHTID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readContent', 'id', id)
            // verify required parameter 'contentParams' is not null or undefined
            assertParamExists('readContent', 'contentParams', contentParams)
            const localVarPath = `/content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xSEATID !== undefined && xSEATID !== null) {
                localVarHeaderParameter['X-SEAT-ID'] = String(xSEATID);
            }

            if (xFLIGHTID !== undefined && xFLIGHTID !== null) {
                localVarHeaderParameter['X-FLIGHT-ID'] = String(xFLIGHTID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **Fetch a single content type by id**  **The below table defines the HTTP Status codes that this API may return**  | Status Code      | Description     | Reason                                 | | ---------------- | ----------------| ---------------------------------------| | 200              | SUCCESS         | If the deletion was success.           | | 400              | BAD REQUEST     | If the request is not valid.           | | 404              | NOT FOUND       | If the content type is not found.      | | 500              | INTERNAL SERVER | If the server cannot serve the request | 
         * @summary Fetch content type
         * @param {string} id The id of the content type to fetch
         * @param {ContentTypeParams} [contentTypeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContentType: async (id: string, contentTypeParams?: ContentTypeParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readContentType', 'id', id)
            const localVarPath = `/content-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentTypeParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all available content types
         * @summary Fetch content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContentTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a paginated list of content
         * @summary Fetch contents
         * @param {ContentsParams} contentsParams 
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContents: async (contentsParams: ContentsParams, xFLIGHTID?: string, xSEATID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentsParams' is not null or undefined
            assertParamExists('readContents', 'contentsParams', contentsParams)
            const localVarPath = `/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFLIGHTID !== undefined && xFLIGHTID !== null) {
                localVarHeaderParameter['X-FLIGHT-ID'] = String(xFLIGHTID);
            }

            if (xSEATID !== undefined && xSEATID !== null) {
                localVarHeaderParameter['X-SEAT-ID'] = String(xSEATID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CMSRuntimeApi - functional programming interface
 * @export
 */
export const CMSRuntimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CMSRuntimeApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch an available asset file
         * @summary Fetch asset
         * @param {string} assetId The assetId of the asset to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all availability entries
         * @summary Fetch all availability entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAvailabilityEntries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailabilityEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAvailabilityEntries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch availability entry by id
         * @summary Fetch availability entry by id
         * @param {string} id The id of the availability entry to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAvailabilityEntry(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilityEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAvailabilityEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a single content by id
         * @summary Fetch content
         * @param {string} id The id of the content to fetch
         * @param {ContentParams} contentParams 
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readContent(id: string, contentParams: ContentParams, xSEATID?: string, xFLIGHTID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readContent(id, contentParams, xSEATID, xFLIGHTID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * **Fetch a single content type by id**  **The below table defines the HTTP Status codes that this API may return**  | Status Code      | Description     | Reason                                 | | ---------------- | ----------------| ---------------------------------------| | 200              | SUCCESS         | If the deletion was success.           | | 400              | BAD REQUEST     | If the request is not valid.           | | 404              | NOT FOUND       | If the content type is not found.      | | 500              | INTERNAL SERVER | If the server cannot serve the request | 
         * @summary Fetch content type
         * @param {string} id The id of the content type to fetch
         * @param {ContentTypeParams} [contentTypeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readContentType(id: string, contentTypeParams?: ContentTypeParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readContentType(id, contentTypeParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all available content types
         * @summary Fetch content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readContentTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readContentTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a paginated list of content
         * @summary Fetch contents
         * @param {ContentsParams} contentsParams 
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readContents(contentsParams: ContentsParams, xFLIGHTID?: string, xSEATID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readContents(contentsParams, xFLIGHTID, xSEATID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CMSRuntimeApi - factory interface
 * @export
 */
export const CMSRuntimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CMSRuntimeApiFp(configuration)
    return {
        /**
         * Fetch an available asset file
         * @summary Fetch asset
         * @param {string} assetId The assetId of the asset to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAsset(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.fetchAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all availability entries
         * @summary Fetch all availability entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAvailabilityEntries(options?: any): AxiosPromise<Array<AvailabilityEntry>> {
            return localVarFp.fetchAvailabilityEntries(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch availability entry by id
         * @summary Fetch availability entry by id
         * @param {string} id The id of the availability entry to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAvailabilityEntry(id: string, options?: any): AxiosPromise<AvailabilityEntry> {
            return localVarFp.fetchAvailabilityEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a single content by id
         * @summary Fetch content
         * @param {string} id The id of the content to fetch
         * @param {ContentParams} contentParams 
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContent(id: string, contentParams: ContentParams, xSEATID?: string, xFLIGHTID?: string, options?: any): AxiosPromise<Content> {
            return localVarFp.readContent(id, contentParams, xSEATID, xFLIGHTID, options).then((request) => request(axios, basePath));
        },
        /**
         * **Fetch a single content type by id**  **The below table defines the HTTP Status codes that this API may return**  | Status Code      | Description     | Reason                                 | | ---------------- | ----------------| ---------------------------------------| | 200              | SUCCESS         | If the deletion was success.           | | 400              | BAD REQUEST     | If the request is not valid.           | | 404              | NOT FOUND       | If the content type is not found.      | | 500              | INTERNAL SERVER | If the server cannot serve the request | 
         * @summary Fetch content type
         * @param {string} id The id of the content type to fetch
         * @param {ContentTypeParams} [contentTypeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContentType(id: string, contentTypeParams?: ContentTypeParams, options?: any): AxiosPromise<ContentType> {
            return localVarFp.readContentType(id, contentTypeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all available content types
         * @summary Fetch content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContentTypes(options?: any): AxiosPromise<Array<ContentType>> {
            return localVarFp.readContentTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a paginated list of content
         * @summary Fetch contents
         * @param {ContentsParams} contentsParams 
         * @param {string} [xFLIGHTID] The flight ID of a flight
         * @param {string} [xSEATID] The seatID of the ISD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readContents(contentsParams: ContentsParams, xFLIGHTID?: string, xSEATID?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.readContents(contentsParams, xFLIGHTID, xSEATID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CMSRuntimeApi - object-oriented interface
 * @export
 * @class CMSRuntimeApi
 * @extends {BaseAPI}
 */
export class CMSRuntimeApi extends BaseAPI {
    /**
     * Fetch an available asset file
     * @summary Fetch asset
     * @param {string} assetId The assetId of the asset to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public fetchAsset(assetId: string, options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).fetchAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all availability entries
     * @summary Fetch all availability entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public fetchAvailabilityEntries(options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).fetchAvailabilityEntries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch availability entry by id
     * @summary Fetch availability entry by id
     * @param {string} id The id of the availability entry to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public fetchAvailabilityEntry(id: string, options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).fetchAvailabilityEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a single content by id
     * @summary Fetch content
     * @param {string} id The id of the content to fetch
     * @param {ContentParams} contentParams 
     * @param {string} [xSEATID] The seatID of the ISD
     * @param {string} [xFLIGHTID] The flight ID of a flight
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public readContent(id: string, contentParams: ContentParams, xSEATID?: string, xFLIGHTID?: string, options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).readContent(id, contentParams, xSEATID, xFLIGHTID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **Fetch a single content type by id**  **The below table defines the HTTP Status codes that this API may return**  | Status Code      | Description     | Reason                                 | | ---------------- | ----------------| ---------------------------------------| | 200              | SUCCESS         | If the deletion was success.           | | 400              | BAD REQUEST     | If the request is not valid.           | | 404              | NOT FOUND       | If the content type is not found.      | | 500              | INTERNAL SERVER | If the server cannot serve the request | 
     * @summary Fetch content type
     * @param {string} id The id of the content type to fetch
     * @param {ContentTypeParams} [contentTypeParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public readContentType(id: string, contentTypeParams?: ContentTypeParams, options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).readContentType(id, contentTypeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all available content types
     * @summary Fetch content types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public readContentTypes(options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).readContentTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a paginated list of content
     * @summary Fetch contents
     * @param {ContentsParams} contentsParams 
     * @param {string} [xFLIGHTID] The flight ID of a flight
     * @param {string} [xSEATID] The seatID of the ISD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSRuntimeApi
     */
    public readContents(contentsParams: ContentsParams, xFLIGHTID?: string, xSEATID?: string, options?: AxiosRequestConfig) {
        return CMSRuntimeApiFp(this.configuration).readContents(contentsParams, xFLIGHTID, xSEATID, options).then((request) => request(this.axios, this.basePath));
    }
}


