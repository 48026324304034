import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useShallow } from 'zustand/react/shallow';

import {
  ListenRelatedProps,
  TPlayerState,
  TAudioSocketMessage,
  TPodcastEpisodeItem
} from 'globals/types/listen';
import { relatedContents } from 'globals/cms-helper/movieHelper';
import { fetchContentById, fetchPageSectionChildren } from 'globals/cms-helper/layoutApi';
import { useScrollToTop } from 'globals/hooks';
import { AerenaContent, AerenaContentType } from 'globals/cms-helper/types';
import { TPlaceHolderContent, placeHolderContentListenDetailsPodcast } from 'utils/placeHolderData';
import { useGlobalStore } from 'globals/store';
import {
  appendPlayerQueue,
  clearPlayerQueue,
  pauseEpisode,
  playEpisode,
  playNextEpisode,
  playPreviousEpisode,
  seekEpisode,
  stopEpisode
} from 'utils/api/audioPlayer';
import { Spacer } from 'globals/styles';
import { getEnvValue } from 'utils/useConfig';

import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';
import HeroHeader from '../components/heroHeader';
import { EpisodesContainer } from '../components/episodesContainer';
import { FavoritesModal } from 'components/FavoritesModal';

const ListenDetailsPage: React.FC = () => {
  const [content, setContent] = useState<AerenaContent>(null);
  const [related, setRelated] = useState<ListenRelatedProps[]>([]);
  const [episodes, setEpisodes] = useState<AerenaContent[]>([]);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [playerState, setPlayerState] = useState<TPlayerState>();

  const { id } = useParams();
  const location: any = useLocation();
  const [contentType, setContentType] = useState<AerenaContentType>(location?.state?.type);

  const { selectedLanguage, isLanguageUpdated, setCurrentPodcastPlayed } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,

      isLanguageUpdated: state.isLanguageUpdated,
      setCurrentPodcastPlayed: state.setCurrentPodcastPlayed
    }))
  );

  const [error, setError] = useState(null);

  const { t } = useTranslation();

  useScrollToTop();

  useEffect(() => {
    if (getEnvValue('REACT_APP_AUDIO_WEBSOCKET_URL')) {
      const ws = new WebSocket(getEnvValue('REACT_APP_AUDIO_WEBSOCKET_URL'));

      ws.onmessage = (event) => {
        const data: TAudioSocketMessage = JSON.parse(event.data);
        const urlParams = data.uri && new URL(data.uri).searchParams;
        const podcastId = urlParams?.get('podcastId');
        const episodeId = urlParams?.get('episodeId');

        if (episodeId && podcastId) {
          setPlayerState({
            ID: episodeId,
            podcastId,
            isPlaying: data?.state === 'PLAYING',
            isStopped: data?.state === 'STOPPED',
            elapsedDuration: data?.elapsed,
            remainingDuration: data?.duration - data?.elapsed,
            totalDuration: data?.duration
          });
          setCurrentPodcastPlayed(data?.state === 'PLAYING' ? podcastId : '');
        }
      };
      return () => {
        ws.close();
      };
    }
  }, [setCurrentPodcastPlayed]);

  const fetchSelectedContent = useCallback(
    async (id: string) => {
      const res = await fetchContentById(id, { language: selectedLanguage });
      const relatedContent = await relatedContents(res, selectedLanguage);
      return { content: res, related: relatedContent };
    },
    [selectedLanguage]
  );

  useEffect(() => {
    const fetchEpisodes = async (content: AerenaContent) => {
      const episodes = await fetchPageSectionChildren(content, selectedLanguage);
      return { episodes };
    };

    const getContents = async () => {
      try {
        const { content, related } = await fetchSelectedContent(id);
        if (!contentType) setContentType(content?.contentTypeID as AerenaContentType);
        const getEpisodes = async () => {
          const { episodes } = await fetchEpisodes(content);
          setEpisodes(episodes as AerenaContent[]);
        };

        if (contentType === 'aerena_podcast') {
          await getEpisodes();
        }
        content && setContent(content as AerenaContent);
        related && setRelated(related as ListenRelatedProps[]);
        setContentLoading(false);
      } catch (err) {
        setError(err);
      }
    };

    // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
    if (id && isLanguageUpdated) {
      getContents();
    }
  }, [id, contentType, related, selectedLanguage, fetchSelectedContent, isLanguageUpdated]);

  if (error) {
    throw error;
  }

  let placeHolderContent: any;
  if (contentType === 'aerena_podcast') {
    placeHolderContent = placeHolderContentListenDetailsPodcast;
  }

  const onHandlePlayEpisode = useCallback(
    async (selectedEpisode: TPodcastEpisodeItem) => {
      if (!playerState?.podcastId || playerState?.podcastId !== id) {
        const clearResponse = await clearPlayerQueue();
        if (clearResponse?.status == 204) {
          const uris: string[] = episodes?.map(
            (el) =>
              `${getEnvValue('REACT_APP_FACADE_CLIENT_URL')}/${
                el?.children?.main?.[0]?.children?.main?.[0]?.ID
              }?podcastId=${id}&episodeId=${el?.ID}`
          );
          const appendResponse = await appendPlayerQueue(uris);
          if (appendResponse?.status == 201) {
            const idx = episodes.findIndex((el) => el?.ID === selectedEpisode?.ID);
            playEpisode(idx);
          }
        }
      } else {
        if (playerState?.ID === selectedEpisode?.ID) {
          playEpisode();
        } else {
          const idx = episodes.findIndex((el) => el?.ID === selectedEpisode?.ID);
          playEpisode(idx);
        }
      }
    },
    [episodes, id, playerState?.ID, playerState?.podcastId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSeekQuery = useCallback(
    debounce((position: number) => seekEpisode(position), 200),
    []
  );

  return (
    <>
      {contentLoading ? (
        placeHolderContent?.map((data: TPlaceHolderContent) => (
          <PlaceHolderWrapper
            type={data.type}
            width={data.width}
            ratio={data.ratio}
            key={data.id}
            showPlaceholderTitle={data.showPlaceholderTitle}
            noOfTile={data.noOfTile}
            rowSet={data.rowSet}
            columns={data.column}
            height={data.height}
            marginTop={data.marginTop}
          />
        ))
      ) : (
        <>
          <FavoritesModal favoriteType="listen" />
          <HeroHeader content={content} />
          <EpisodesContainer
            episodes={episodes}
            episodesHeader={t('listen:details.episodes.title')}
            playButtonText={t('listen:details.button.play')}
            onPlay={onHandlePlayEpisode}
            onPause={pauseEpisode}
            onStop={stopEpisode}
            onPrev={playPreviousEpisode}
            onNext={playNextEpisode}
            onSeek={delayedSeekQuery}
            playerState={playerState}
          />
        </>
      )}
      <Spacer />
    </>
  );
};

export default ListenDetailsPage;
