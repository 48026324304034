import axios from 'axios';

import { TAudioPlayerQueue, TAudioPlayerStatus } from 'globals/types/audio';
import { getEnvValue } from 'utils/useConfig';

const REACT_APP_AUDIO_URL = getEnvValue('REACT_APP_AUDIO_URL');

export const getPlayerStatus = () => {
  return axios.get<TAudioPlayerStatus>(`${REACT_APP_AUDIO_URL}/v1/audio-player/status`);
};

export const getPlayerQueue = () => {
  return axios.get<TAudioPlayerQueue[]>(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`);
};

export const appendPlayerQueue = (uris: string[]) => {
  return axios.put(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`, uris, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const clearPlayerQueue = () => {
  return axios.delete(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue`);
};

export const getPlayerQueueByTrackId = (trackId: string) => {
  return axios.get<TAudioPlayerQueue>(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue/${trackId}`);
};

export const clearPlayerQueueByTrackId = (trackId: string) => {
  return axios.delete(`${REACT_APP_AUDIO_URL}/v1/audio-player/queue/${trackId}`);
};

export const playEpisode = (trackIndex?: number) => {
  let url = `${REACT_APP_AUDIO_URL}/v1/audio-player/play`;

  if (typeof trackIndex === 'number')
    url = `${REACT_APP_AUDIO_URL}/v1/audio-player/play/${trackIndex}`;

  return axios.post(url);
};

export const pauseEpisode = () => {
  return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/pause`);
};

export const playNextEpisode = () => {
  return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/next`);
};

export const playPreviousEpisode = () => {
  return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/previous`);
};

export const stopEpisode = () => {
  return axios.post(`${REACT_APP_AUDIO_URL}/v1/audio-player/stop`);
};

export const shuffleEpisode = (mode: boolean) => {
  return axios.post(
    `${REACT_APP_AUDIO_URL}/v1/audio-player/shuffle`,
    { mode },
    { headers: { 'Content-Type': 'application/json' } }
  );
};

export const seekEpisode = (position: number) => {
  return axios.post(
    `${REACT_APP_AUDIO_URL}/v1/audio-player/seek`,
    { position },
    { headers: { 'Content-Type': 'application/json' } }
  );
};
