import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionMeta } from 'globals/cms-helper/layoutApi';
import { AerenaContent } from 'globals/cms-helper/types';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';

import BackToTop from 'components/BackToTop/BackToTop';
import NoContentFound from 'views/watch/components/NoContentFound';
import { BillboardAd } from 'components/BillboardAd';
import { TileCompound } from 'components/TileCompound/TileCompound';
import { ViewMoreBackToTopWrapper } from 'components/WatchTileGridWrapper/WatchTileGridWrapper.styled';

type WatchBrowseAllWrapperProps = {
  pageSections: AerenaContent[];
  onClearSelection: () => void;
};

export const WatchSwimlanesWrapper: React.FC<WatchBrowseAllWrapperProps> = ({
  pageSections,
  onClearSelection
}) => {
  const { t } = useTranslation();

  useNavigationLogEvent('watch_page', pageSections);

  return (
    <>
      {pageSections?.length > 0 ? (
        pageSections?.map((section: AerenaContent) => {
          const sectionMeta = section?.metadata as SectionMeta;
          if (sectionMeta?.type !== 'TileSwiper') {
            return section?.contentTypeID === 'advertisement' ? (
              <BillboardAd key={section?.ID} section={section} />
            ) : (
              <TileCompound key={section?.ID} componentName={sectionMeta?.type} section={section} />
            );
          }
        })
      ) : (
        <NoContentFound
          description={t('watch:details.no_content_found.description')}
          onClearSelection={onClearSelection}
        />
      )}
      <ViewMoreBackToTopWrapper marginTop="1.2rem">
        <BackToTop />
      </ViewMoreBackToTopWrapper>
    </>
  );
};
