export const getAppConfig = () => {
  if (process.env.NODE_ENV === 'production') {
    return (window as any)?.appConfig;
  }
  return process.env;
};

export const getEnvValue = (key: string) => {
  const envValue = getAppConfig()?.[key];
  if (typeof envValue === 'boolean') {
    return envValue;
  } else if (typeof envValue === 'string') {
    try {
      const value = JSON.parse(envValue.toLowerCase());
      return value;
    } catch (e) {
      return envValue;
    }
  }
  return envValue;
};
