import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { useGlobalStore } from 'globals/store';
import { SectionMeta, fetchContentById } from 'globals/cms-helper/layoutApi';
import { getPageID, useResetWatchOptions } from 'globals/hooks';
import { AerenaContent } from 'globals/cms-helper/types';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';
import { QUERY_BY_PAGE_IDS } from 'utils/constants';
import { HomePageContent } from './homePage.styled';

import { TileCompound } from 'components/TileCompound/TileCompound';
import { ResetAudioSubtitles } from 'components/ResetAudioSubtitles';
import { BillboardAd } from 'components/BillboardAd';
import packageJson from '../../../package.json';
import { WorldMap } from 'components/styled/icons';
import MapContainer from '../../components/WorldMap/MapContainer';

const HomePage: React.FC = () => {
  const { selectedLanguage, isLanguageUpdated, aerenaStudioPreview } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      isLanguageUpdated: state.isLanguageUpdated,
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const homepageID = getPageID(QUERY_BY_PAGE_IDS.HOME);

  const { previewID, previewDate } = aerenaStudioPreview;

  // get the cached data of homepage
  const { data, isLoading } = useQuery({
    queryKey: [homepageID, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(homepageID, {
        language: selectedLanguage,
        depth: 2,
        previewDate
      }),
    enabled: !!previewID || (!previewID && isLanguageUpdated), // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang,
    staleTime: 0
  });

  const pageSections = (data?.children?.sections as any)?.[0]?.children?.contents;
  useNavigationLogEvent('home_page', pageSections);

  useResetWatchOptions();

  return (
    !isLoading &&
    pageSections?.length > 0 && (
      <>
        <HomePageContent>
          <MapContainer>
            <WorldMap />
          </MapContainer>
          {pageSections?.map((section: AerenaContent, index: number) => {
            const sectionMeta = section?.metadata as SectionMeta;
            return (
              section &&
              (section?.contentTypeID === 'advertisement' ? (
                <BillboardAd key={section?.ID} section={section} />
              ) : (
                <div
                  key={section?.ID}
                  data-testid={`${section?.ID}`}
                  id={`homepage-section-${index}`}
                >
                  <TileCompound
                    key={section?.ID}
                    componentName={sectionMeta?.type}
                    section={section}
                  />
                </div>
              ))
            );
          })}
        </HomePageContent>
        <ResetAudioSubtitles />
        <div style={{ textAlign: 'center' }}>v {packageJson.version}</div>
      </>
    )
  );
};

export default HomePage;
