import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { assetsPath, sdkInstance } from 'globals/api';
import { handleLogEvent } from 'globals/handleLogEvents';
import { PlayEventParams } from 'globals/types/logevent';
import { getEnvValue } from 'utils/useConfig';
import { TPlayableVideo } from 'globals/types';

export const usePlayVideo = (
  preRollVideo: boolean,
  preRollVideoURL: string,
  playableVideo: TPlayableVideo,
  setPlayableVideo: React.Dispatch<React.SetStateAction<TPlayableVideo>>,
  langCode: string
) => {
  useEffect(() => {
    if (!preRollVideo && preRollVideoURL === 'NA' && playableVideo) {
      const payload: PlayEventParams = {
        type: 'play',
        eventId: uuidv4(),
        languageSelected: langCode,
        timestamp: new Date().toISOString(),
        correlationId: uuidv4(),
        playedContent: {
          id: playableVideo.ID,
          type: playableVideo.type,
          name: playableVideo.title,
          fileName: playableVideo.path ?? ''
        }
      };

      handleLogEvent('PLAY MEDIA', payload);

      const getCurrPos = () => {
        return playableVideo.currentPos > 5 ? `t=${playableVideo.currentPos - 5}&` : '';
      };

      const getMediaId = () => {
        return playableVideo.type === 'aerena_movie' ? playableVideo.ID : playableVideo?.episodeId;
      };
      // Redirect to play the media
      let videoUrl = `${getEnvValue('REACT_APP_MEDIA_URL')}${assetsPath}${playableVideo.path}`;

      videoUrl += `?${getCurrPos()}mediaId=${getMediaId()}`;
      setPlayableVideo(null);
      sdkInstance?.startApp('media-player', videoUrl);
    }
  }, [langCode, playableVideo, preRollVideo, preRollVideoURL, setPlayableVideo]);
};
