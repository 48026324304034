import { create } from 'zustand';

import { INavigationEventStore, TNavigationEventOriginPage } from 'globals/types/logevent';

interface State {
  navigationEventPreviousUrl: string;
  navigationEventOriginPage: TNavigationEventOriginPage;
}

const initialState: State = {
  navigationEventPreviousUrl: '',
  navigationEventOriginPage: {
    type: '',
    languageSelected: '',
    clickedElement: {
      id: '',
      name: '',
      type: '',
      rank: 0,
      clickedElement: {
        rank: 0,
        type: '',
        name: '',
        id: ''
      }
    }
  }
};

export const useNavigationEventStore = create<INavigationEventStore & State>()((set) => ({
  ...initialState,
  setNavigationEventPreviousUrl(navigationEventPreviousUrl: string) {
    set({ navigationEventPreviousUrl });
  },
  setNavigationEventOriginPage(navigationEventOriginPage: TNavigationEventOriginPage) {
    set({ navigationEventOriginPage });
  }
}));
