import React from 'react';

import { Placeholder } from '@aerq/aerq-design-system';

import {
  ActionContainer,
  ContentLeft,
  LanguageContainer,
  LogoContainer,
  WelcomeContent
} from 'views/welcome/welcome.styled';
import {
  FlightInformationContainer,
  Departure,
  Destination,
  FlightNumber,
  FlightTime,
  Separator,
} from '../FlightInformation/FlightInformation.styled';
import { PlaceholderWelcomeContainer } from './placeholderWrapper.styled';

const PlaceHolderWelcome: React.FC = () => {
  return (
    <PlaceholderWelcomeContainer data-testid="placeholder-welcome">
      <LogoContainer>
        <Placeholder type="pill" size="l" />
      </LogoContainer>
      <WelcomeContent>
        <ContentLeft>
          <Placeholder type="tab" size="xxl" className="placeholder-title__line_1" />
          <Placeholder type="tab" size="l" className="placeholder-title__line_1" />
          <Placeholder type="tab" size="l" className="lng-title" />
          <LanguageContainer>
            <Placeholder type="tab" size="l" className="lng-btn" />
            <Placeholder type="tab" size="l" className="lng-btn" />
            <Placeholder type="tab" size="l" className="lng-btn" />
          </LanguageContainer>
        </ContentLeft>
        <FlightInformationContainer>
          <FlightNumber>
            <Placeholder type="tab" size="xxl" />
            <br />
            <Placeholder type="tab" size="s" />
            <Placeholder type="tab" size="m" />
          </FlightNumber>
          <Departure>
            <Placeholder type="tab" size="s" />
            <div />
            <Placeholder type="tab" size="m" />
          </Departure>
          <Destination>
            <Placeholder type="tab" size="s" />
            <div />
            <Placeholder type="tab" size="m" />
          </Destination>
          <FlightTime>
            <div>
              <Placeholder type="tab" size="s" />
              <Placeholder type="tab" size="m" />
            </div>
            <Separator />
            <div>
              <Placeholder type="tab" size="s" />
              <Placeholder type="tab" size="m" />
            </div>
          </FlightTime>
        </FlightInformationContainer>
      </WelcomeContent>
      <ActionContainer>
        <div className="welcome-btn">
          <Placeholder type="pill" size="xxl" className="button-pill" />
        </div>
      </ActionContainer>
    </PlaceholderWelcomeContainer>
  );
};

export default PlaceHolderWelcome;
