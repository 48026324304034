import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { pageXMargin, tabletSize } from 'globals/styles';

export const WatchSectionHeaderStyled = styled.p`
  ${({ theme }) => theme?.font?.large?.header2};

  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header2}
  }
  margin: 3.2rem 0;
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  padding-left: ${pageXMargin};
`;

export const WatchTrailerContent = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 3.2rem;
  padding-left: ${pageXMargin};
  padding-right: ${pageXMargin};
  scroll-padding: 0 ${pageXMargin};
  scroll-snap-type: x mandatory;
`;

export const WatchTrailers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WatchRelatedImg = styled(Link)`
  text-decoration: none;
  img {
    width: 42.4rem;
  }
`;

export const WatchRelatedContent = styled.div`
  display: flex;
  overflow: auto;
  gap: 3.2rem;
  padding-left: ${pageXMargin};
  padding-right: ${pageXMargin};
`;

export const WatchDetailsContainer = styled.div`
  padding: 0 ${pageXMargin};
`;

export const WatchDetailsLanguages = styled.div`
  display: grid;
  gap: 3.2rem;
  padding: 7.2rem 0 8.8rem 0;
`;

export const WatchDetailsAbout = styled.div`
  display: grid;
  gap: 3.2rem;
  padding: 7.2rem 0 8.8rem 0;
`;

export const Divider = styled.div`
  height: 0.1rem;
  background: black;
`;

export const WatchDetailsAboutContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;
`;

export const WatchDetailsAboutHeader = styled.div`
  ${({ theme }) => theme?.font?.large?.header2};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header2}
  }
  color: ${({ theme }) => theme?.colors?.primaryBlack};
`;

export const WatchDetailsAboutItem = styled.div`
  div:nth-child(1) {
    ${({ theme }) => theme?.font?.large?.bodyXL};
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.bodyXL}
    }
    margin-bottom: 0.8rem;
  }

  div:nth-child(2) {
    ${({ theme }) => theme?.font?.large?.bodyL};
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.bodyL}
    }
  }
`;
