/* eslint-disable @typescript-eslint/no-explicit-any */
import { AerenaContent } from './types';

// meta data of a podcast
export const podcastMeta = (content: AerenaContent) => {
  const podcastMeta = content?.metadata as any;
  const releaseDate = podcastMeta?.releaseYear as string;
  const producer = podcastMeta?.producer as string;
  const type = content?.contentTypeID;
  const title = podcastMeta?.title as string;
  const artist = podcastMeta?.artist?.join(', ') as string;
  const longDescription = podcastMeta?.description as string;
  const synopsis = podcastMeta?.synopsis as string;
  const availableLanguages = podcastMeta?.availableLanguages as string[];
  const originalLanguage = podcastMeta?.originalLanguage as string;
  const runtime = podcastMeta?.runtime;
  const numberOfEpisodes = podcastMeta?.numberOfEpisodes;
  return {
    releaseDate,
    producer,
    title,
    longDescription,
    synopsis,
    runtime,
    type,
    numberOfEpisodes,
    artist,
    availableLanguages,
    originalLanguage
  };
};
