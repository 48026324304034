import styled from 'styled-components';

export const NoContentFilterComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6.4rem auto;
  max-width: 118.4rem;
  width: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => theme?.font?.large?.header3};
  margin-bottom: 2.4rem;
  text-align: center;
`;

export const Description = styled.div`
  ${({ theme }) => theme?.font?.large?.header1};
  margin-bottom: 4.8rem;
  text-align: center;
`;

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;
