import React, { useState, useRef, useLayoutEffect } from 'react';
import { ExpandableText, SeeMore } from './ExpandableText.styled';

const ExpandableTextContainer = ({
  text,
  isTextDark,
  seeMoreLabel,
  seeLessLabel
}: {
  text: string;
  isTextDark: boolean;
  seeMoreLabel: string;
  seeLessLabel: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref, text]);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ExpandableText isExpanded={expanded} isTextDark={isTextDark} ref={ref}>
        {text}
      </ExpandableText>
      {showLink && (
        <SeeMore onClick={toggleText} isTextDark={isTextDark}>
          {expanded ? seeLessLabel : seeMoreLabel}
        </SeeMore>
      )}
    </>
  );
};

export default ExpandableTextContainer;
