import React, { useState, useEffect } from 'react';
import { intervalToDuration } from 'date-fns';
import { TIconName, Button } from '@aerq/aerq-design-system';
import { EpisodeContent, Title, EpisodeText, Info, EpisodeDetails } from './episode.styled';
import {
  PlayerPlaybackContainer,
  PlaybackTimersContainer,
  PlayerPlaybackWrapper,
  PlaybackTime,
  PlaybackProgress
} from './player.styled';
import PlayerButtons from './playerButtons';
import { TPlayerState } from 'globals/types/listen';

export interface EpisodeProps {
  title?: string;
  info?: string;
  className?: string;
  playButtonText?: string;
  showPlayer?: boolean;
  playerState?: TPlayerState;
  playActions?: {
    onPlay: () => void;
    onPause: () => void;
    onStop: () => void;
    onPrev: () => void;
    onNext: () => void;
    onSeek: (position: number) => void;
    hasPrev: boolean;
    hasNext: boolean;
  };
}

const formatTime = (seconds: number) => {
  //  (sec - (sec %= 60)) / 60 + (9 < sec ? ':' : ':0') + sec;
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const zeroPad = (num: number | string) => String(num).padStart(2, '0');
  return [duration.hours, duration.minutes || '00', duration.seconds || '00']
    .filter(Boolean)
    .map(zeroPad)
    .join(':');
};

export const Episode = React.forwardRef<HTMLDivElement, EpisodeProps>(
  ({ className, title, info, playButtonText, showPlayer, playerState, playActions }, ref) => {
    const [seek, setSeek] = useState(playerState?.elapsedDuration || 0);

    useEffect(() => {
      setSeek(playerState?.elapsedDuration);
    }, [playerState?.elapsedDuration]);

    const onSeekPositionUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
      const position = Number((event.target as any)?.value);
      setSeek(position);
      playActions.onSeek(position);
    };

    return (
      <EpisodeContent className={className} showPlayer={showPlayer} ref={ref}>
        <EpisodeDetails>
          <EpisodeText>
            {info && <Info>{info}</Info>}
            {title && <Title>{title}</Title>}
          </EpisodeText>
          {showPlayer ? (
            <PlayerButtons playActions={playActions} playerState={playerState} />
          ) : (
            <Button
              icon={'Play' as TIconName}
              buttonType="filled"
              buttonMode="dark"
              buttonSize="m"
              className="icon"
              handleClick={playActions.onPlay}
            >
              {playButtonText}
            </Button>
          )}
        </EpisodeDetails>
        {showPlayer && (
          <PlayerPlaybackContainer>
            <PlayerPlaybackWrapper>
              <PlaybackProgress
                type="range"
                min="0"
                max={playerState?.totalDuration}
                //TODO: Event handler should be changed as it'll trigger N number of times.
                onChange={onSeekPositionUpdate}
                value={seek}
              ></PlaybackProgress>
            </PlayerPlaybackWrapper>
            <PlaybackTimersContainer>
              <PlaybackTime>{formatTime(playerState?.elapsedDuration)}</PlaybackTime>
              <PlaybackTime>-{formatTime(playerState?.remainingDuration)}</PlaybackTime>
            </PlaybackTimersContainer>
          </PlayerPlaybackContainer>
        )}
      </EpisodeContent>
    );
  }
);

//forwardRef requires
Episode.displayName = 'Episode';
