import axios from 'axios';

export enum SurveyType {
  fullBlown = 'fullBlown',
  rating = 'rating',
  onboarding = 'onboarding'
}

export const getSurveyById = async (type: SurveyType): Promise<Record<string, string>> => {
  let resp;
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (type === SurveyType.onboarding) {
      resp = await axios.get<Record<string, string>>(`/assets/mockedAPI/onboarding-survey.json`);
    } else if (type === SurveyType.fullBlown) {
      resp = await axios.get<Record<string, string>>(`/assets/mockedAPI/fullBlown-survey.json`);
    }
  } else {
    resp = await axios.get<Record<string, string>>(`/api/v1/getSurveyId?type=${type}`);
  }
  return resp.data;
};
