import { AerenaContentType } from 'globals/cms-helper/types';

export const MAP_SECTIONS_IDS: Record<string, AerenaContentType[]> = {
  watch: ['aerena_movie', 'aerena_tv_show'],
  listen: ['aerena_podcast']
};

export enum QUERY_BY_PAGE_IDS {
  HOME = 'home_page',
  WATCH = 'watch_page',
  LISTEN = 'listen_page',
  WATCH_DETAIL = 'watch_detail_page',
  ABOUT = 'about_content'
}

export const PAGE_QUERY_KEYS_NOT_CACHED = ['watch_detail_page', 'ped_status', 'all_watch_progress', 'welcome_image'];

export enum QUERY_BY_CONTENT_IDS {
  ACTIVE_LANGS = 'active_languages',
  WATCH_OPTIONS = 'watch_options',
  WELCOME = 'welcome',
  ONBOARDING = 'onboarding_content',
  AUDIO_SUBS = 'audio_subtitles',
  APPS = 'apps_grid',
  THEME = 'theme'
}

export const ACTIVE_LIST = 'active_list';

export const ALL_CONTENT = 'all_content_filter';

export const IMAGE_SIZE_IDS: Record<string, string> = {
  img_68_poster: '68_poster',
  img_372_poster: '372_poster', //also for applications 3:4, about_us
  img_880_still: '880_still',
  img_1236_still: '1236_still',
  img_426_still: '426_still',
  img_90_poster: '90_poster',
  img_496_poster: '496_poster',
  img_680_poster: '680_poster',
  // static images
  img_1920_still: '1920_still', // welcome and onboarding background
  img_276_still: '276_still', // onboarding tiles 3:4
  img_576_poster: '576_still', // top section 2:1
  img_426_poster: '426_poster' // applications 4:3
};
