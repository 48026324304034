import React from 'react';

import { Placeholder, TTileRatio } from '@aerq/aerq-design-system';

import {
  PlaceholderContainerEpisode,
  PlaceHolderEpisodeItem,
  PlaceholderScrollerContent,
  PlaceholderTitle
} from './placeholderWrapper.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderScrollerProps = {
  showPlaceholderTitle?: boolean;
  width: string[] | string;
  ratio: TTileRatio;
  noOfTile: number;
};

const PlaceHolderEpisode: React.FC<PlaceHolderScrollerProps> = ({
  showPlaceholderTitle,
  width,
  ratio,
  noOfTile
}) => (
  <PlaceholderContainerEpisode>
    <PlaceholderTitle>
      {showPlaceholderTitle && <Placeholder type="tab" size="xl" />}
    </PlaceholderTitle>
    <PlaceholderScrollerContent>
      {rangeSequence(noOfTile)?.map((tileNo) => (
        <div key={tileNo}>
          <PlaceHolderEpisodeItem>
            <Placeholder key={tileNo} type="image" width={width} aspectRatio={ratio} />
            <Placeholder type="tab" size="m" />
            <Placeholder type="tab" size="l" />
            <Placeholder type="tab" size="s" className="placeholder-episode-content" />
            <Placeholder type="tab" size="s" className="placeholder-episode-content" />
            <Placeholder type="tab" size="m" />
          </PlaceHolderEpisodeItem>
        </div>
      ))}
    </PlaceholderScrollerContent>
  </PlaceholderContainerEpisode>
);

export default PlaceHolderEpisode;
