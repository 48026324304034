import { useEffect, useRef } from "react";

export const useComponentWillMount = (callback: any) => {
  const mounted = useRef(false);
  if (!mounted.current) callback();

  useEffect(() => {
    mounted.current = true;
  }, []);
};
