import styled from 'styled-components';

import { tabletSize } from 'globals/styles';

export const GridContainer = styled.div<{ xMargin?: string }>`
  margin: 0 ${({ xMargin }) => xMargin};
`;

export const NoContentWrapper = styled.div`
  margin: 3.2rem;
  margin-bottom: 4.8rem;
  text-align: center;
  ${({ theme }) => theme?.font?.large?.bodyL}
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyL}
  }
`;

export const ViewMoreBackToTopWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop ?? '3.2rem'};
  margin-bottom: 6.4rem;
  gap: 1.6rem;
`;

export const EndOfListText = styled.div`
  ${({ theme }) => theme?.font?.large?.bodyS};
  color: ${({ theme }) => theme?.colors?.gray60};
  height: 7.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
