import React from 'react';

import { TTileRatio } from '@aerq/aerq-design-system';

import PlaceHolderScroller from './PlaceHolderScroller';
import PlaceHolderSwiper from './PlaceHolderSwiper';
import PlaceHolderContainer from './PlaceHolderContainer';
import PlaceHolderOption from './PlaceHolderOption';
import { TPlaceHolderType } from 'utils/placeHolderData';
import PlaceHolderOnboarding from './PlaceHolderOnboarding';
import PlaceHolderContainerFullWidth from './PlaceHolderContainerFull';
import PlaceHolderEpisode from './PlaceHolderEpisode';
import PlaceHolderWelcome from './PlaceHolderWelcome';
import PlaceHolderWatchDetailInfo from './PlaceHolderWatchDetailInfo';
import PlaceHolderAboutUs from './PlaceHolderAboutUs';

interface PlaceHolderWrapperProps {
  type: TPlaceHolderType;
  width?: string[] | string;
  ratio: TTileRatio;
  showPlaceholderTitle?: boolean;
  columns?: number;
  noOfTile?: number;
  goBack?: () => void;
  height?: string;
  marginTop?: string;
  rowSet?: number;
  centered?: boolean;
}

const PlaceHolderWrapper: React.FC<PlaceHolderWrapperProps> = ({
  type,
  width,
  ratio,
  showPlaceholderTitle = true,
  columns,
  noOfTile,
  goBack,
  height,
  marginTop,
  rowSet,
  centered = false
}) => {
  switch (type) {
    case 'TileContainerAndGrid':
      return (
        <PlaceHolderContainer
          width="100%"
          noOfTile={noOfTile}
          columns={columns}
          ratio={ratio}
          showPlaceholderTitle={false}
        />
      );

    case 'Welcome':
      return <PlaceHolderWelcome />;

    case 'Onboarding':
      return <PlaceHolderOnboarding noOfTile={noOfTile} goBack={goBack} />;

    case 'AboutUs':
      return <PlaceHolderAboutUs />;

    case 'Option':
      return <PlaceHolderOption noOfTab={10} />;

    case 'TileFullWidth':
      return (
        <PlaceHolderContainerFullWidth
          width="100%"
          ratio={ratio}
          height={height}
          marginTop={marginTop}
        />
      );

    case 'EpisodeContainer':
      return (
        <PlaceHolderEpisode
          width={width}
          showPlaceholderTitle={showPlaceholderTitle}
          ratio={ratio}
          noOfTile={noOfTile}
        />
      );

    case 'WatchDetailInfo':
      return (
        <PlaceHolderWatchDetailInfo
          showPlaceholderTitle={showPlaceholderTitle}
          rowSet={rowSet}
          columns={columns}
          noOfTile={noOfTile}
        />
      );

    case 'TileSwiper':
      return (
        <PlaceHolderSwiper
          width={width}
          height={height}
          noOfTile={noOfTile}
          ratio={ratio}
          centered={centered}
        />
      );

    default:
      return (
        <PlaceHolderScroller
          width={width}
          noOfTile={noOfTile}
          ratio={ratio}
          showPlaceholderTitle={showPlaceholderTitle}
        />
      );
  }
};

export default PlaceHolderWrapper;
