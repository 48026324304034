import styled from 'styled-components';

import { pageXMargin } from 'globals/styles';

export const PlaceholderContainer = styled.div`
  margin-top: 6.4rem;
`;

export const PlaceholderContainerEpisode = styled.div`
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
`;

export const PlaceHolderEpisodeItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 42.6rem;
  gap: 1.2rem;

  .placeholder-episode-content {
    width: 90%;
  }
`;

export const PlaceholderTitle = styled.div`
  padding-left: ${pageXMargin};
`;

export const PlaceholderContent = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 ${pageXMargin}rem;
  gap: 3.2rem;
  margin-top: 2.9rem;
  padding: 0 ${pageXMargin};
`;

export const PlaceholderGridContent = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 3.2rem;
  padding: 0 ${pageXMargin};
`;

export const PlaceholderGridTextContent = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: 2;
  gap: 3.2rem;
  margin-top: 4rem;
`;

export const PlaceholderScrollerContent = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 3.2rem;
  margin-top: 2.9rem;
  padding: 0 ${pageXMargin};
  scroll-snap-type: x mandatory;
  scroll-padding: 0 ${pageXMargin}rem;
`;

export const PlaceHolderSwiperContent = styled.div<{ centered?: boolean }>`
  display: flex;
  overflow-x: auto;
  gap: 3.2rem;
  padding: 0 ${pageXMargin};
  scroll-snap-type: x mandatory;
  scroll-padding: 0 ${pageXMargin}rem;
  justify-content: ${({ centered }) => (centered ? 'center' : 'unset')};
`;

// welcome page styles
export const PlaceholderWelcomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme?.colors?.darkOverlay1};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  .tab {
    width: 60%;
  }

  .accessibility-placeholder {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme?.spacing?.large?.spacing_s1};
  }

  .placeholder-title__line_1 {
    width: 90%;

    :nth-child(2) {
      width: 60%;
    }
    :nth-child(3) {
      width: 60%;
    }
  }

  .lng-title {
    width: 50%;
  }

  .lng-btn {
    width: 80%;
  }

  .button-pill {
    width: 27rem;
  }
`;

// onboarding page styles
export const PlaceholderOnboardingContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme?.colors?.darkOverlay1};

  .tab {
    width: 80%;
  }

  .placeholder-title__line_1 {
    width: 70%;

    :nth-child(2) {
      width: 50%;
    }
  }

  .placeholder-slider__title {
    width: 70%;
    margin-bottom: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  }

  .swiper-tab {
    width: 40rem;
  }

  .text-placeholder {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme?.spacing?.large?.spacing_s2};
  }

  .accessibility-placeholder {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme?.spacing?.large?.spacing_s1};
  }

  .button-pill {
    width: 15%;
  }
`;

export const PlaceholderOnboardingContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  max-width: 72.8rem;
  width: 100%;
`;

export const PlaceholderOnboardingContentSwiper = styled.div`
  max-width: 88rem;
  width: 100%;
  background: ${({ theme }) => theme?.colors?.lightOverlay2};
  backdrop-filter: ${({ theme }) => theme?.shadow.flatDark};
  border-radius: 2.4rem;
  padding: 3.2rem;
  position: relative !important;
`;

export const CustomPlaceholder = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop};
`;

// about us placeholder style
export const PlaceHolderAboutUsContainer = styled.div`
  width: 100%;

  .placeholder-caption {
    margin-bottom: 1.6rem;
  }

  .placeholder-title {
    margin-bottom: 2.2rem;
  }

  .placeholder-button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    gap: 0.8rem;
    border: none;
    border-radius: 4rem;
    width: 40rem;
  }
`;

export const PlaceHolderAboutUsOverview = styled.div`
  height: 68.4rem;
  width: 100%;
  padding: 6.4rem;
  padding-top: 8.8rem;
  background: ${({ theme }) => theme?.colors?.darkOverlay2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .placeholder-description {
    max-width: 88rem;
    width: 100%;
    margin-bottom: 1.2rem;
  }
`;

export const PlaceHolderAboutUsValuesContainer = styled.div`
  height: 108rem;
  display: flex;
`;
