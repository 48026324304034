import { useCallback, useEffect } from 'react';
import { IFlightDataStore } from 'globals/types/flightdata';
import { useFlightDataStore } from 'globals/store/fapi.store';
import { useShallow } from 'zustand/react/shallow';
import { socketFAPI } from 'utils/socket';

export const usePassengerAnnouncementListener = () => {
  const { setFlightData } = useFlightDataStore(
    useShallow((state: IFlightDataStore) => ({
      setFlightData: state.setFlightData
    }))
  );

  const onDisplayPAChange = useCallback(
    (payload: any) => {
      setFlightData({ displayPA: payload?.displayPA });
    },
    [setFlightData]
  );

  useEffect(() => {
    socketFAPI.connect();

    socketFAPI.on('FLIGHT_DATA', onDisplayPAChange);

    return () => {
      socketFAPI.off('FLIGHT_DATA', onDisplayPAChange);
      socketFAPI.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
