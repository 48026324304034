import styled from 'styled-components';
import { tabletSize } from 'globals/styles';

export const Container = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const OnboardingOverlay = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme?.colors?.lightOverlay1};
  ${({ theme }) => theme?.shadow?.flatLight};
  display: flex;
  flex-direction: column;
`;

export const OnboardingContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;
  padding: 3.2rem 6.4rem;
  align-items: center;
`;

export const OnboardingLeft = styled.div`
  max-width: 72.8rem;
  width: 100%;

  h1 {
    ${({ theme }) => theme?.font?.large?.display2}
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.display2}
    }
    margin-bottom: 1.6rem;
  }

  p {
    ${({ theme }) => theme?.font?.large?.bodyL};
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.bodyL}
    }
  }
`;

export const OnboardingRight = styled.div`
  max-width: 88rem;
  width: 100%;
  background: ${({ theme }) => theme?.colors?.darkOverlay2};
  backdrop-filter: ${({ theme }) => theme?.shadow.flatDark};
  border-radius: 2.4rem;
  padding: 3.2rem;
  position: relative !important;

  .swiper {
    position: static !important;

    .swiper-pagination {
      bottom: -4rem !important;

      .swiper-pagination-bullet {
        width: 1.6rem !important;
        height: 1.6rem !important;
        background: ${({ theme }) => theme?.colors?.darkOverlay2} !important;

        &.swiper-pagination-bullet-active {
          background: ${({ theme }) => theme?.colors?.primaryBlack} !important;
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;

  .no_image.text {
    color: ${({ theme }) => theme?.colors?.primaryBlack} !important;
  }
`;

export const SliderContent = styled.div`
  margin-left: 3.2rem;

  h1 {
    ${({ theme }) => theme?.font?.large?.header2};
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.header2}
    }
    margin-bottom: 0.8rem;
  }

  p {
    ${({ theme }) => theme?.font?.large?.bodyL};
    color: ${({ theme }) => theme?.colors?.primaryBlack};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.bodyL}
    }
  }
`;

export const ActionTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3.2rem 3.2rem 0 2.4rem;
`;

export const ActionBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3.2rem 6.4rem;
`;
