import styled from 'styled-components';

export const AudioSubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 3.2rem;

  h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;
    border: 0.1rem solid ${({ theme }) => theme?.colors?.primaryBlack};
    border-radius: 0.8rem;
    height: 7.3rem;
    ${({ theme }) => theme?.font?.large?.header3}
  }
`;

export const AudioSubtitleWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2.4rem;
`;

export const AudioSubtitleBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AudioSubtitleContent = styled.div`
  max-width: 57.6rem;
  width: 100%;
`;

export const ActionButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  :nth-child(1) {
    padding-bottom: 3.2rem;
  }

  .action_btns {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
`;

export const MaxHeight = styled.div`
  max-height: 74.9rem;
  height: 100%;
  overflow-y: auto;
`;

export const AudioSubtitleList = styled.div`
  margin-bottom: 1.6rem;
`;

export const AudioSubtitleOverlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(120px);
  z-index: 100;
`;
