import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { useGlobalStore } from 'globals/store';
import { fetchContentById } from 'globals/cms-helper/layoutApi';
import { Spacer } from 'globals/styles';
import { TPlaceHolderContent, placeholderContentAppsPage } from 'utils/placeHolderData';
import { QUERY_BY_CONTENT_IDS } from 'utils/constants';

import { TileGridWrapper } from 'components/TileGridWrapper';
import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';

const AppsPage: React.FC = () => {
  const { selectedLanguage } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage
    }))
  );

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_BY_CONTENT_IDS.APPS, selectedLanguage.code],
    queryFn: () => fetchContentById(QUERY_BY_CONTENT_IDS.APPS, { language: selectedLanguage }),
    throwOnError: true
  });

  return isLoading ? (
    <>
      {placeholderContentAppsPage?.map((data: TPlaceHolderContent) => {
        return (
          <PlaceHolderWrapper
            type={data.type}
            width={data.width}
            ratio={data.ratio}
            key={data.id}
            showPlaceholderTitle={data.showPlaceholderTitle}
            noOfTile={data?.noOfTile}
            columns={data?.column}
          />
        );
      })}
    </>
  ) : (
    data && (
      <>
        <Spacer />
        <TileGridWrapper section={data} />
        <Spacer />
      </>
    )
  );
};

export default AppsPage;
