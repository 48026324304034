import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary';

import { Button } from '@aerq/aerq-design-system';
import { useShallow } from 'zustand/react/shallow';
import axios from 'axios';

import {
  ButtonContainer,
  ErrorContainer,
  ErrorPageBox,
  MainMessage,
  SecondMessage
} from './errorPage.styled';

import { sdkInstance } from 'globals/api';
import { useFlightDataStore, useGlobalStore } from 'globals/store';
import i18n from 'globals/translations/i18n';
import { IFlightDataStore } from 'globals/types/flightdata';
import { useComponentWillMount } from 'globals/hooks/useComponentWillMount';
import { getLocale } from 'utils/switchAppContext';
import { Spacer } from 'globals/styles';

import { FlightInformation } from 'components/FlightInformation';

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { isStartup } = useGlobalStore(
    useShallow((state) => ({
      isStartup: state.isStartup
    }))
  );

  const { setFlightData } = useFlightDataStore((state: IFlightDataStore) => ({
    setFlightData: state.setFlightData
  }));

  useEffect(() => {
    // Fetch the initial data quickly
    if (isStartup) {
      sdkInstance?.setContext('home');
    }
  }, [isStartup, setFlightData]);

  useComponentWillMount(() => {
    const code = getLocale();
    i18n.changeLanguage(code || 'de');
  });

  const location = useLocation();

  const { t } = useTranslation();

  const TIMEOUT = isStartup ? 60 : 8;
  const [counter, setCounter] = useState(TIMEOUT);

  const code = axios.isAxiosError(error) ? error.response?.status : null;

  const isServerError = code && code >= 500;
  const isClientError = code && code >= 400 && code < 500;
  const isStartPage = ['/', '/welcome', '/onboarding'].includes(location.pathname);
  const showSpacer = ['/welcome', '/onboarding'].includes(location.pathname);

  const mainMessage = isServerError
    ? t('errors:main_message_5xx')
    : isClientError
    ? t('errors:main_message_4xx')
    : t('errors:main_message');

  useEffect(() => {
    let timer: NodeJS.Timer;
    if ((isServerError || isStartup) && counter > 0) {
      timer = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isStartup) {
      if (counter === 0) {
        refreshClickHandler();
      }
    } else if (isServerError) {
      if (counter === 0) {
        resetErrorBoundary();
        setCounter(TIMEOUT);
      }
    }
  }, [TIMEOUT, counter, isServerError, isStartup, resetErrorBoundary]);

  const RefreshButton = () => (
    <Button width="33rem" textColor="white" handleClick={refreshClickHandler}>
      {t('errors:button_text_refresh')}
    </Button>
  );

  const refreshClickHandler = () => {
    window.location.reload();
  };

  const homeClickHandler = () => {
    window.location.replace('/');
  };

  const HomeButton = () => (
    <Button width="33rem" buttonType="outlined" textColor="black" handleClick={homeClickHandler}>
      {t('errors:button_text_homePage')}
    </Button>
  );

  return (
    <>
      {showSpacer && <Spacer />}
      <ErrorContainer>
        <ErrorPageBox>
          <MainMessage>{mainMessage}</MainMessage>
          <SecondMessage>
            <span className="bodyL bodyInverse">
              {t('errors:second_message')}&nbsp;
              {(isServerError || isStartup) && t('errors:refresh_seconds', { counter })}
            </span>
          </SecondMessage>
          {isStartup && <FlightInformation />}
          <ButtonContainer>
            {!isStartPage && <HomeButton />}
            <RefreshButton />
          </ButtonContainer>
        </ErrorPageBox>
      </ErrorContainer>
    </>
  );
};

export default ErrorPage;
