import axios, { AxiosRequestConfig } from 'axios';

import {
  TIconName,
  TTextAlignment,
  TTextPosition,
  TTileItem,
  TTileRatio
} from '@aerq/aerq-design-system';

import { ContentParams } from 'cms/api/runtime';
import { assetsPath, cmsAPI, keycloakInstance } from 'globals/api';
import { defaultLanguage, useGlobalStore } from 'globals/store/global.store';
import i18n from 'globals/translations/i18n';
import { TAllowedRating } from 'globals/types';
import { TImageType } from 'globals/types/image';
import { TLanguage } from 'globals/types/language';
import { ALL_CONTENT, IMAGE_SIZE_IDS, QUERY_BY_CONTENT_IDS } from 'utils/constants';
import { isValidTimestamp } from 'utils/IsValidTimestamp';
import { queryClient } from 'utils/reactQueryConfig';
import { tagList } from 'utils/tagList';
import { findObjectById, updateRatingValues } from './movieHelper';
import { AerenaContent, AerenaContentType } from './types';
import { useFlightDataStore } from 'globals/store';
import { IFlightData } from 'globals/types/flightdata';
import { filterContentWithEpisodes } from './filterExpiredContents';
import { getEnvValue } from 'utils/useConfig';

export type TileTextMap = {
  [key in string]: [string];
};

export type SectionMeta = {
  textAlignment?: TTextAlignment;
  textPosition?: TTextPosition;
  tileCaption?: TileTextMap;
  tileDescription?: TileTextMap;
  tileRatio?: TTileRatio;
  tileSub?: TileTextMap;
  tileTitle?: TileTextMap;
  showTitle?: boolean;
  showCaption?: boolean;
  showDescription?: boolean;
  showSub?: boolean;
  showTag?: boolean;
  showRankNo?: boolean;
  imageWidth?: string;
  imageType?: TImageType;
  tileWidth?: string[] | string;
  title?: string;
  description?: string;
  backgroundImage?: string;
  columns?: number[];
  hasSeeAll?: boolean;
  seeAllMaxLimit?: number;
  seeAllText?: string;
  queryParams?: string[];
  url?: string[];
  slidesPerView?: number;
  type?: 'TileContainer' | 'TileGrid' | 'TileScroller' | 'TileSwiper';
  categories?: string[];
  rank?: number;
  content?: string[];
  ratings?: string[];
};

export type TAppTileItem = TContentTileItem & {
  appId?: string;
  context?: string;
  ratings?: TAllowedRating[];
};

export type TPodcastTileItem = TContentTileItem & {
  context?: string;
  ratings?: TAllowedRating[];
  artist?: string[];
  category?: string[];
  numberOfEpisodes?: number;
  rank?: number;
  releaseYear?: string;
  producer?: string;
  copyright?: string;
  originalLanguage?: string;
  availableLanguages?: string[];
  touchId?: string;
};

export type TContentTileItem = TTileItem & {
  contentTypeID?: AerenaContentType;
};

export type TOnboardingMetadata = {
  categories: string[];
  dateUpdated?: string;
  rank?: number | string | null;
  releases: string[];
  url: string;
  title: string;
  description: string;
  backgroundImage: string;
  accessibilityText: string;
  continueButtonText: string;
  skipButtonText: string;
};

export type TOnboardingContent = {
  metadata: TOnboardingMetadata;
  contents: any;
};

export type TWelcomeMetadata = {
  title: string;
  description: string;
  languageHeader: string;
  flightNumber: string;
  departure: string;
  destination: string;
  duration: string;
  arrival: string;
  accessibilityText: string;
  startButtonText: string;
  backgroundImage: string;
};

export type TThemeMetadata = {
  name: string;
  mode: string;
};

export type TAboutMetadata = {
  title_header: string;
  caption_header: string;
  description_header: string;
  button_header: string;
  title_1_values: string;
  description_1_values: string;
  element_1_values_1: string;
  element_1_values_2: string;
  element_1_values_3: string;
  title_2_values: string;
  description_2_values: string;
  element_2_values_1: string;
  element_2_values_2: string;
  element_2_values_3: string;
  title_3_values: string;
  description_3_values: string;
  element_3_values_1: string;
  element_3_values_2: string;
  element_3_values_3: string;
  title_about: string;
  caption_about: string;
  description_about: string;
  caption_responsibilites: string;
  title_1_responsibilities: string;
  description_1_responsibilities: string;
  title_2_responsibilities: string;
  description_2_responsibilities: string;
  title_fleet: string;
  description_fleet: string;
  aircraft_1_fleet: string;
  aircraft_2_fleet: string;
  aircraft_3_fleet: string;
  title_history: string;
  description_history: string;
  section_1_history: string;
  section_1_year: string;
  section_2_history: string;
  section_2_year: string;
  section_3_history: string;
  section_3_year: string;
  section_4_history: string;
  section_4_year: string;
  title_destinations: string;
  description_destinations: string;
  caption_1_gallery: string;
  caption_2_gallery: string;
  caption_3_gallery: string;
  link: string;
  text_1_footer_en: string;
  text_2_footer_en: string;
  text_3_footer_en: string;
  text_1_footer_de: string;
  text_2_footer_de: string;
  text_3_footer_de: string;
};

export type TAboutContent = {
  metadata: TAboutMetadata;
  contents: any;
};

/**
 * Request a page from CMS
 * @param id
 * @returns the page content
 */
export const fetchContentById = async (
  id: string,
  options?: {
    language?: TLanguage;
    depth?: number;
    previewDate?: string;
  },
  signal?: AbortSignal
): Promise<AerenaContent> => {
  const flightData = useFlightDataStore.getState().flightData;
  const flightID = getUniqueFlightIdentifier(flightData);

  const contentParams: ContentParams = {
    ...(options?.language && { langcode: options?.language.code }),
    ...(options?.depth && { depth: options?.depth }),
    ...(options?.previewDate &&
      options?.previewDate === 'ignore' && { ignoreExhibitionPeriod: true }),
    ...(options?.previewDate &&
      isValidTimestamp(options?.previewDate) && { now: Number(options?.previewDate) })
  };

  let header = { signal };
  if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) {
    header = { ...header, ...(await createCMSHeader()) };
  }
  const data = (await cmsAPI.readContent(id, contentParams, null, flightID, header))
    .data as AerenaContent;

  if (id === ALL_CONTENT) {
    const filterExpiredContents = data?.children?.contents?.filter((content) =>
      filterContentWithEpisodes(content, data?.children?.contents)
    );
    data.children.contents = filterExpiredContents;
    return data;
  }
  return data;
};

const createCMSHeader = async () => {
  const axiosConfig: AxiosRequestConfig = {};

  if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) {
    try {
      await keycloakInstance?.updateToken(30);
      const { token } = keycloakInstance;
      axiosConfig.headers = { Authorization: `Bearer ${token}` };
      const profiles = (await axios.get(getEnvValue('REACT_APP_USER_MANAGER_URL'), axiosConfig))
        ?.data;
      const selectedProfileId = profiles?.payload?.find((p: any) => p?.selected)?.id;

      axiosConfig.headers['Controltower-Profileid'] = selectedProfileId;
    } catch (e) {
      console.error('UpdateToken / Fetch ProfileId failed', e);
    }
  }
  return axiosConfig;
};
/**
 * Request sections of a page from CMS
 * @param pageTitle
 * @returns the sections of the selected page
 */

export const fetchPageSections = async (
  pageTitle: string,
  selectedLanguage: TLanguage,
  signal?: AbortSignal
): Promise<AerenaContent[]> => {
  const flightData = useFlightDataStore.getState().flightData;
  const flightID = getUniqueFlightIdentifier(flightData);

  const contentParams: ContentParams = {
    ...(selectedLanguage && { langcode: selectedLanguage.code })
  };

  const pageContent: AerenaContent = await fetchContentById(
    pageTitle,
    { language: selectedLanguage },
    signal
  );
  const body = pageContent?.children?.sections?.[0]?.metadata as ContentParams;

  let header = { signal };
  if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) {
    header = { ...header, ...(await createCMSHeader()) };
  }

  const response = await cmsAPI.readContents({ ...body, ...contentParams }, flightID, null, header);
  return response.data as AerenaContent[];
};

export const fetchPageSectionChildren = async (
  section: AerenaContent,
  selectedLanguage: TLanguage,
  filters?: Record<string, string[]>,
  signal?: AbortSignal
): Promise<AerenaContent[]> => {
  const flightData = useFlightDataStore.getState().flightData;
  const flightID = getUniqueFlightIdentifier(flightData);

  // children of a section is either a filter or a list of reference.
  // if it is a filter, then fetch items by this filter
  // If Static content
  // Need to fetch each item separately.

  const contentParams: ContentParams = {
    ...(selectedLanguage ? { langcode: selectedLanguage.code } : {})
  };

  const sectionChildrenMetaData = section?.children?.filter?.[0].metadata as any;
  const sectionChildrenContent = section?.children?.content;
  const limit = sectionChildrenMetaData?.limit;
  if (sectionChildrenMetaData) {
    try {
      if (filters) {
        sectionChildrenMetaData.filters = filters;
      }

      let header = { signal };
      if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) {
        header = { ...header, ...(await createCMSHeader()) };
      }

      return (
        (
          await cmsAPI.readContents(
            {
              ...sectionChildrenMetaData,
              ...contentParams,
              ...{
                limit: limit || 0
              }
            },
            flightID,
            null,
            header
          )
        ).data as AerenaContent[]
      )?.map(updateRatingValues) as AerenaContent[];
    } catch (err) {
      return undefined;
    }
  }
  // For sections that have static content.
  // Static content should have contentID in metadata
  else if (sectionChildrenContent) {
    const items = sectionChildrenContent?.map((item) => {
      // item can be a reference. in this case, it should be found somewhere in the content
      if (item?.contentTypeID === '!reference') {
        return findObjectById(item?.ID, section);
      }
      return item;
    });
    return items;
  } else return null;
};

export const fetchSectionFilter = (section: AerenaContent) => ({
  contentTypes: Object.keys((section?.children?.filter?.[0]?.metadata as any)?.contentTypes || {}),
  genres: ((section?.children?.filter?.[0]?.metadata as any)?.filters.genres?.map((genre: string) =>
    genre?.replace('+', '')
  ) || []) as string[]
});

const getImageAsset = (
  imageContainer: AerenaContent,
  content: AerenaContent | AerenaContent[],
  sizeID: string
) => {
  const imgContainer =
    imageContainer?.contentTypeID === '!reference'
      ? findObjectById(imageContainer?.ID, content)
      : imageContainer;

  const matchedVariant = imgContainer?.children?.variants?.find(
    (variant: any) => variant?.metadata?.sizeID === sizeID
  );

  if (matchedVariant) {
    const contentTypeID = matchedVariant?.contentTypeID;
    const imgID = matchedVariant?.ID;

    if (contentTypeID === '!reference') {
      const imageObj = findObjectById(imgID, content);
      const imageAsset = imageObj?.children?.main?.[0];
      return {
        ...imageAsset,
        ratio: (imgContainer?.metadata as any)?.ratio,
        dominantColor: (imgContainer?.metadata as any)?.dominantColor?.split(',')
      };
    }
    const imageAsset = matchedVariant?.children?.main?.[0];

    return {
      ...imageAsset,
      ratio: (imgContainer?.metadata as any)?.ratio,
      dominantColor: (imgContainer?.metadata as any)?.dominantColor?.split(',')
    };
  }
  return null;
};

export const getImage = (
  content: AerenaContent,
  sizeID: string,
  outerContent?: AerenaContent[] | AerenaContent,
  langCode?: string
) => {
  const allContent = queryClient.getQueryData<AerenaContent>([ALL_CONTENT, langCode]);
  if (!content?.children?.images || content?.children?.images?.length === 0) {
    content = allContent?.children?.contents?.find((ct) => ct?.ID === content?.ID);
  }

  let selectedOuterContent: AerenaContent[] | AerenaContent;
  if (outerContent) {
    selectedOuterContent = outerContent;
  } else {
    selectedOuterContent = allContent?.children?.contents;
  }
  let imageAsset;
  if (content?.children?.images) {
    for (const imageContainer of content.children.images) {
      const asset = getImageAsset(imageContainer, selectedOuterContent, sizeID);
      if (asset) {
        imageAsset = asset;
        break;
      }
    }
  }

  return {
    imageSrc: imageAsset?.ID,
    imageAspectRatio: imageAsset?.ratio,
    dominantColor: imageAsset?.dominantColor
  };
};

export const getImageWithFallback = (
  content: AerenaContent,
  tileRatio: TTileRatio,
  imageType: TImageType,
  tileWidth: string,
  outerContent?: AerenaContent[] | AerenaContent,
  originalLanguageCode?: string // if provided use this, otherwise use content originalLanguage
) => {
  const sizeID = getImageSizeIDByTile(tileWidth, imageType);
  const selectedLangCode = useGlobalStore.getState().selectedLanguage.code;
  const originalLangCode = originalLanguageCode || (content?.metadata as any)?.originalLanguage;

  let { imageSrc, dominantColor, imageAspectRatio } = getImage(
    content,
    sizeID,
    outerContent,
    selectedLangCode
  );

  // if image is not found in target language, look for it in original langauge of content
  if (!imageSrc && selectedLangCode !== originalLangCode) {
    const activeLanguages = queryClient.getQueryData<Record<string, any>[]>([
      QUERY_BY_CONTENT_IDS.ACTIVE_LANGS
    ]);
    const activeLangCodes = activeLanguages?.map((lang) => lang?.code);
    const langCode = activeLangCodes?.includes(originalLangCode) ? originalLangCode : 'en';

    const {
      imageSrc: src,
      dominantColor: domColor,
      imageAspectRatio: ratio
    } = getImage(content, sizeID, outerContent, langCode);

    imageSrc = src;
    dominantColor = domColor;
    imageAspectRatio = ratio;
  }
  // fallback to en when no image is found in selected and original Lang which are not en
  else if (!imageSrc && originalLangCode !== 'en' && selectedLangCode !== 'en') {
    const {
      imageSrc: src,
      dominantColor: domColor,
      imageAspectRatio: ratio
    } = getImage(content, sizeID, outerContent, 'en');

    imageSrc = src;
    dominantColor = domColor;
    imageAspectRatio = ratio;
  }
  return { imageSrc, dominantColor, imageAspectRatio };
};

export const getImageSizeIDByTile = (tileWidth: string, imageType: string) => {
  const widthInPx = Number(tileWidth?.replace('rem', '')) * 10;
  return IMAGE_SIZE_IDS[`img_${widthInPx}_${imageType}`];
};

export const getBackgroundImage = (contents: AerenaContent) =>
  contents?.children?.backgroundImage?.[0]?.children?.variants?.[0]?.children?.main?.[0]?.ID;

export const getDominantColor = (content: AerenaContent) => {
  const imageContent: AerenaContent = content?.children?.images?.[0] as AerenaContent;
  if (imageContent?.contentTypeID === '!reference') {
    const image = findObjectById(imageContent?.ID, content);
    return image?.metadata?.dominantColor?.split(',');
  }

  return (imageContent?.metadata as any)?.dominantColor?.split(',');
};

export const getOnboardingChildrenContents = (contents: AerenaContent) => {
  return contents?.children?.content?.map((content: any) => ({
    title: content?.metadata?.caption,
    ID: content?.ID,
    description: content?.metadata?.description,
    imageSrc: assetsPath + getImage(content, getImageSizeIDByTile('27.6rem', 'still'))?.imageSrc
  }));
};

export const getOnboardingContents = (contents: AerenaContent) => {
  const backgroundImage = assetsPath + getBackgroundImage(contents);
  return {
    metadata: {
      ...contents?.metadata,
      backgroundImage
    } as TOnboardingMetadata,
    contents: getOnboardingChildrenContents(contents)
  };
};

export const getWelcomeContent = (content: AerenaContent) => {
  const backgroundImage = assetsPath + getBackgroundImage(content);
  return {
    ...content?.metadata,
    backgroundImage
  } as TWelcomeMetadata;
};

export const getAboutChildrenContents = (contents: AerenaContent) => {
  return contents?.children?.images?.map((content: any) => ({
    imageSrc: assetsPath + content?.children?.variants?.[0]?.children?.main?.[0]?.ID
  }));
};

export const getAboutContent = (contents: AerenaContent) => {
  return {
    metadata: {
      ...contents?.metadata
    } as TAboutMetadata,
    contents: getAboutChildrenContents(contents)
  };
};

export const getLanguages = async (id: string) => {
  const flightData = useFlightDataStore.getState().flightData;
  const flightID = getUniqueFlightIdentifier(flightData);

  let header = {};
  if (getEnvValue('REACT_APP_KEYCLOAK_ENABLED')) {
    header = await createCMSHeader();
  }

  const languagesContent: AerenaContent = (await cmsAPI.readContent(id, {}, null, flightID, header))
    ?.data as AerenaContent;
  return (
    languagesContent
      ? languagesContent?.children?.languages?.map((language: any) => ({
          code: language?.children?.language?.[0]?.ID,
          name: language?.metadata?.name as string,
          icon: language?.metadata?.icon as TIconName,
          locale: language?.metadata?.locale as string
        }))
      : [defaultLanguage]
  ) as TLanguage[];
};

export const getTagIconAndValue = (contentTypeID: string) => {
  const tag = tagList?.find((tag: any) => tag.type === contentTypeID);
  if (tag) {
    return {
      tagValue: i18n.t([`tags:${tag.type}`]),
      tagIcon: tag.icon
    };
  }
  return {
    tagValue: '',
    tagIcon: ''
  };
};

export const getUniqueFlightIdentifier = (flightData: IFlightData) => {
  return flightData
    ? `${flightData?.flightNumber}_${flightData?.dest?.code}_${
        (flightData?.dest?.localTime ? new Date(flightData?.dest?.localTime) : new Date())
          ?.toISOString()
          ?.split('T')?.[0]
      }`
    : null;
};
