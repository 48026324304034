import { pageXMargin, tabletSize } from 'globals/styles';
import styled from 'styled-components';

export const SeeAllPageTitle = styled.div`
  margin: 0 ${pageXMargin};
  color: ${({ theme }) => theme?.colors?.gray80};
  ${({ theme }) => theme?.font?.large?.bodyS};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyS}
  }
`;
export const SeeAllContainer = styled.div`
  margin-bottom: 6.4rem;
  position: relative;
`;
export const EndOfListText = styled.div`
  ${({ theme }) => theme?.font?.large?.bodyS};
  color: ${({ theme }) => theme?.colors?.gray60};
  height: 16rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BackToTopAction = styled.div`
  position: fixed;
  bottom: 11.1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`;
