import styled from 'styled-components';

export const Progress = styled.div<{ isTextDark: boolean }>`
  width: 100%;
  height: 0.8rem;
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.darkOverlay2 : theme?.colors?.lightOverlay2};
`;

export const Bar = styled.div<{ width: number; isTextDark: boolean }>`
  width: ${({ width }) => `${width}%`};
  height: 100%;
  background-color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.primaryBlack : theme?.colors?.primaryWhite};
`;
