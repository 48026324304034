import React, { useCallback, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { useShallow } from 'zustand/react/shallow';
import { TileScroller } from '@aerq/aerq-design-system';
import { useTheme } from 'styled-components';

import { assetsPath, sdkInstance } from 'globals/api';
import { useGlobalStore } from 'globals/store';
import { fetchPageSectionChildren, getImageWithFallback } from 'globals/cms-helper/layoutApi';
import { WatchOptions } from 'views/watch/components/watchOptionsContainer';
import { TvShowSeasonContent } from './tvShowSection.styled';
import { AerenaContent } from 'globals/cms-helper/types';
import { videoPath } from 'globals/cms-helper/movieHelper';
import { useSeatNumber } from 'globals/hooks/useSeatNumber';
import { getEnvValue } from 'utils/useConfig';
import { formatMinuteRuntimeToInfo } from 'utils/runtimeFormat';
import { TEpisodeTileItem, TPlayableVideo } from 'globals/types';
import {
  calculateCurrentPercentage,
  calculateRemainingTimeInSec,
  getAllWatchProgress,
  getEpisodeCurrPosInSec,
  updateSelectedEpisodeInfoInStore
} from 'utils/api/contentRemainingRuntime';
import styles from './TvShowSection.module.css';

import PlaceHolderEpisode from 'components/PlaceHolderWrapper/PlaceHolderEpisode';
import { EpisodeDescription } from './EpisodeDescription';
import { useQuery } from '@tanstack/react-query';
import { pageXMargin } from 'globals/styles';

interface TvShowsSectionProps {
  xPadding: string;
  content: AerenaContent;
  seasons: AerenaContent[];
  episodes: AerenaContent[];
  originalLanguageCode: string;
  episodeTileItems: TEpisodeTileItem[];
  setEpisodeTileItems: React.Dispatch<React.SetStateAction<TEpisodeTileItem[]>>;
  setPreRollVideo: (val: boolean) => void;
  setPreRollVideoURL: (val: string) => void;
  setPlayableVideo: (val: TPlayableVideo) => void;
}

const TvShowsSection: React.FC<TvShowsSectionProps> = ({
  xPadding,
  content,
  seasons,
  episodes,
  originalLanguageCode,
  episodeTileItems,
  setEpisodeTileItems,
  setPreRollVideo,
  setPreRollVideoURL,
  setPlayableVideo
}) => {
  const { t } = useTranslation();

  const [seasonOptions, setSeasonOptions] = useState([]);
  const [currentEpisodes, setCurrentEpisodes] = useState<AerenaContent[]>(episodes);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const {
    selectedLanguage,
    lastWatchedEpisodeInSeries,
    setLastWatchedEpisodeInSeries,
    aerenaStudioPreview
  } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries,
      setLastWatchedEpisodeInSeries: state.setLastWatchedEpisodeInSeries,
      aerenaStudioPreview: state.aerenaStudioPreview
    }))
  );

  const seatNumber = useSeatNumber();
  const { previewID } = aerenaStudioPreview;

  const { data: allWatchProgress } = useQuery({
    queryKey: ['all_watch_progress'],
    queryFn: () => getAllWatchProgress(seatNumber),
    staleTime: 0
  });

  const isAnyEpisodeWatched = () => {
    const watchedEpisode = lastWatchedEpisodeInSeries?.find(
      (series) => series?.seriesId === content?.ID
    )?.episode;
    const idx = seasons?.findIndex((season) => season?.ID === watchedEpisode?.seasonId);
    const watchedSeasonIdx = idx === -1 ? 0 : idx;
    return { watchedSeasonIdx, watchedEpisode };
  };

  useEffect(() => {
    const { watchedSeasonIdx: idx, watchedEpisode } = isAnyEpisodeWatched();
    const constructSeasons = () => {
      const constructedSeasons = seasons?.map((season: any) => {
        return {
          ID: season?.ID,
          index: season?.metadata?.index,
          title: `${t('watch:details.season')} ${season?.metadata?.index}`,
          value: season,
          selected: season?.ID === watchedEpisode?.seasonId
        };
      });
      if (constructedSeasons[idx]) {
        // if no watched season, preselect the first season
        constructedSeasons[idx].selected = true;
      }
      return constructedSeasons;
    };
    setSeasonOptions(constructSeasons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyEpisodewithTileData = useCallback(
    (episodes: AerenaContent[], seasonIdx: number) => {
      const itemsWithTileData: TEpisodeTileItem[] = episodes?.map((episode: AerenaContent) => {
        const episodeMeta = episode?.metadata as any;
        const currentPos = getEpisodeCurrPosInSec(
          content?.ID,
          episode,
          lastWatchedEpisodeInSeries,
          allWatchProgress
        );
        const { imageSrc } = getImageWithFallback(
          episode,
          '16:9',
          'still',
          '42.6rem',
          episodes,
          originalLanguageCode
        );
        return {
          ID: episode?.ID,
          seasonId: episodeMeta?.seasonId?.[0],
          title: episodeMeta?.title,
          episodeNum: episodeMeta?.episode?.[0],
          seasonNum: seasonIdx.toString(),
          caption: [
            `${t('watch:details.episode.episode')} ${episodeMeta?.episode}`,
            formatMinuteRuntimeToInfo(episodeMeta?.runtime || 0)
          ].join(' • '),
          description: renderToString(<EpisodeDescription theme={theme} episode={episode} />),
          imageSrc: assetsPath + imageSrc,
          url: videoPath(episode),
          ratings: episodeMeta?.ratings || [''],
          runtime: episodeMeta?.runtime,
          currPosInSec: currentPos,
          progressWidth:
            currentPos > 0 ? calculateCurrentPercentage(episodeMeta?.runtime, currentPos / 60) : 0
        } as TEpisodeTileItem;
      });
      setEpisodeTileItems(itemsWithTileData?.filter(Boolean));
    },
    [
      allWatchProgress,
      content?.ID,
      lastWatchedEpisodeInSeries,
      originalLanguageCode,
      setEpisodeTileItems,
      t,
      theme
    ]
  );

  const fetchEpisodes = useCallback(
    async (content: AerenaContent, seasonIdx: number) => {
      try {
        const episodes: AerenaContent[] = await fetchPageSectionChildren(content, selectedLanguage);
        setLoading(false);
        setCurrentEpisodes(episodes);
        applyEpisodewithTileData(episodes, seasonIdx);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    [applyEpisodewithTileData, selectedLanguage, setCurrentEpisodes]
  );

  useEffect(() => {
    const { watchedSeasonIdx: idx } = isAnyEpisodeWatched();
    applyEpisodewithTileData(currentEpisodes, (seasons?.[idx]?.metadata as any)?.index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWatchProgress]);

  const selectSeasonHandler = useCallback(
    (item: any) => {
      setLoading(true);
      fetchEpisodes(item?.value, item?.index);
      setSeasonOptions((prev) =>
        prev?.map((option) => {
          if (option?.ID === item?.ID) {
            option.selected = true;
          } else {
            option.selected = false;
          }
          return option;
        })
      );
    },
    [fetchEpisodes]
  );

  const episodeClickHandler = (episode: TEpisodeTileItem) => {
    if (episode?.url && !previewID) {
      sdkInstance?.setContext('onboarding');

      setPreRollVideo(true);
      setPreRollVideoURL(
        `${getEnvValue(
          'REACT_APP_PRE_ROLL_AD_URL'
        )}?section=TVSHOW&isdId=${seatNumber}&platform=IFE&orientation=LANDSCAPE&limit=1`
      );
      // check if the next playable episode is already watched
      const remTimeInSecForSelectedEpisode = calculateRemainingTimeInSec(
        episode?.runtime,
        episode?.currPosInSec
      );
      // play it from the beginning if the episode is already watched
      const currPosInSec = remTimeInSecForSelectedEpisode <= 5 ? 0 : episode?.currPosInSec;

      setPlayableVideo({
        ID: content?.ID,
        episodeId: episode?.ID,
        type: content?.contentTypeID,
        title: episode?.title,
        path: episode?.url,
        currentPos: currPosInSec
      });

      updateSelectedEpisodeInfoInStore(
        lastWatchedEpisodeInSeries,
        setLastWatchedEpisodeInSeries,
        content,
        episode?.ID,
        episode?.seasonId,
        episode?.title,
        episode?.url,
        episode?.runtime,
        currPosInSec,
        episode?.episodeNum,
        episode?.seasonNum
      );
    }
  };

  return (
    <>
      <TvShowSeasonContent xPadding={xPadding}>
        {seasonOptions?.length > 0 && (
          <WatchOptions
            mainOptions={seasonOptions}
            secondaryOptions={[]}
            mainOptionsClickHandler={selectSeasonHandler}
            secondaryOptionsClickHandler={() => undefined}
          />
        )}
      </TvShowSeasonContent>
      {loading ? (
        <PlaceHolderEpisode
          width={'42.6rem'}
          showPlaceholderTitle={false}
          ratio={'16:9'}
          noOfTile={5}
        />
      ) : (
        episodeTileItems?.length > 0 && (
          <TileScroller
            className={styles.tap_highlight_false}
            theme={theme}
            textPosition="belowImage"
            showTitle={true}
            showCaption={true}
            showDescription={true}
            showSub={true}
            showTag={false}
            ratio="16:9"
            xPadding={pageXMargin}
            width={['20rem', '30rem', '42.6rem']}
            tiles={episodeTileItems}
            onTileClick={episodeClickHandler}
            seeMoreTextOptions={{
              seeLessLabel: t('watch:details.see_less'),
              seeMoreLabel: t('watch:details.see_more'),
              isTextDark: true
            }}
            noImageText={t('common:error_page.no_image.text')}
          />
        )
      )}
    </>
  );
};

export default TvShowsSection;
