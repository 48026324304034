import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tile } from '@aerq/aerq-design-system';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from 'styled-components';

import {
  WatchDetailsHeaderContent,
  WatchDetailsHeaderInfo,
  WatchDetailsHeader,
  WatchDetailsHeaderTitle,
  WatchDetailsHeaderType,
  PlayAction,
  WatchDetailsProgressBar,
  WatchDetailImageContainer,
  RemainingTime
} from './heroHeader.styled';

import ProgressBar from './progressBar';
import { hexToRgb, isTextDark } from 'utils/textColor';
import { assetsPath, sdkInstance } from 'globals/api';
import {
  getContentMetaData,
  getCurrentLangBundle,
  setGenresAudioSubs,
  videoPath
} from 'globals/cms-helper/movieHelper';
import { IHeroMeta } from 'globals/types/watch';
import { getImageWithFallback } from 'globals/cms-helper/layoutApi';
import { useGlobalStore } from 'globals/store';
import { AerenaContent, AerenaContentType } from 'globals/cms-helper/types';
import { useFavorites } from 'globals/hooks';
import { useSeatNumber } from 'globals/hooks/useSeatNumber';
import { TSelectedContent, TPlayableVideo, TCurrentSeason, TEpisodeTileItem } from 'globals/types';
import { formatMinuteRuntimeToInfo } from 'utils/runtimeFormat';
import { ACTIVE_LIST } from 'utils/constants';
import { getEnvValue } from 'utils/useConfig';
import {
  calculateCurrentPercentage,
  calculateRemainingTimeInMin,
  getMovieCurrentPosition,
  getLastWatchedEpisode,
  updateSelectedEpisodeInfoInStore,
  calculateRemainingTimeInSec
} from 'utils/api/contentRemainingRuntime';

import ExpandableText from 'components/ExpandableText/ExpandableText';
import { useFlightContextListener } from 'globals/hooks/useFlightContextListener';

interface HeroHeaderProps {
  content?: AerenaContent;
  info?: { key: string; value: any[] };
  season?: TCurrentSeason;
  episodes?: AerenaContent[];
  episodeTileItems: TEpisodeTileItem[];
  setEpisodeTileItems: React.Dispatch<React.SetStateAction<TEpisodeTileItem[]>>;
  setPreRollVideo: (val: boolean) => void;
  setPreRollVideoURL: (TPlayableVideo: string) => void;
  setPlayableVideo: (val: TPlayableVideo) => void;
}

const HeroHeader: React.FC<HeroHeaderProps> = ({
  content,
  info,
  season,
  episodes,
  episodeTileItems,
  setEpisodeTileItems,
  setPreRollVideo,
  setPreRollVideoURL,
  setPlayableVideo
}) => {
  const theme = useTheme();

  const [playableEpisode, setPlayableEpisode] = useState<TSelectedContent>({
    ID: episodes?.[0]?.ID,
    seasonId: season?.seasonId,
    seasonNum: season?.seasonNumber,
    episodeNum: (episodes?.[0]?.metadata as any)?.episode?.[0],
    title: (episodes?.[0]?.metadata as any)?.title,
    path: videoPath(episodes?.[0]),
    runtimeInMin: (episodes?.[0]?.metadata as any)?.runtime,
    currentPosInSec: 0,
    remainingTimeInMin: 0
  });
  const [playableMovie, setPlayableMovie] = useState<TSelectedContent>({
    ID: content?.ID,
    title: (content?.metadata as any)?.title,
    path: videoPath(content),
    runtimeInMin: (content?.metadata as any)?.runtime,
    currentPosInSec: 0,
    remainingTimeInMin: 0
  });

  const {
    selectedLanguage,
    isLanguageUpdated,
    aerenaStudioPreview,
    lastWatchedEpisodeInSeries,
    setLastWatchedEpisodeInSeries
  } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      isLanguageUpdated: state.isLanguageUpdated,
      aerenaStudioPreview: state.aerenaStudioPreview,
      lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries,
      setLastWatchedEpisodeInSeries: state.setLastWatchedEpisodeInSeries
    }))
  );

  const { previewID } = aerenaStudioPreview;

  const seatNumber = useSeatNumber();
  const contentType = content?.contentTypeID;

  useFlightContextListener(content?.ID, contentType, seatNumber, setPlayableMovie);

  const contentRuntime =
    contentType === 'aerena_movie' ? playableMovie?.runtimeInMin : playableEpisode?.runtimeInMin;
  const contentRemTime =
    contentType === 'aerena_movie'
      ? playableMovie?.remainingTimeInMin
      : playableEpisode?.remainingTimeInMin;
  const contentElapsedTime =
    contentType === 'aerena_movie'
      ? playableMovie?.currentPosInSec
      : playableEpisode?.currentPosInSec;

  const defaultDominantColor = hexToRgb(theme?.colors?.gray20);
  let headerImage: string;
  let meta: IHeroMeta = {};
  let infos: string;
  let dominantColor;
  let isDarkText;
  let playButtonLabel;

  const { data: genres } = useQuery({
    queryKey: [ACTIVE_LIST],
    queryFn: () => setGenresAudioSubs(selectedLanguage.code),
    select: (data) => {
      return data?.activeGenres
        ?.filter((genre: string) => {
          return (content?.metadata as any)?.genres?.includes(genre);
        })
        ?.map(
          (g: string) => getCurrentLangBundle(selectedLanguage.code, 'watchSecondaryOptions')?.[g]
        )
        ?.filter(Boolean);
    },
    enabled: content && isLanguageUpdated // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
  });

  useEffect(() => {
    (async () => {
      if (contentType === 'aerena_movie') {
        const pos = await getMovieCurrentPosition(content?.ID, seatNumber);
        setPlayableMovie({
          ID: content?.ID,
          title: (content?.metadata as any)?.title,
          path: videoPath(content),
          runtimeInMin: (content?.metadata as any)?.runtime,
          currentPosInSec: pos || 0,
          remainingTimeInMin:
            pos > 0 ? calculateRemainingTimeInMin((content?.metadata as any)?.runtime, pos) : 0
        });
      }
    })();
  }, [content, contentType, seatNumber]);

  useEffect(() => {
    if (contentType === 'aerena_tv_show') {
      const data = getLastWatchedEpisode(content?.ID, lastWatchedEpisodeInSeries);

      // Update the playable episode to the last watched episode
      if (data) {
        const remTimeInSecForLastWatchedEpisode = calculateRemainingTimeInSec(
          data.runtimeInMin,
          data.currentPosInSec
        );

        const episodeItems = [...episodeTileItems];
        const watchedIdx = episodeItems?.findIndex((episode) => episode?.ID === data?.episodeId);

        // update the episode pos and progress only if the pos > 0
        if (data.currentPosInSec && episodeTileItems?.length) {
          episodeItems[watchedIdx].currPosInSec = data.currentPosInSec || 0;
          episodeItems[watchedIdx].progressWidth =
            data.currentPosInSec > 0
              ? calculateCurrentPercentage(
                  episodeItems[watchedIdx]?.runtime,
                  data.currentPosInSec / 60
                )
              : 0;
          setEpisodeTileItems(episodeItems);
        }

        // if the remaining time is <= 5 seconds, then switch it to next episode if exist or first episode of that season.
        if (remTimeInSecForLastWatchedEpisode <= 5) {
          const nextPlayableEpisode = episodeItems?.[watchedIdx + 1] || episodeItems?.[0];

          if (nextPlayableEpisode) {
            // check if the next playable episode is same as current episode
            const AreCurrentAndNextEpisodeSame = nextPlayableEpisode.ID === episodeItems?.[0]?.ID;

            // check if the next playable episode is already watched
            const remTimeInSecForNextPlayableEpisode = calculateRemainingTimeInSec(
              nextPlayableEpisode.runtime,
              AreCurrentAndNextEpisodeSame ? 0 : nextPlayableEpisode.currPosInSec
            );

            // play it from the beginning, if (the episode is already watched) or (current and next episode are same)
            const currPosInSec = AreCurrentAndNextEpisodeSame
              ? 0
              : remTimeInSecForNextPlayableEpisode <= 5
              ? 0
              : nextPlayableEpisode.currPosInSec;

            updateSelectedEpisodeInfoInStore(
              lastWatchedEpisodeInSeries,
              setLastWatchedEpisodeInSeries,
              content,
              nextPlayableEpisode.ID,
              nextPlayableEpisode.seasonId,
              nextPlayableEpisode.title,
              nextPlayableEpisode.url,
              nextPlayableEpisode.runtime,
              currPosInSec,
              nextPlayableEpisode.episodeNum,
              nextPlayableEpisode.seasonNum
            );
            return;
          }
        }

        setPlayableEpisode({
          ID: data.episodeId,
          seasonId: data.seasonId,
          seasonNum: data.seasonNum,
          episodeNum: data.episodeNum,
          title: data.title,
          path: data.path,
          runtimeInMin: data.runtimeInMin,
          currentPosInSec: data.currentPosInSec,
          remainingTimeInMin: calculateRemainingTimeInMin(data.runtimeInMin, data.currentPosInSec)
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.ID, contentType, lastWatchedEpisodeInSeries]);

  if (content) {
    const { imageSrc, dominantColor: dominantCol } = getImageWithFallback(
      content,
      '16:9',
      'still',
      '88rem'
    );

    dominantColor = dominantCol;

    if (imageSrc) {
      headerImage = assetsPath + imageSrc;
    }

    meta = getContentMetaData(content);
    infos = [
      genres?.slice(0, 3)?.join(', '),
      meta?.ratings?.slice(0, 3)?.join(', '),
      meta?.releaseDate || meta?.releaseYear,
      contentType === 'aerena_tv_show'
        ? meta?.numberOfSeasons
        : formatMinuteRuntimeToInfo(meta?.runtime || 0)
    ]
      .filter(Boolean)
      .join(' • ');
    isDarkText = isTextDark(dominantCol ?? defaultDominantColor);
  }

  const { t } = useTranslation(['favorites', 'watch']);

  // show resume button only if something is watched
  if (contentElapsedTime >= 5) {
    if (contentType === 'aerena_movie') {
      playButtonLabel = t('watch:resumeButton');
    } else {
      playButtonLabel = `${t('watch:resumeButton')} ${
        playableEpisode?.seasonNum && `S${playableEpisode?.seasonNum} `
      }${playableEpisode?.episodeNum && `E${playableEpisode?.episodeNum}`}`;
    }
  }
  // show play button if not watched or pointed to fully watched content
  else {
    playButtonLabel =
      contentType === 'aerena_movie'
        ? t('watch:playButton')
        : `${t('watch:playButton')} ${
            playableEpisode?.seasonNum && `S${playableEpisode?.seasonNum} `
          }${playableEpisode?.episodeNum && `E${playableEpisode?.episodeNum}`}`;
  }

  const informationLabel = t('watch:details.information.title');
  const { toggleFavorite, isFavorite } = useFavorites('watch');
  const isContentFavorite = content ? isFavorite(content.ID) : false;
  const favoriteLabel = isContentFavorite
    ? t('favorites:removeFavorites')
    : t('favorites:addFavorites');

  const playPreRollVideo = () => {
    sdkInstance?.setContext('onboarding');
    setPreRollVideo(true);
    setPreRollVideoURL(
      `${getEnvValue('REACT_APP_PRE_ROLL_AD_URL')}?section=${
        contentType === 'aerena_movie' ? 'MOVIE' : 'TVSHOW'
      }&isdId=${seatNumber}&platform=IFE&orientation=LANDSCAPE&limit=1`
    );

    if (contentType === 'aerena_movie') {
      setPlayableVideo({
        ID: playableMovie?.ID,
        type: content?.contentTypeID as AerenaContentType,
        title: playableMovie?.title,
        path: playableMovie?.path,
        currentPos: playableMovie?.currentPosInSec
      });
    }
    if (contentType === 'aerena_tv_show') {
      setPlayableVideo({
        ID: content?.ID,
        episodeId: playableEpisode?.ID,
        type: content?.contentTypeID as AerenaContentType,
        title: playableEpisode?.title,
        path: playableEpisode?.path,
        currentPos: playableEpisode?.currentPosInSec
      });
      updateSelectedEpisodeInfoInStore(
        lastWatchedEpisodeInSeries,
        setLastWatchedEpisodeInSeries,
        content,
        playableEpisode?.ID,
        playableEpisode?.seasonId,
        playableEpisode?.title,
        playableEpisode?.path,
        playableEpisode?.runtimeInMin,
        playableEpisode?.currentPosInSec,
        playableEpisode?.episodeNum,
        playableEpisode?.seasonNum
      );
    }
  };

  return (
    <WatchDetailsHeader data-testid="hero-header-watch">
      <WatchDetailsHeaderContent
        dominantCol={dominantColor}
        defaultDominantCol={defaultDominantColor}
      >
        <WatchDetailsHeaderInfo>
          <WatchDetailsHeaderType isTextDark={isDarkText}>{infos}</WatchDetailsHeaderType>
          <WatchDetailsHeaderTitle isTextDark={isDarkText}>{meta?.title}</WatchDetailsHeaderTitle>
          <ExpandableText
            text={meta?.synopsis}
            isTextDark={isDarkText}
            seeMoreLabel={t('watch:details.see_more')}
            seeLessLabel={t('watch:details.see_less')}
          />
          <div style={{ marginTop: 'auto', width: 'fit-content' }}>
            <RemainingTime>
              {contentRemTime > 0 && (
                <WatchDetailsProgressBar isTextDark={isDarkText}>
                  <p>
                    {/* check if remaining time is less than a minute or not */}
                    {contentRuntime * 60 - contentElapsedTime < 60 && '<'} {contentRemTime}
                    {t('watch:remainingTime')}
                  </p>
                  <ProgressBar
                    width={calculateCurrentPercentage(contentRuntime, contentElapsedTime / 60)}
                    isTextDark={isDarkText}
                  />
                </WatchDetailsProgressBar>
              )}
            </RemainingTime>
            <PlayAction>
              <Button
                buttonMode={isDarkText ? 'dark' : 'light'}
                icon="Play"
                buttonSize="m"
                handleClick={playPreRollVideo}
                disabled={!!previewID}
              >
                {playButtonLabel}
              </Button>
              {info?.value?.some((it: any) => it?.value) && (
                <Button
                  icon={'Information'}
                  buttonMode={isDarkText ? 'dark' : 'light'}
                  buttonType="transparent"
                  handleClick={() =>
                    document
                      .getElementById('information')
                      ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                  }
                >
                  {informationLabel}
                </Button>
              )}
              <Button
                buttonType="transparent"
                icon={isFavorite(content?.ID) ? 'Close' : 'Star'}
                buttonMode={isDarkText ? 'dark' : 'light'}
                handleClick={() => toggleFavorite(content)}
              >
                {favoriteLabel}
              </Button>
            </PlayAction>
          </div>
        </WatchDetailsHeaderInfo>

        <WatchDetailImageContainer>
          <Tile
            theme={theme}
            imageSrc={headerImage || ''}
            width="100%"
            aspectRatio="16:9"
            textPosition="none"
            className="watch-header_image"
            noImageText={t('common:error_page.no_image.text')}
          />
        </WatchDetailImageContainer>
      </WatchDetailsHeaderContent>
    </WatchDetailsHeader>
  );
};

export default HeroHeader;
