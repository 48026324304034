import { tabletSize } from 'globals/styles';
import styled from 'styled-components';

export const EpisodeDescriptionContainer = styled.div`
  color: ${({ theme }) => theme?.colors?.body};

  ${({ theme }) => theme?.font?.large?.bodyS};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.bodyS}
  }

  .episode-synopsis {
    margin-bottom: ${({ theme }) => theme?.spacing?.large?.spacing_s1};
    @media screen and (max-width: ${tabletSize}) {
      margin-bottom: ${({ theme }) => theme?.spacing?.small?.spacing_s1};
    }
  }

  .episode-meta-key {
    ${({ theme }) => theme?.font?.large?.bodyXS};
    @media screen and (max-width: ${tabletSize}) {
      ${({ theme }) => theme?.font?.small?.bodyXS};
    }
  }

  .episode-meta-value {
    margin-bottom: ${({ theme }) => theme?.spacing?.large?.spacing_s3};
    @media screen and (max-width: ${tabletSize}) {
      margin-bottom: ${({ theme }) => theme?.spacing?.small?.spacing_s3};
    }
  }
`;
