import { pageYMargin } from 'globals/styles';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

export const FavoritesOuterContainer = styled.div`
  width: 58.9rem;
  max-width: 100%;
  position: fixed;
  display: block;
  z-index: 13;
  top: ${pageYMargin};
  right: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  ${({ theme }) => theme?.shadow?.shadowDark4}
  border-radius: ${({ theme }) =>
    `0 0 ${theme?.spacing?.large?.spacing_m1} ${theme?.spacing?.large?.spacing_m1}`};
  background-color: ${({ theme }) => theme?.colors?.bgColor1};
`;

export const FavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  padding: ${({ theme }) =>
    `${theme?.spacing?.large?.spacing_s3} ${theme?.spacing?.large?.spacing_s4} ${theme?.spacing?.large?.spacing_s3} ${theme?.spacing?.large?.spacing_s4}`};

  & > div {
    width: 100%;
  }

  .favorites-header {
    display: flex;
    align-items: center;
    .title {
      ${({ theme }) => theme?.font?.large?.header2}
    }
    .count {
      margin-left: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
    }
    .close-button {
      margin-left: auto;
    }
  }
  .favorite-type-select {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1.2rem;
  }
`;

export const SlideTransition = styled(CSSTransition)`
  &.slide-enter {
    max-height: 0;
    overflow: hidden;
  }

  &.slide-enter-active {
    max-height: 79.3rem;
    transition: ${({ theme }) =>
      `max-height ${theme?.duration?.fast4} ${theme?.easing?.['ease-in-subtle']}`};
  }

  &.slide-exit {
    max-height: 79.3rem;
  }

  &.slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: ${({ theme }) =>
      `max-height ${theme?.duration?.fast4} ${theme?.easing?.['ease-in-subtle']}`};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 13;
`;

export const FavoritesEmpty = styled.div`
  height: 58.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme?.spacing?.large?.spacing_s3};
  background-color: ${({ theme }) => theme?.colors?.gray0};
  border-radius: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  gap: 6rem;
  & :first-child {
    text-align: center;
    ${({ theme }) => theme?.font?.large?.header2};
  }
  & :last-child {
    text-align: center;
    ${({ theme }) => theme?.font?.large?.header4};
  }
`;

export const FavoritesDrawerStyled = styled.div`
  display: flex;
  top: ${pageYMargin};
  right: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  position: fixed;
  z-index: 13;
  height: 6.4rem;
  padding: ${({ theme }) =>
    `${theme?.spacing?.large?.spacing_s2} ${theme?.spacing?.large?.spacing_s4} ${theme?.spacing?.large?.spacing_s2} ${theme?.spacing?.large?.spacing_s4}`};
  border-radius: ${({ theme }) =>
    `0 0 ${theme?.spacing?.large?.spacing_s4} ${theme?.spacing?.large?.spacing_s4}`};
  background: ${({ theme }) => theme?.colors?.bgColor1};
  ${({ theme }) => theme?.shadow?.shadowLight4}
  justify-content: left;
  align-items: center;
  gap: ${({ theme }) => theme?.spacing?.large?.spacing_s2};
  cursor: pointer;
  background-color: ${({ theme }) => theme?.colors?.buttonLight1};
  color: ${({ theme }) => theme?.colors?.buttonDark1};
  ${({ theme }) => theme?.font?.large?.button1}
`;

export const FavoritesCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  height: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  border-radius: ${({ theme }) => theme?.spacing?.large?.spacing_s3};
  padding: 0.4rem;
  background-color: ${({ theme }) => theme?.colors?.primary1};
  color: ${({ theme }) => theme?.colors?.buttonDark1};
  ${({ theme }) => theme?.font?.large?.labelBold}
`;

export const FavCounterPlaceholder = styled.div`
  width: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  height: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
`;
