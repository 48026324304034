import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tile } from '@aerq/aerq-design-system';

import {
  ListenDetailsHeaderContent,
  ListenDetailsHeaderDetails,
  ListenDetailsHeaderInfo,
  ListenDetailsHeader,
  ListenDetailsHeaderTitle,
  ListenDetailsHeaderType,
  ListenDetailImageContainer
} from './heroHeader.styled';

import { hexToRgb, isTextDark } from 'utils/textColor';
import { assetsPath } from 'globals/api';
import { podcastMeta } from 'globals/cms-helper/podcastHelper';
import { IHeroMeta } from 'globals/types/listen';
import { getImageWithFallback } from 'globals/cms-helper/layoutApi';
import { AerenaContent } from 'globals/cms-helper/types';
import ExpandableText from 'components/ExpandableText/ExpandableText';
import { useFavorites } from 'globals/hooks';
import { PlayAction } from 'views/watch/components/heroHeader.styled';
import { useTheme } from 'styled-components';

interface HeroHeaderProps {
  content?: AerenaContent;
}

const HeroHeader: React.FC<HeroHeaderProps> = ({ content }) => {
  const theme = useTheme();

  let headerImage: string;
  let meta: IHeroMeta = {};
  let infos: string;
  let dominantColor;
  let isDarkText;

  const { t } = useTranslation();
  const defaultDominantColor = hexToRgb(theme?.colors?.gray20);

  if (content) {
    const { imageSrc, dominantColor: dominantCol } = getImageWithFallback(
      content,
      '1:1',
      'poster',
      '49.6rem'
    );
    dominantColor = dominantCol;

    if (imageSrc) {
      headerImage = assetsPath + imageSrc;
    }

    meta = podcastMeta(content);
    infos = [
      `${meta?.numberOfEpisodes} ${t('listen:details.episodes.title')}`,
      meta?.releaseDate && `${meta.releaseDate}`
    ]
      .filter(Boolean)
      .join(' • ');

    isDarkText = isTextDark(dominantColor ?? defaultDominantColor);
  }
  const { toggleFavorite, isFavorite } = useFavorites('listen');
  const isContentFavorite = content ? isFavorite(content.ID) : false;
  const favoriteLabel = isContentFavorite
    ? t('favorites:removeFavorites')
    : t('favorites:addFavorites');

  return (
    <ListenDetailsHeader>
      <ListenDetailsHeaderContent
        dominantCol={dominantColor}
        defaultDominantCol={defaultDominantColor}
        data-testid="hero-header-listen"
      >
        <ListenDetailsHeaderInfo>
          <ListenDetailsHeaderType isTextDark={isDarkText}>{infos}</ListenDetailsHeaderType>
          <ListenDetailsHeaderTitle isTextDark={isDarkText}>{meta?.title}</ListenDetailsHeaderTitle>
          <ListenDetailsHeaderDetails isTextDark={isDarkText}>
            {meta?.artist}
          </ListenDetailsHeaderDetails>
          <ExpandableText
            text={meta?.synopsis}
            isTextDark={isDarkText}
            seeMoreLabel={t('listen:details.see_more')}
            seeLessLabel={t('listen:details.see_less')}
          />
          <PlayAction>
            <Button
              buttonType="transparent"
              icon={isFavorite(content?.ID) ? 'Close' : 'Star'}
              buttonMode={isDarkText ? 'dark' : 'light'}
              handleClick={() => toggleFavorite(content)}
            >
              {favoriteLabel}
            </Button>
          </PlayAction>
        </ListenDetailsHeaderInfo>

        <ListenDetailImageContainer>
          <Tile
            theme={theme}
            imageSrc={headerImage || ''}
            width="100%"
            aspectRatio="1:1"
            textPosition="none"
            className="listen-header_image"
            noImageText={t('common:error_page.no_image.text')}
          />
        </ListenDetailImageContainer>
      </ListenDetailsHeaderContent>
    </ListenDetailsHeader>
  );
};

export default HeroHeader;
