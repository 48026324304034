import React, { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useQuery } from '@tanstack/react-query';

import { fetchContentById } from 'globals/cms-helper/layoutApi';
import { useGlobalStore } from 'globals/store';
import { QUERY_BY_PAGE_IDS } from 'utils/constants';
import { getPageID, useResetWatchOptions } from 'globals/hooks';
import { Spacer } from 'globals/styles';
import { AerenaContent } from 'globals/cms-helper/types';
import { TAudioSocketMessage } from 'globals/types/listen';
import { getEnvValue } from 'utils/useConfig';

import { TileCompound } from 'components/TileCompound/TileCompound';
import { BillboardAd } from 'components/BillboardAd';
import { FavoritesModal } from 'components/FavoritesModal';

export const ListenPage: React.FC = () => {
  const { selectedLanguage, isLanguageUpdated, setCurrentPodcastPlayed, aerenaStudioPreview } =
    useGlobalStore(
      useShallow((state) => ({
        selectedLanguage: state.selectedLanguage,
        isLanguageUpdated: state.isLanguageUpdated,
        setCurrentPodcastPlayed: state.setCurrentPodcastPlayed,
        aerenaStudioPreview: state.aerenaStudioPreview
      }))
    );

  useEffect(() => {
    if (getEnvValue('REACT_APP_AUDIO_WEBSOCKET_URL')) {
      const ws = new WebSocket(getEnvValue('REACT_APP_AUDIO_WEBSOCKET_URL'));

      ws.onmessage = (event) => {
        const data: TAudioSocketMessage = JSON.parse(event.data);
        const urlParams = data.uri && new URL(data.uri).searchParams;
        const podcastId = urlParams?.get('podcastId');

        if (podcastId) {
          setCurrentPodcastPlayed(data?.state === 'PLAYING' ? podcastId : '');
        }
      };
      return () => {
        ws.close();
      };
    }
  }, [setCurrentPodcastPlayed]);

  const listenPageID = getPageID(QUERY_BY_PAGE_IDS.LISTEN);

  const { previewID, previewDate } = aerenaStudioPreview;

  // get the cached data of listenpage
  const { data: page, isLoading } = useQuery({
    queryKey: [listenPageID, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(listenPageID, {
        language: selectedLanguage,
        depth: 2,
        previewDate
      }),
    enabled: !!previewID || (!previewID && isLanguageUpdated), // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang,
    staleTime: 0
  });

  const pageSections = (page?.children?.sections as any)?.[0]?.children?.contents;

  const modifiedDisplayedSections = useMemo(() => {
    return pageSections?.map((section: AerenaContent) => {
      if ((section?.metadata as any)?.type === 'TileSwiper') {
        (section.metadata as any).slidesPerView = 2.74;
      }
      return section;
    });
  }, [pageSections]);

  useResetWatchOptions();

  return (
    <div data-testid="listen-page">
      {!isLoading && modifiedDisplayedSections?.length > 0 && isLanguageUpdated && (
        <>
          <FavoritesModal favoriteType="listen" />
          {modifiedDisplayedSections?.map((section: any) => {
            const sectionMeta = section?.metadata;
            return section?.contentTypeID === 'advertisement' ? (
              <BillboardAd key={section?.ID} section={section} />
            ) : (
              <TileCompound key={section?.ID} componentName={sectionMeta?.type} section={section} />
            );
          })}
          <Spacer />
        </>
      )}
    </div>
  );
};
