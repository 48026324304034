import styled from 'styled-components';

export const HomePageFooter = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  margin-bottom: 6.4rem;
  margin-top: 1.2rem;
`;
