import { io } from 'socket.io-client';
import { getEnvValue } from './useConfig';

export const socketFAPI = io(getEnvValue('REACT_APP_FAPI_SOCKET_URL'), {
  transports: ['websocket'],
  reconnection: process.env.NODE_ENV === 'production',
  autoConnect: false,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 60000
});

export const socketPSS = io(getEnvValue('REACT_APP_PSS_CLIENT_URL'), {
  transports: ['websocket'],
  reconnection: process.env.NODE_ENV === 'production',
  autoConnect: false,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 60000
});

export const socketFC = (seatNumber: string) => {
  return io(`${getEnvValue('REACT_APP_FLIGHT_CONTEXT_SOCKET_URL')}/seat/${seatNumber}`, {
    transports: ['websocket'],
    reconnection: process.env.NODE_ENV === 'production',
    autoConnect: false,
    reconnectionDelay: 5000,
    reconnectionDelayMax: 60000
  });
};
