import React from 'react';
import { Button } from '@aerq/aerq-design-system';

import {
  NoContentFilterComponentContainer,
  Description,
  ActionsButton
} from './NoContentFound.styled';
import { NoContentInfo } from './NoContentInfo';
import { t } from 'i18next';

type NoContentFoundProps = {
  description: string;
  onClearSelection: () => void;
};

const NoContentFound: React.FC<NoContentFoundProps> = ({ description, onClearSelection }) => (
  <NoContentFilterComponentContainer>
    <NoContentInfo />
    <Description>{description}</Description>
    <ActionsButton>
      <Button buttonType="outlined" handleClick={onClearSelection}>
        {t('listen:subtitles.button.clearAllFilter')}
      </Button>
    </ActionsButton>
  </NoContentFilterComponentContainer>
);

export default NoContentFound;
