import { TIconName } from '@aerq/aerq-design-system';
import { AerenaContentType } from 'globals/cms-helper/types';
export type TTag = {
  icon: TIconName;
  type: AerenaContentType;
};
export const tagList: TTag[] = [
  {
    icon: 'watch',
    type: 'aerena_movie'
  },
  {
    icon: 'watch',
    type: 'aerena_tv_show'
  },
  {
    icon: 'listen',
    type: 'aerena_podcast'
  },
  {
    icon: 'app',
    type: 'aerena_application'
  },
  {
    icon: 'app',
    type: 'aerena_game'
  },
  {
    icon: 'listen',
    type: 'aerena_music'
  },
  {
    icon: 'app',
    type: 'aerena_microsite_tile'
  }
];
