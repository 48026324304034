import React from 'react';

import { AerenaContent } from 'globals/cms-helper/types';

import { BillboardAd } from 'components/BillboardAd';
import { RankScrollerWrapper } from 'components/RankScrollerWrapper';
import { TileContainerWrapper } from 'components/TileContainerWrapper';
import { TileGridWrapper } from 'components/TileGridWrapper';
import { TileScrollerWrapper } from 'components/TileScrollerWrapper';
import { TileSwiperWrapper } from 'components/TileSwiperWrapper';

export const TileComponents: Record<string, React.FC<{ section: AerenaContent }>> = {
  TileScroller: TileScrollerWrapper,
  TileGrid: TileGridWrapper,
  TileContainer: TileContainerWrapper,
  RankScroller: RankScrollerWrapper,
  Billboard: BillboardAd,
  TileSwiper: TileSwiperWrapper
};

export const TileCompound: React.FC<{
  componentName: string;
  section: AerenaContent;
}> = ({ componentName, section }) => {
  const RenderNonAvailableComponent: React.FC<{ section: AerenaContent }> = () => null;

  const Component = TileComponents[componentName]
    ? TileComponents[componentName]
    : RenderNonAvailableComponent;

  return <Component section={section} />;
};
