import i18n from 'globals/translations/i18n';

export const formatSecondRuntimeToMinute = (runtime: number) => {
  const { t } = i18n;

  const minutes = Math.round(runtime / 60);
  const seconds = runtime % 60;

  const minute = minutes > 0 ? `${minutes} ${t('watch:details.episode.runtime_minute')}` : '';
  const second = seconds > 0 ? `${seconds} ${t('watch:details.episode.runtime_second')}` : '';

  return runtime <= 30 ? second : minute;
};

export const formatMinuteRuntimeToInfo = (runtime: number) => {
  const { t } = i18n;

  const minute = runtime > 0 ? `${runtime} ${t('watch:details.episode.runtime_minute')}` : '';

  return minute;
};
