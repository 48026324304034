import React from 'react';

import { Placeholder, TTileRatio } from '@aerq/aerq-design-system';

import {
  PlaceholderContainer,
  PlaceholderGridContent,
  PlaceholderTitle
} from './placeholderWrapper.styled';

type PlaceHolderContainerProps = {
  showPlaceholderTitle?: boolean;
  width: string;
  ratio: TTileRatio;
  columns: number;
  noOfTile: number;
};

const PlaceHolderContainer: React.FC<PlaceHolderContainerProps> = ({
  showPlaceholderTitle,
  width,
  ratio,
  noOfTile = 3,
  columns = 3
}) => {
  return (
    <PlaceholderContainer>
      <PlaceholderTitle>
        {showPlaceholderTitle && <Placeholder type="pill" size="xl" />}
      </PlaceholderTitle>
      <PlaceholderGridContent columns={columns}>
        {Array(noOfTile)
          ?.fill('')
          ?.map((_, index) => (
            <Placeholder key={index} type="image" width={width} aspectRatio={ratio} />
          ))}
      </PlaceholderGridContent>
    </PlaceholderContainer>
  );
};

export default PlaceHolderContainer;
