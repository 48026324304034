export enum CmsImageRatio {
  image1_1 = '1.1',
  image2_1 = '2.1',
  image3_4 = '3.4',
  image4_3 = '4.3',
  image16_9 = '16.9',
  image21_9 = '21.9'
}

export enum CmsImageSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extra_large = 'extra_large'
}
