import React from 'react';

import { Placeholder } from '@aerq/aerq-design-system';

import { PlaceholderContainer, PlaceholderContent } from './placeholderWrapper.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderScrollerProps = {
  noOfTab: number;
};

const PlaceHolderOption: React.FC<PlaceHolderScrollerProps> = ({ noOfTab }) => (
  <PlaceholderContainer>
    <PlaceholderContent>
      {rangeSequence(noOfTab)?.map((tileNo) => (
        <div key={tileNo}>
          <Placeholder type="tab" size="xxl" />
        </div>
      ))}
    </PlaceholderContent>
  </PlaceholderContainer>
);

export default PlaceHolderOption;
