import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { WatchRelatedProps } from 'globals/types/watch';
import { fetchContentById, fetchPageSectionChildren } from 'globals/cms-helper/layoutApi';
import { useScrollToTop } from 'globals/hooks';
import { AerenaContent } from 'globals/cms-helper/types';
import { useGlobalStore } from 'globals/store';
import { useNavigationLogEvent } from 'globals/hooks/useNavigationLogEvent';
import {
  TPlaceHolderContent,
  placeHolderContentWatchDetailsMovie,
  placeHolderContentWatchDetailsTvShow
} from 'utils/placeHolderData';
import { QUERY_BY_PAGE_IDS } from 'utils/constants';

import { WatchDetails } from './components/watchDetails';
import PlaceHolderWrapper from 'components/PlaceHolderWrapper/PlaceHolderWrapper';
import { TCurrentSeason } from 'globals/types';

const WatchDetailsPage: React.FC = () => {
  const [content, setContent] = useState<AerenaContent>(null);
  const [related] = useState<WatchRelatedProps[]>([]);
  const [seasons, setSeasons] = useState<AerenaContent[]>([]);
  const [currentSeason, setCurrentSeason] = useState<TCurrentSeason>(null);
  const [initialEpisodes, setInitialEpisodes] = useState<AerenaContent[]>([]);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [seasonsEpisodesLoading, setSeasonsEpisodesLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.type === 'aerena_tv_show') {
      setSeasonsEpisodesLoading(true);
    }
  }, [location?.state]);

  useScrollToTop();

  const { selectedLanguage, isLanguageUpdated, lastWatchedEpisodeInSeries } = useGlobalStore(
    useShallow((state) => ({
      selectedLanguage: state.selectedLanguage,
      isLanguageUpdated: state.isLanguageUpdated,
      lastWatchedEpisodeInSeries: state.lastWatchedEpisodeInSeries
    }))
  );

  const { data: advertisement } = useQuery({
    queryKey: [QUERY_BY_PAGE_IDS.WATCH_DETAIL, selectedLanguage.code],
    queryFn: () =>
      fetchContentById(QUERY_BY_PAGE_IDS.WATCH_DETAIL, { language: selectedLanguage, depth: 1 }),
    enabled: isLanguageUpdated, // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
    staleTime: 0
  });

  useNavigationLogEvent('watch_detail_page', [content]);

  useEffect(() => {
    const fetchSelectedContent = async (id: string) => {
      try {
        const data = await fetchContentById(id, { language: selectedLanguage });
        // TODO: enable related content only when available to test before pushes to PROD
        // const relatedContent = await relatedContents(data, selectedLanguage);
        data && setContent(data);
        // relatedContent && setRelated(relatedContent);
      } catch (err) {
        setError(err);
      } finally {
        setContentLoading(false);
      }
    };
    // paxfe should wait systemUI language update before making any query, otherwise it makes double fetch for prev and new lang
    if (id && isLanguageUpdated) {
      fetchSelectedContent(id);
    }
  }, [id, selectedLanguage, isLanguageUpdated]);

  if (error) {
    throw error;
  }

  useEffect(() => {
    const fetchSeasonsAndEpisodes = async (content: AerenaContent) => {
      try {
        if (content?.contentTypeID === 'aerena_tv_show') {
          const seasons = await fetchPageSectionChildren(content, selectedLanguage);
          const watchedEpisode = lastWatchedEpisodeInSeries?.find(
            (series) => series?.seriesId === content?.ID
          )?.episode;
          const idx = seasons?.findIndex((season) => season?.ID === watchedEpisode?.seasonId);
          const seasonIndex = idx === -1 ? 0 : idx;
          const episodes = await fetchPageSectionChildren(seasons?.[seasonIndex], selectedLanguage);
          setCurrentSeason({
            seasonId: seasons?.[seasonIndex]?.ID,
            seasonNumber: (seasons?.[seasonIndex]?.metadata as any)?.index
          });
          setSeasons(seasons);
          setInitialEpisodes(episodes);
        }
      } finally {
        setSeasonsEpisodesLoading(false);
      }
    };

    if (content?.ID && content?.contentTypeID === 'aerena_tv_show') {
      fetchSeasonsAndEpisodes(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, selectedLanguage]);

  let placeHolderContent;
  if (location?.state?.type === 'aerena_tv_show') {
    placeHolderContent = placeHolderContentWatchDetailsTvShow;
  } else {
    placeHolderContent = placeHolderContentWatchDetailsMovie;
  }

  const loading = () => contentLoading || seasonsEpisodesLoading;
  if (loading()) {
    return (
      <>
        {placeHolderContent?.map((data: TPlaceHolderContent) => (
          <PlaceHolderWrapper
            type={data.type}
            width={data.width}
            ratio={data.ratio}
            key={data.id}
            showPlaceholderTitle={data.showPlaceholderTitle}
            noOfTile={data.noOfTile}
            rowSet={data.rowSet}
            columns={data.column}
            height={data.height}
            marginTop={data.marginTop}
          />
        ))}
      </>
    );
  } else {
    return (
      <WatchDetails
        content={content}
        contentType={content?.contentTypeID}
        related={related}
        seasons={seasons}
        currentSeason={currentSeason}
        initialEpisodes={initialEpisodes}
        advertisement={advertisement}
      />
    );
  }
};

export default WatchDetailsPage;
