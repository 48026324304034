import { TTileRatio } from '@aerq/aerq-design-system';

export type TPlaceHolderType =
  | 'TileScroller'
  | 'TileSwiper'
  | 'TileContainerAndGrid'
  | 'Option'
  | 'Onboarding'
  | 'TileFullWidth'
  | 'EpisodeContainer'
  | 'Welcome'
  | 'WatchDetailInfo'
  | 'AboutUs';

export type TPlaceHolderContent = {
  id: number;
  type: TPlaceHolderType;
  width?: string;
  ratio?: TTileRatio;
  showPlaceholderTitle?: boolean;
  noOfTile?: number;
  column?: number;
  paddingTop?: string;
  height?: string;
  marginTop?: string;
  rowSet?: number;
};

export const placeHolderContentWatchPage: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileScroller',
    width: '111rem',
    ratio: '16:9',
    showPlaceholderTitle: false,
    noOfTile: 9
  },
  {
    id: 1,
    type: 'Option',
    width: '0rem',
    ratio: '21:9',
    showPlaceholderTitle: false
  },
  {
    id: 2,
    type: 'TileScroller',
    width: '37.2rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 4
  },
  {
    id: 3,
    type: 'TileScroller',
    width: '57.6rem',
    ratio: '3:4',
    showPlaceholderTitle: false,
    noOfTile: 4
  },
  {
    id: 4,
    type: 'TileScroller',
    width: '57.6rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 4
  },
  {
    id: 5,
    type: 'TileScroller',
    width: '28rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 4
  }
];

export const placeHolderContentWatchDetailsMovie: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileFullWidth',
    ratio: 'unset',
    width: '100%',
    showPlaceholderTitle: false,
    height: '68rem'
  },
  {
    id: 1,
    type: 'TileScroller',
    width: '88.2rem',
    ratio: '16:9',
    showPlaceholderTitle: true,
    noOfTile: 1
  },
  {
    id: 2,
    type: 'WatchDetailInfo',
    showPlaceholderTitle: true,
    rowSet: 2,
    column: 3,
    noOfTile: 6
  }
];

export const placeHolderContentListenDetailsPodcast: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileFullWidth',
    ratio: 'unset',
    width: '100%',
    showPlaceholderTitle: false,
    height: '68rem'
  },
  {
    id: 1,
    type: 'TileScroller',
    width: '88.2rem',
    ratio: '16:9',
    showPlaceholderTitle: true,
    noOfTile: 1
  },
  {
    id: 2,
    type: 'WatchDetailInfo',
    showPlaceholderTitle: true,
    rowSet: 2,
    column: 3,
    noOfTile: 6
  }
];

export const placeHolderContentWatchDetailsTvShow: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileFullWidth',
    ratio: 'unset',
    width: '100%',
    showPlaceholderTitle: false,
    height: '68rem'
  },
  {
    id: 1,
    type: 'Option',
    width: '0rem',
    ratio: '21:9',
    showPlaceholderTitle: false
  },
  {
    id: 2,
    type: 'EpisodeContainer',
    width: '42.6rem',
    ratio: '16:9',
    showPlaceholderTitle: false,
    noOfTile: 5
  },
  {
    id: 3,
    type: 'WatchDetailInfo',
    showPlaceholderTitle: true,
    rowSet: 2,
    column: 3,
    noOfTile: 6
  }
];

export const placeholderContentHomePage: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileContainerAndGrid',
    width: '57.6rem',
    ratio: '16:9',
    showPlaceholderTitle: false,
    noOfTile: 3,
    column: 3
  },
  {
    id: 1,
    type: 'TileContainerAndGrid',
    width: '42.4rem',
    ratio: '1:1',
    showPlaceholderTitle: false,
    noOfTile: 8,
    column: 4
  },
  {
    id: 2,
    type: 'TileScroller',
    width: '37.2rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 9
  },
  {
    id: 3,
    type: 'TileScroller',
    width: '37.2rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 9
  },
  {
    id: 4,
    type: 'TileScroller',
    width: '37.2rem',
    ratio: '3:4',
    showPlaceholderTitle: true,
    noOfTile: 9
  }
];

export const placeholderContentAppsPage: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileContainerAndGrid',
    width: '42.4rem',
    ratio: '1:1',
    showPlaceholderTitle: false,
    noOfTile: 3,
    column: 4
  }
];

export const placeholderContentListenPage: TPlaceHolderContent[] = [
  {
    id: 0,
    type: 'TileSwiper',
    width: '68.04rem',
    height: '77.64rem',
    ratio: '1:1',
    showPlaceholderTitle: false,
    noOfTile: 9
  },
  {
    id: 1,
    type: 'TileScroller',
    width: '49.6rem',
    height: '62.8rem',
    ratio: '1:1',
    showPlaceholderTitle: true,
    noOfTile: 10
  }
];
