import { TLanguage } from 'globals/types/language';
import '../globals/types';

import { defaultLanguage } from 'globals/store/global.store';
import { sdkInstance } from 'globals/api';

export const switchAppContext = (context: string, url?: string) => {
  if (url) {
    if (context === 'fullscreen_app') {
      window.open(url, 'fullscreen_app');
    } else {
      sdkInstance?.setContext(context);
      window.location.replace(url);
    }
  } else {
    sdkInstance?.setContext(context);
  }
};

export const setFullscreen = (enable: boolean) => {
  sdkInstance?.fullscreenOverlayMode(enable);
};

export const setLocale = (lang: TLanguage) => {
  sdkInstance?.setLocale(lang.locale);
};

export const getLocale = () => {
  const systemLanguage = window.navigator?.language || 'de-DE';
  const code = systemLanguage?.split('-')?.[0];

  return code || defaultLanguage.code;
};
