import styled from 'styled-components';
import { Color } from 'utils/textColor';
import { tabletSize } from 'globals/styles';

export const WatchDetailsHeader = styled.div`
  min-height: 68rem;
  height: 100%;
  z-index: 0;
  position: relative;

  @media screen and (max-width: ${tabletSize}) {
    min-height: 62.4rem;
  }
`;

export const WatchSectionHeaderStyled = styled.div`
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  height: 9.6rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
`;

export const WatchDetailImageContainer = styled.div`
  width: 88rem;
  z-index: 1;

  .watch-header_image {
    border-radius: 0;
  }

  @media screen and (max-width: ${tabletSize}) {
    width: 44rem;
    height: 24.7rem;
  }
`;

export const WatchDetailsHeaderContent = styled.div<{
  dominantCol: Color | string;
  defaultDominantCol: Color | string;
}>`
  display: flex;
  background: ${({ dominantCol, defaultDominantCol }) =>
    dominantCol ? `rgb(${dominantCol})` : `rgb(${defaultDominantCol},0.6)`};
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 4.3%;
  padding: 3.2rem 6.4rem;
  width: 100%;
`;

export const WatchDetailsHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 68rem;
  width: 79.6rem;

  @media screen and (max-width: ${tabletSize}) {
    min-height: 45rem;
  }
`;

export const WatchDetailsHeaderType = styled.div<{ isTextDark: boolean }>`
  ${({ theme }) => theme?.font?.large?.caption};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.darkOverlay1 : theme?.colors?.lightOverlay1};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.caption}
  }
  letter-spacing: 0.06rem;
  text-align: left;
  text-transform: uppercase;
`;
export const WatchDetailsHeaderTitle = styled.div<{ isTextDark: boolean }>`
  ${({ theme }) => theme?.font?.large?.header1};
  color: ${({ theme, isTextDark }) =>
    isTextDark ? theme?.colors?.primaryBlack : theme?.colors?.primaryWhite};
  @media screen and (max-width: ${tabletSize}) {
    ${({ theme }) => theme?.font?.small?.header1};
  }
  text-align: left;
  max-width: 79.6rem;
  padding-top: 1.2rem;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

export const WatchDetailsHeaderDetailLabels = styled.div`
  margin-top: 1.2rem;
  display: flex;
  gap: 0.8rem;
`;

export const RemainingTime = styled.div`
  margin-top: auto;
`;

export const PlayAction = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  padding-top: 2rem;
  width: fit-content;
  margin-top: auto;
`;

export const WatchDetailsProgressBar = styled.div<{ isTextDark: boolean }>`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 1rem;

  p {
    color: ${({ theme, isTextDark }) =>
      isTextDark ? theme?.colors?.darkOverlay1 : theme?.colors?.lightOverlay1};
    ${({ theme }) => theme?.font?.large?.caption}
  }
`;
