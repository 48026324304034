import { QueryClient } from '@tanstack/react-query';
import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { get, set, del } from 'idb-keyval';

import { getPreviewData } from './previewConfig';

const { previewID } = getPreviewData();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: previewID ? 0 : Infinity,
      gcTime: previewID ? 0 : Infinity,
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

export function createIDBPersister(idbValidKey: IDBValidKey = 'CMS_QUERY_CACHE') {
  return {
    persistClient: async (client: PersistedClient) => {
      try {
        await set(idbValidKey, client);
      } catch (e) {
        console.error(e);
      }
    },
    restoreClient: async () => {
      try {
        return await get<PersistedClient>(idbValidKey);
      } catch (e) {
        console.error(e);
      }
    },
    removeClient: async () => {
      try {
        await del(idbValidKey);
      } catch (e) {
        console.error(e);
      }
    }
  } as Persister;
}
