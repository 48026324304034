import React from 'react';
import { CircleButton, TIconName } from '@aerq/aerq-design-system';
import { PlayerButtonsContainer } from './player.styled';
import { EpisodeProps } from './episode';

const PlayerButtons: React.FC<EpisodeProps> = ({ playActions, playerState }) => {
  return (
    <PlayerButtonsContainer>
      <CircleButton
        disabled={!playActions.hasPrev}
        icon="PrevTrack"
        buttonType="outlined"
        buttonMode="dark"
        size="xs"
        className="icon"
        handleClick={playActions.onPrev}
      />
      <CircleButton
        icon={playerState?.isPlaying ? 'Pause' : 'Play'}
        buttonType="filled"
        buttonMode="dark"
        size="m"
        className="icon"
        handleClick={() => (playerState?.isPlaying ? playActions.onPause() : playActions.onPlay())}
      />
      <CircleButton
        icon={'Stop' as TIconName}
        buttonType="outlined"
        buttonMode="dark"
        size="xs"
        className="icon"
        handleClick={playActions.onStop}
      />
      <CircleButton
        disabled={!playActions.hasNext}
        icon="NextTrack"
        buttonType="outlined"
        buttonMode="dark"
        size="xs"
        className="icon"
        handleClick={playActions.onNext}
      />
    </PlayerButtonsContainer>
  );
};

export default PlayerButtons;
