import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { IFlightData, IFlightDataStore } from 'globals/types/flightdata';
import { getPreviewData } from 'utils/previewConfig';

const initState = {
  flightData: null as IFlightData
};

const { previewID } = getPreviewData();

// creaate flight data store that saves iflightdata content
export const useFlightDataStore = create<IFlightDataStore>()(
  persist(
    (set) => ({
      ...initState,
      resetFlightData: () => {
        set({ ...initState });
      },
      setFlightData: (data: Record<string, any>) => {
        set((state) => ({ flightData: { ...state.flightData, ...data } }));
      }
    }),
    {
      name: 'flightData',
      storage: createJSONStorage(() => {
        return previewID ? sessionStorage : localStorage;
      })
    }
  )
);
