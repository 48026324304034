import React from 'react';
import {
  BillboardContainer,
  BillboardImageContainer,
  BillboardVideoContainer
} from './BillboardAd.styled';

import { useBillboardInView } from 'globals/hooks';
import { AerenaContent } from 'globals/cms-helper/types';
import { TAdTypes } from 'globals/types/billboard';

export const BillboardAd: React.FC<{ section: AerenaContent }> = ({ section }) => {
  const adType: TAdTypes = (section?.metadata as any)?.adType;

  const { containerRef } = useBillboardInView(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // 50% of the view
    },
    section
  );

  const mediaURL = section?.children?.media?.[0]?.metadata?.url;

  return (
    mediaURL && (
      <BillboardContainer ref={containerRef}>
        {adType === 'VIDEO' ? (
          <BillboardVideoContainer autoPlay playsInline muted loop src={mediaURL} />
        ) : (
          <BillboardImageContainer src={mediaURL} />
        )}
      </BillboardContainer>
    )
  );
};
