import { keycloakInstance } from 'globals/api';
import { getEnvValue } from 'utils/useConfig';

export const keyCloakInit = async (
  setInitKeyCloak: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const redirectUri = getEnvValue('REACT_APP_KEYCLOACK_REDIRECT_URL');

  keycloakInstance
    .init({
      onLoad: 'login-required',
      ...(redirectUri && { redirectUri }) //if redirect url is provided use it otherwise remove
    })
    .then((authenticated) => {
      if (authenticated) {
        setInitKeyCloak(true);
      }
    })
    .catch((e) => {
      console.error('KeyCloak init failed', e);
      keycloakInstance?.logout();
    });
};
