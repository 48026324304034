import styled from 'styled-components';

export const PlayerPlaybackContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5.4rem;
  position: relative;
  justify-content: space-between;
`;

export const PlayerPlaybackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.4rem;
  position: relative;
  justify-content: space-between;
`;

export const PlaybackTimersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PlaybackTime = styled.div`
  ${({ theme }) => theme?.font?.large?.bodyXS};
  color: ${({ theme }) => theme?.colors?.primaryBlack};
`;

export const PlayerButtonsContainer = styled.div`
  width: 36.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 4.8rem;
`;

export const PlaybackProgress = styled.input.attrs((props) => ({
  style: {
    backgroundSize: props.value ? `${(Number(props.value) / Number(props.max)) * 100}% 100%` : 0
  }
}))`
  width: 100%;
  position: absolute;
  left: 0;
  height: ${({ theme }) => theme?.spacing?.large?.spacing_s1};
  border-radius: 1rem;
  background: inherit;
  margin-bottom: 20px;
  appearance: none;
  accent-color: #000000;
  background-image: ${({ theme }) =>
    `linear-gradient(${theme?.colors?.primaryBlack}, ${theme?.colors?.primaryBlack})`};

  background-repeat: no-repeat;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: none;
    border: none;
    -webkit-appearance: none;
  }
  &::-webkit-slider-thumb {
    height: 32px;
    width: 32px;
    border-radius: 32px;
    background: ${({ theme }) => theme?.colors?.primaryBlack};
    cursor: grab;
    -webkit-appearance: none;
    margin-top: -12px;
  }
`;
