import React from 'react';

import { HomePageFooter } from './ResetAudioSubtitles.styled';

// import { ResetButton } from 'components/ResetButton';
import BackToTop from 'components/BackToTop/BackToTop';

const ResetAudioSubtitles: React.FC = () => (
  <HomePageFooter>
    <BackToTop />
    {/* <ResetButton /> */}
  </HomePageFooter>
);

export default ResetAudioSubtitles;
