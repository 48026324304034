import React from 'react';

import { Placeholder, TTileRatio } from '@aerq/aerq-design-system';

import { PlaceHolderSwiperContent } from './placeholderWrapper.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderSwiperProps = {
  width: string[] | string;
  height?: string;
  ratio: TTileRatio;
  noOfTile: number;
  centered?: boolean;
};

const PlaceHolderSwiper: React.FC<PlaceHolderSwiperProps> = ({
  width,
  height,
  ratio,
  noOfTile = 3,
  centered = true
}) => (
  <PlaceHolderSwiperContent centered={centered}>
    {rangeSequence(noOfTile)?.map((tileNo) => (
      <div key={tileNo}>
        <Placeholder key={tileNo} type="image" width={width} aspectRatio={ratio} height={height} />
      </div>
    ))}
  </PlaceHolderSwiperContent>
);

export default PlaceHolderSwiper;
