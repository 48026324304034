import styled from 'styled-components';

export const EpisodeContent = styled.div<{
  showPlayer: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, showPlayer }) =>
    showPlayer ? '#DDDDDD' : theme?.colors?.primaryWhite};
  padding: ${({ theme }) =>
    `${theme?.spacing?.large?.spacing_s4} ${theme?.spacing?.large?.spacing_m1}`};
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  height: ${({ showPlayer }) => showPlayer && '25.3rem'};
`;

export const EpisodeText = styled.div`
  margin-right: auto;
`;

export const Title = styled.div`
  ${({ theme }) => theme?.font?.large?.bodyXL};
  color: ${({ theme }) => theme?.colors?.body};

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Info = styled.div`
  ${({ theme }) => theme?.font?.large?.bodyS};
  color: ${({ theme }) => `${theme?.colors?.primaryBlack}99`};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const EpisodeSectionHeader = styled.h3`
  ${({ theme }) => theme?.font?.large?.header2};
  color: ${({ theme }) => theme?.colors?.primaryBlack};
  padding: ${({ theme }) =>
    `${theme?.spacing?.large?.spacing_s2} ${theme?.spacing?.large?.spacing_s3} 4.4rem`};
`;

export const EpisodeItemsContainerWrapper = styled.div`
  height: 100%;
  padding: ${({ theme }) => `${theme?.spacing?.large?.spacing_s4} 6.4rem`};
  z-index: 0;
  position: relative;
`;

export const EpisodeItemsContainer = styled.div`
  background-color: ${({ theme }) => theme?.colors?.primaryWhite};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const OverflowDiv = styled.div<{
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme?.spacing?.large?.spacing_s4};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme?.spacing?.large?.spacing_s3}`};
`;

export const EpisodeDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
