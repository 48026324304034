export type Color = [number, number, number];

export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;
  let h, s;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
      default:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [h * 360, s * 100, l * 100];
};

export const isTextDark = (dominantColor: Color): boolean => {
  if (!dominantColor) {
    return true;
  }
  const [r, g, b] = dominantColor;
  const [, , lightness] = rgbToHsl(...dominantColor);
  if (lightness >= 60) {
    return true;
  } else if (lightness <= 30) {
    return false;
  } else if (g === 255 && b === 0) {
    return true;
  } else if (r === 0 && g === 255 && b > 128) {
    return true;
  }
  return false;
};

export const hexToRgb = (hex: string): Color => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : [0, 0, 0];
};
