import React, { useEffect } from 'react';

import { Chip, CircleButton } from '@aerq/aerq-design-system';

import {
  OptionsContainer,
  OptionsSeperator,
  OptionsSlider,
  WatchOptionsContainer
} from './watchOptions.styled';
import { useThemeContent } from 'globals/hooks';

export type WatchOption = {
  title: string;
  value: string;
  selected?: boolean;
};

type WatchOptionsProps = {
  mainOptions: WatchOption[];
  secondaryOptions: WatchOption[];
  showAudioSubtitles?: boolean;
  isAudioOrSubtitleSelected?: boolean;
  mainOptionsClickHandler: (item: WatchOption) => void;
  secondaryOptionsClickHandler: (item: WatchOption) => void;
  audioSubtitleClickHandler?: () => void;
};

export const WatchOptions: React.FC<WatchOptionsProps> = ({
  mainOptions,
  secondaryOptions,
  showAudioSubtitles = false,
  isAudioOrSubtitleSelected,
  mainOptionsClickHandler,
  secondaryOptionsClickHandler,
  audioSubtitleClickHandler
}) => {
  const { mode } = useThemeContent();

  useEffect(() => {
    // scroll to selected secondary options
    if (secondaryOptions && secondaryOptions?.some((it) => it?.selected)) {
      const item = secondaryOptions?.find((it) => it?.selected);
      document.getElementById(item.title)?.scrollIntoView({
        block: 'nearest'
      });
    } else {
      // show first option if none selected
      document.getElementById(secondaryOptions?.[0]?.title)?.scrollIntoView({
        block: 'nearest'
      });
    }
  }, [secondaryOptions]);

  return (
    <WatchOptionsContainer>
      {showAudioSubtitles && (
        <>
          <OptionsContainer>
            <CircleButton
              icon="AudioSubtitle"
              buttonType={isAudioOrSubtitleSelected ? 'filled' : 'outlined'}
              buttonMode={mode === 'dark' ? 'light' : 'dark'}
              size="s"
              handleClick={() => audioSubtitleClickHandler()}
            />
          </OptionsContainer>
          {mainOptions?.length > 0 && <OptionsSeperator />}
        </>
      )}
      <OptionsContainer>
        {mainOptions?.map((item) => (
          <Chip
            key={item.title}
            chipStyle={mode === 'dark' ? 'light' : 'dark'}
            chipType={item.selected ? 'filled' : 'transparent'}
            onClick={() => mainOptionsClickHandler(item)}
          >
            {item.title}
          </Chip>
        ))}
      </OptionsContainer>
      {secondaryOptions?.length > 0 && <OptionsSeperator />}
      <OptionsSlider>
        {secondaryOptions?.map((item) => (
          <div key={item.title} id={item.title}>
            <Chip
              key={item.title}
              icon={item.selected ? 'Close' : null}
              chipStyle={mode === 'dark' ? 'light' : 'dark'}
              chipType={item.selected ? 'filled' : 'transparent'}
              onClick={() => secondaryOptionsClickHandler(item)}
            >
              {item.title}
            </Chip>
          </div>
        ))}
      </OptionsSlider>
    </WatchOptionsContainer>
  );
};
