import { create } from 'zustand';
import { AerenaContentType } from 'globals/cms-helper/types';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getPreviewData } from 'utils/previewConfig';

export type TFavorite = {
  id: string;
  type?: AerenaContentType;
  url?: string;
};

const initState = {
  watchFavorites: [] as TFavorite[],
  listenFavorites: [] as TFavorite[],
  error: ''
};

const { previewID } = getPreviewData();

export interface IFavoritesState {
  watchFavorites: TFavorite[];
  listenFavorites: TFavorite[];
  error?: Error | string;
}

export interface IFavoritesStore extends IFavoritesState {
  setWatchFavorites: (fn: (prev: TFavorite[]) => TFavorite[]) => void;
  setListenFavorites: (fn: (prev: TFavorite[]) => TFavorite[]) => void;
}

export const useFavoritesStore = create<IFavoritesStore>()(
  persist(
    (set) => ({
      ...initState,
      setWatchFavorites: (fn: (prev: TFavorite[]) => TFavorite[]) => {
        set((state) => ({ watchFavorites: fn(state.watchFavorites) }));
      },
      setListenFavorites: (fn: (prev: TFavorite[]) => TFavorite[]) => {
        set((state) => ({ listenFavorites: fn(state.listenFavorites) }));
      }
    }),
    {
      name: 'favorites',
      storage: createJSONStorage(() => {
        return previewID ? sessionStorage : localStorage;
      }),
      partialize: (state) => ({
        watchFavorites: state.watchFavorites,
        listenFavorites: state.listenFavorites
      })
    }
  )
);
