import React from 'react';

import { Query, QueryKey, useIsFetching } from '@tanstack/react-query';

import { Loader, LoaderContainer } from './LoadingWrapper.styled';

import { ALL_CONTENT } from 'utils/constants';

export const LoadingWrapper: React.FC = () => {
  const allContentQuery = (query: Query<unknown, Error, unknown, QueryKey>) =>
    query.queryKey.some((key) => key === ALL_CONTENT);

  let isFetching = useIsFetching({
    predicate: (query) => allContentQuery(query)
  });

  if (window.location.pathname.startsWith('/error')) {
    isFetching = 0;
  }

  return isFetching ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : null;
};
