import React, { useCallback, useEffect, useState } from 'react';

import { format, parse, isValid } from 'date-fns';

import { Episode } from './episode';
import {
  EpisodeItemsContainer,
  EpisodeSectionHeader,
  EpisodeItemsContainerWrapper,
  OverflowDiv
} from './episode.styled';

import { TPlayerState, TPodcastEpisodeItem } from 'globals/types/listen';
import { AerenaContent } from 'globals/cms-helper/types';
import { formatSecondRuntimeToMinute } from 'utils/runtimeFormat';

export interface EpisodesContainerProps {
  className?: string;
  episodes?: AerenaContent[];
  playButtonText?: string;
  episodesHeader?: string;
  playerState?: TPlayerState;
  onPlay?: (episode: TPodcastEpisodeItem) => void;
  onPause?: (episode: TPodcastEpisodeItem) => void;
  onStop?: (episode: TPodcastEpisodeItem) => void;
  onPrev?: (episode: TPodcastEpisodeItem) => void;
  onNext?: (episode: TPodcastEpisodeItem) => void;
  onSeek?: (position: number) => void;
}

export const EpisodesContainer: React.FC<EpisodesContainerProps> = ({
  className,
  episodes,
  playButtonText,
  episodesHeader,
  playerState,
  onPlay,
  onPause,
  onStop,
  onPrev,
  onNext,
  onSeek
}) => {
  const [tileItems, setTileItems] = useState<TPodcastEpisodeItem[]>([]);

  const applyEpisodeWithTileData = useCallback((episodes: AerenaContent[]) => {
    const itemsWithTileData: TPodcastEpisodeItem[] = episodes?.map((episode: any) => {
      const parsedDate = parse(
        episode?.metadata?.releaseDate.replace(/[/.]/g, '-'),
        'dd-mm-yyyy',
        new Date()
      );
      const formattedDate = isValid(parsedDate) ? format(parsedDate, 'dd-mm-yyyy') : '';
      return {
        ID: episode?.ID,
        title: episode?.metadata?.title,
        info: [
          formatSecondRuntimeToMinute(episode?.metadata?.runtime || 0),
          formattedDate && `${formattedDate}`
        ]
          .filter(Boolean)
          .join(' • ')
      } as TPodcastEpisodeItem;
    });
    setTileItems(itemsWithTileData?.filter(Boolean));
  }, []);

  useEffect(() => {
    if (episodes) {
      applyEpisodeWithTileData(episodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodes]);

  const [playerStateChecked, setPlayerStateChecked] = useState(false);

  //trigger useEffect if a playing episode is rendered
  const [playingEpisode, setPlayingEpisode] = useState<HTMLDivElement>(null);

  // scroll to playing episode if there is one. Only at first navigation and after episodes are rendered
  useEffect(() => {
    if (playingEpisode && !playerStateChecked) {
      playingEpisode?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
      setPlayerStateChecked(true);
    }
  }, [playerStateChecked, playingEpisode]);

  return (
    <EpisodeItemsContainerWrapper>
      <EpisodeItemsContainer className={className}>
        {tileItems?.length ? <EpisodeSectionHeader>{episodesHeader}</EpisodeSectionHeader> : null}
        <OverflowDiv data-testid="episodes-container">
          {tileItems?.map((episode: TPodcastEpisodeItem, index: number) => (
            <Episode
              ref={(element) => {
                if (playerState?.isPlaying && playerState?.ID === episode.ID) {
                  setPlayingEpisode(element); //trigger rerender to update playing episode scrolling
                }
              }}
              key={episode.ID}
              info={episode?.info}
              title={episode?.title}
              playButtonText={playButtonText}
              showPlayer={playerState?.ID === episode.ID}
              playerState={playerState}
              playActions={{
                onPlay: () => onPlay(episode),
                onPause: () => onPause(episode),
                onStop: () => onStop(episode),
                onPrev: () => onPrev(episode),
                onNext: () => onNext(episode),
                onSeek: (position) => onSeek(position),
                hasPrev: index > 0,
                hasNext: index < tileItems.length - 1
              }}
            />
          ))}
        </OverflowDiv>
      </EpisodeItemsContainer>
    </EpisodeItemsContainerWrapper>
  );
};
