export type PathContext =
  | 'onboarding'
  | 'home'
  | 'landing'
  | 'watch'
  | 'subpage'
  | 'fullscreen_app';

interface PathInfo {
  value: string;
  context: PathContext;
}

export type PathKeys =
  | 'home'
  | 'watch'
  | 'tvShows'
  | 'apps'
  | 'notFound'
  | 'seeAll'
  | 'welcome'
  | 'onboarding'
  | 'ejournal'
  | 'listen'
  | 'about';

// Note that similar to routing concept, most specific paths should be listed BEFORE less specific paths:
// example: /watch/tv-shows is more specific than /watch hence /watch/tv-show listed BEFORE /watch
// root path "/" is least specific -> goes to the end
// It is assumed that we do not have paths with variables in between like below
// watch/0/show/1
// variables at the end is ok
export const paths: Record<PathKeys, PathInfo> = {
  tvShows: {
    value: '/watch/tv-shows',
    context: 'subpage'
  },
  watch: {
    value: '/watch',
    context: 'landing'
  },
  apps: {
    value: '/apps',
    context: 'landing'
  },
  seeAll: {
    value: '/see-all',
    context: 'subpage'
  },
  welcome: {
    value: '/welcome',
    context: 'onboarding'
  },
  onboarding: {
    value: '/onboarding',
    context: 'onboarding'
  },
  notFound: {
    value: '/notFound',
    context: 'home'
  },
  home: {
    value: '/',
    context: 'home'
  },
  listen: {
    value: '/listen',
    context: 'landing'
  },
  ejournal: {
    value: '/ejournal',
    context: 'subpage'
  },
  about: {
    value: '/about',
    context: 'landing'
  }
};
