import { IFlightData } from 'globals/types/flightdata';
import axios from 'axios';

export const getFlightData = async (): Promise<IFlightData> => {
  try {
    let resp;
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      resp = await axios.get<IFlightData>(`/assets/mockedAPI/fapi.json`);
    } else {
      resp = await axios.get<IFlightData>(`/api/flight-data`);
    }
    return resp.data;
  } catch (error) {
    console.error(error);
  }
};
