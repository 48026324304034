import { CircleButton, Placeholder } from '@aerq/aerq-design-system';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import {
  PlaceholderOnboardingContainer,
  PlaceholderOnboardingContentText,
  PlaceholderOnboardingContentSwiper
} from './placeholderWrapper.styled';
import {
  ActionBottomContainer,
  ActionTopContainer,
  OnboardingContent,
  SliderContainer,
  SliderContent
} from 'views/onboarding/onboarding.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderOnboardingProps = {
  noOfTile: number;
  goBack: () => void;
};

const PlaceHolderOnboarding: React.FC<PlaceHolderOnboardingProps> = ({ noOfTile, goBack }) => {
  const swiperRef = useRef();

  return (
    <PlaceholderOnboardingContainer data-testid="placeholder-onboarding">
      <ActionTopContainer>
        <CircleButton
          buttonMode="light"
          buttonType="filled"
          size="m"
          icon="ArrowBack"
          handleClick={goBack}
        />
      </ActionTopContainer>
      <OnboardingContent>
        <PlaceholderOnboardingContentText>
          <Placeholder type="tab" size="xxl" className="placeholder-title__line_1" />
          <Placeholder type="tab" size="xxl" className="placeholder-title__line_1" />
          <div className="text-placeholder">
            <Placeholder type="tab" size="l" className="tab" />
            <Placeholder type="tab" size="l" className="tab" />
            <Placeholder type="tab" size="l" className="tab" />
          </div>
        </PlaceholderOnboardingContentText>
        <PlaceholderOnboardingContentSwiper>
          <Swiper
            ref={swiperRef}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            modules={[Autoplay, Pagination]}
            onClick={() => {
              const autoplay = (swiperRef as any)?.current?.swiper?.autoplay;
              if (autoplay.running) {
                autoplay.stop();
              } else {
                autoplay.start();
              }
            }}
          >
            {rangeSequence(noOfTile)?.map((tileNo) => {
              return (
                <SwiperSlide key={tileNo}>
                  <SliderContainer>
                    <div>
                      <Placeholder type="image" width="27.6rem" aspectRatio="3:4" />
                    </div>
                    <SliderContent>
                      <Placeholder type="tab" size="xl" className="placeholder-slider__title" />
                      <div className="text-placeholder">
                        <Placeholder type="tab" size="l" className="swiper-tab" />
                        <Placeholder type="tab" size="l" className="swiper-tab" />
                      </div>
                    </SliderContent>
                  </SliderContainer>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </PlaceholderOnboardingContentSwiper>
      </OnboardingContent>
      <ActionBottomContainer>
        <Placeholder type="pill" size="xxl" className="button-pill" />
        <Placeholder type="pill" size="xxl" className="button-pill" />
      </ActionBottomContainer>
    </PlaceholderOnboardingContainer>
  );
};

export default PlaceHolderOnboarding;
