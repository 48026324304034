import React from 'react';

import { t } from 'i18next';
import { useShallow } from 'zustand/react/shallow';

import { getCurrentLangBundle } from 'globals/cms-helper/movieHelper';
import { useGlobalStore } from 'globals/store';

import { Title } from './NoContentFound.styled';

export const NoContentInfo = () => {
  const {
    selectedAudios,
    selectedSubtitles,
    selectedLanguage,
    watchMainOptions,
    watchSecondaryOptions
  } = useGlobalStore(
    useShallow((state) => ({
      selectedAudios: state.selectedAudios,
      selectedSubtitles: state.selectedSubtitles,
      selectedLanguage: state.selectedLanguage,
      watchMainOptions: state.watchMainOptions,
      watchSecondaryOptions: state.watchSecondaryOptions
    }))
  );

  const selectedAudioTitles = selectedAudios?.map(
    (selectedAudio) => getCurrentLangBundle(selectedLanguage?.code, 'languages')?.[selectedAudio]
  );
  const selectedSubtitlesTitles = selectedSubtitles?.map(
    (selectedSubtitle) =>
      getCurrentLangBundle(selectedLanguage?.code, 'languages')?.[selectedSubtitle]
  );

  const selectedMainOption = watchMainOptions?.find((option) => option?.selected);
  const selectedSecondaryOption = watchSecondaryOptions?.find((option) => option?.selected);

  return (
    <Title>
      <>
        {selectedSubtitlesTitles?.length > 0 && (
          <p>
            {t('watch:details.subtitles.title')}: {selectedSubtitlesTitles.slice(0, 2).join(', ')}
            {selectedSubtitlesTitles.length > 2 && `, +${selectedSubtitlesTitles.length - 2}`}
          </p>
        )}
        {selectedAudioTitles?.length > 0 && (
          <p>
            {t('watch:details.audio.title')}: {selectedAudioTitles.slice(0, 2).join(', ')}
            {selectedAudioTitles.length > 2 && `, +${selectedAudioTitles.length - 2}`}
          </p>
        )}
        {selectedSecondaryOption && (
          <p>
            {selectedMainOption?.title}, {selectedSecondaryOption?.title}
          </p>
        )}
      </>
    </Title>
  );
};
