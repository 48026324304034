import { AerenaContent } from './types';

export const filterContentWithEpisodes = (
  content: AerenaContent,
  allContents: AerenaContent[]
): boolean => {
  switch (content?.contentTypeID) {
    case 'aerena_tv_show':
      return hasEpisodesForTvShow(content, allContents);
    case 'aerena_podcast':
      return hasEpisodesForPodcast(content, allContents);
    default:
      return true;
  }
};

const hasEpisodesForTvShow = (tvShow: AerenaContent, allContents: AerenaContent[]): boolean => {
  const seasons = getSeasonsForTvShow(tvShow, allContents);
  return seasons.some((season: AerenaContent) => hasEpisodesForSeason(season, allContents));
};

const getSeasonsForTvShow = (
  tvShow: AerenaContent,
  allContents: AerenaContent[]
): AerenaContent[] => {
  return allContents?.filter(
    (season: AerenaContent) =>
      season?.contentTypeID === 'aerena_season' &&
      (season?.metadata as any)?.seriesId?.[0] === tvShow?.ID
  );
};

const hasEpisodesForSeason = (season: AerenaContent, allContents: AerenaContent[]): boolean => {
  const episodes = getEpisodesForSeason(season, allContents);
  return episodes?.length > 0;
};

const getEpisodesForSeason = (
  season: AerenaContent,
  allContents: AerenaContent[]
): AerenaContent[] => {
  return allContents?.filter(
    (episode: AerenaContent) =>
      episode?.contentTypeID === 'aerena_episode' &&
      (episode?.metadata as any)?.seasonId?.[0] === season?.ID
  );
};

const hasEpisodesForPodcast = (podcast: AerenaContent, allContents: AerenaContent[]): boolean => {
  const episodes = getEpisodesForPodcast(podcast, allContents);
  return episodes?.length > 0;
};

const getEpisodesForPodcast = (
  podcast: AerenaContent,
  allContents: AerenaContent[]
): AerenaContent[] => {
  return allContents?.filter(
    (episode: AerenaContent) =>
      episode?.contentTypeID === 'aerena_podcast_episode' &&
      (episode?.metadata as any)?.podcastId === podcast?.ID
  );
};
