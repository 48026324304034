import axios from 'axios';

const baseURL = '/';

interface SeatInfo {
  seatId: string;
  hostname?: string;
}

export const getSeatNoConfig = async () => {
  let resp;
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    resp = await axios.get<SeatInfo>(`${baseURL}assets/mockedAPI/seat-no-config.json`);
  } else {
    resp = await axios.get<SeatInfo>('/api/config/seat-config');
  }
  return resp.data;
};
