import axios from 'axios';

import { AerenaContent } from 'globals/cms-helper/types';
import { TEpisodeTileItem, TLastWatchedEpisodeInSeries } from 'globals/types';

export const getMovieCurrentPosition = async (id: string, seatNumber: string) => {
  try {
    const resp = await axios.get(`/v1/flight-context/watchProgress/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Seat-ID': seatNumber
      }
    });
    return Number(resp?.data);
  } catch (e) {
    return null;
  }
};

export const getAllWatchProgress = async (seatNumber: string) => {
  try {
    const resp = await axios.get(`/v1/flight-context/watchProgress/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Seat-ID': seatNumber
      }
    });
    return resp?.data as Record<string, string>;
  } catch (e) {
    return null;
  }
};

export const getLastWatchedEpisode = (
  seriesId: string,
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries
) => {
  return lastWatchedEpisodeInSeries?.find((content) => content?.seriesId === seriesId)?.episode;
};

export const updateSelectedEpisodeInfoInStore = (
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  setLastWatchedEpisodeInSeries: (val: TLastWatchedEpisodeInSeries) => void,
  content: AerenaContent,
  episodeId: string,
  seasonId: string,
  episodeTitle: string,
  episodeURL: string,
  episodeRuntime: number,
  currentPos: number,
  episodeNum: string,
  seasonNum: string
) => {
  // Check if the series with the given seriesId exists in watchedEpisodesInSeries
  const seriesToUpdateIndex = lastWatchedEpisodeInSeries.findIndex(
    (series) => series?.seriesId === content?.ID
  );

  // If series with seriesId exists
  if (seriesToUpdateIndex !== -1) {
    lastWatchedEpisodeInSeries[seriesToUpdateIndex].episode = {
      episodeId,
      seasonId,
      title: episodeTitle,
      path: episodeURL,
      runtimeInMin: episodeRuntime,
      currentPosInSec: currentPos || 0,
      episodeNum,
      seasonNum
    };
  } else {
    // If series with seriesId doesn't exist, create a new object with the series and episode
    lastWatchedEpisodeInSeries.push({
      seriesId: content?.ID,
      episode: {
        episodeId,
        seasonId,
        title: episodeTitle,
        path: episodeURL,
        runtimeInMin: episodeRuntime,
        currentPosInSec: currentPos || 0,
        episodeNum,
        seasonNum
      }
    });
  }
  setLastWatchedEpisodeInSeries([...lastWatchedEpisodeInSeries]);
};

export const getEpisodeCurrPosInSec = (
  seriesId: string,
  episode: AerenaContent | TEpisodeTileItem,
  lastWatchedEpisodeInSeries: TLastWatchedEpisodeInSeries,
  allWatchProgress: Record<string, string>
) => {
  let currPos: number;
  // first search in lastWatchedEpisode store
  currPos = lastWatchedEpisodeInSeries?.find(
    (series) => series?.seriesId === seriesId && series?.episode?.episodeId === episode?.ID
  )?.episode?.currentPosInSec;

  // if not found, then search in allWatchProgress
  if (!currPos) {
    currPos = Number(allWatchProgress?.[episode?.ID]) || 0;
  }
  return currPos || 0;
};

export const calculateRemainingTimeInMin = (runtime: number, pos: number) => {
  // calculate when pos > O. Else, return runtime which means that content was never played
  if (pos > 0) {
    const totalRuntimeInSec = runtime * 60;

    const remainingTimeInMin = (totalRuntimeInSec - pos) / 60;

    return Math.ceil(remainingTimeInMin);
  }
  return runtime;
};

export const calculateRemainingTimeInSec = (runtime: number, pos: number) => {
  // calculate when pos > O. Else, return runtime which means that content was never played
  if (pos > 0) {
    const totalRuntimeInSec = runtime * 60;

    const remainingTimeInSec = totalRuntimeInSec - pos;

    return Math.ceil(remainingTimeInSec);
  }
  return runtime;
};

export const calculateCurrentPercentage = (totalRuntime: number, elapsedTime: number) => {
  return Number(((elapsedTime / totalRuntime) * 100).toFixed(2));
};
