import styled, { createGlobalStyle, css } from 'styled-components';
import { Theme, themes } from '@aerq/aerq-design-system';
import { useThemeContent } from './hooks';
import { getEnvValue } from 'utils/useConfig';

export const useGetTheme = () => {
  const { name, mode, logoDark, logoLight } = useThemeContent();

  const defaultThemeName = getEnvValue('REACT_APP_DEFAULT_THEME') || 'Aerq';

  const themeName = name ?? defaultThemeName;
  const isDarkMode = mode ? mode === 'Dark' : true;
  const logo = isDarkMode ? logoDark : logoLight;

  const lightTheme = themes?.find((theme: Theme) => theme?.name === `${themeName}Light`);
  const darkTheme = themes?.find((theme: Theme) => theme?.name === `${themeName}Dark`);

  const secondaryTheme = isDarkMode ? darkTheme : lightTheme;

  return {
    mainTheme: lightTheme,
    secondaryTheme: secondaryTheme,
    logo
  };
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%; // 1 rem/em = 10px
    background-color: white;
  }
  
  body {
    margin: 0;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    
    ${() => css`
      background-color: ${({ theme }) => theme?.colors?.primaryWhite || undefined};
    `};
  }

  ::-webkit-scrollbar {
    display: none;
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }
`;

export { createGlobalStyle };

export const pageXMargin = '6.4rem';
export const pageYMargin = '14.4rem';

export const tabletSize = '1440px';

export const Spacer = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? pageYMargin};
  width: 100%;
`;
