import axios, { AxiosInstance } from 'axios';
import { SystemUIService } from '@aerena-sdk/system-ui';
import Keycloak from 'keycloak-js';

import { CMSRuntimeApi, Configuration } from 'cms/api/runtime';
import { getEnvValue } from 'utils/useConfig';

export const assetsPath = '/runtime/asset/';

const axiosInstance: AxiosInstance = axios.create();
const configuration = new Configuration({ basePath: '/runtime' });
export const cmsAPI = new CMSRuntimeApi(configuration, undefined, axiosInstance);
export const sdkInstance: SystemUIService = new SystemUIService();
export const keycloakInstance = new Keycloak({
  url: getEnvValue('REACT_APP_KEYCLOAK_URL'),
  realm: 'controltower',
  clientId: 'passenger-frontend'
});
