import React from 'react';

import { Placeholder, TTileRatio } from '@aerq/aerq-design-system';

import {
  PlaceholderContainer,
  PlaceholderScrollerContent,
  PlaceholderTitle
} from './placeholderWrapper.styled';
import { rangeSequence } from 'utils/rangeSequence';

type PlaceHolderScrollerProps = {
  showPlaceholderTitle?: boolean;
  width: string[] | string;
  ratio: TTileRatio;
  noOfTile: number;
};

const PlaceHolderScroller: React.FC<PlaceHolderScrollerProps> = ({
  showPlaceholderTitle,
  width,
  ratio,
  noOfTile
}) => (
  <PlaceholderContainer>
    <PlaceholderTitle>
      {showPlaceholderTitle && <Placeholder type="tab" size="xl" />}
    </PlaceholderTitle>
    <PlaceholderScrollerContent>
      {rangeSequence(noOfTile)?.map((tileNo) => (
        <div key={tileNo}>
          <Placeholder key={tileNo} type="image" width={width} aspectRatio={ratio} />
        </div>
      ))}
    </PlaceholderScrollerContent>
  </PlaceholderContainer>
);

export default PlaceHolderScroller;
